import React from 'react';

import logo from '~/assets/logos/logo-mcf.svg';
import { Container } from './styles';

interface ISimulation {
  prazo: number;
  taxa: number;
  vlrImovel: number;
}

interface IParams {
  simulation: ISimulation;
}

const SimulationConditions: React.FC<IParams> = ({ simulation }) => {
  return (
    <Container className="container card-dashboard justify-content-center align-self-start p-4">
      <div className="">
        <div className="section_info_imovel d-flex align-items-center mb-4 justify-content-between">
          <div className="col-7 d-flex align-items-center ">
            <h5 className="mb-0">Seu imóvel</h5>
          </div>
          <div className="container_icon_house">
            <img className="" src={logo} alt="Minha casa financiada" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="section_info_imovel_secundary -flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <h5 className="mb-0">Valor do imóvel</h5>
            <p className="mb-0 font-weight-700 ms-auto text-green">
              R${simulation.vlrImovel}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SimulationConditions;
