import React, { useCallback, useEffect, useState } from 'react';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { ChatOptions, Container } from './styles';

// Hooks
import { useClient } from '~/hooks/Client';
import { useFinality } from '~/hooks/Finality';
import { IParticipant, useParticipant } from '~/hooks/Participants';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSpouse } from '~/hooks/Spouse';
import { useAuthContext } from '~/hooks/contexts/Auth';

// Service
import api from '~/services/api';

// Components
import Loading from '~/components/Loading';
import { IOption } from '~/components/Select';
import AmortizationSimulation from './Components/AmortizationSimulation';
import BudgetAmount from './Components/BudgetAmount';
import BudgetResult from './Components/BudgetResult';
import PerformanceComparator from './Components/PerformanceComparator';
import ProjeteiSuggestion from './Components/ProjeteiSuggestion';
import PropertyEnvironments from './Components/PropertyEnvironments';
import PropertyValuation from './Components/PropertyValuation';
import ResourceComposition from './Components/ResourceComposition';
import SimulationConditions from './Components/SimulationConditions';
import UserData from './Components/UserData';
import YourProperty from './Components/YourProperty';
import Avisoama from './Components/aviso-amarillo';
import Avisonar from './Components/aviso-naranja';
import Avisover from './Components/aviso-verde';
import { Mock } from './Partials/Mock';

interface IParams {
  os_id: string;
  osKey: string;
}
interface IFinalityResponse {
  id: number;
  finalidade: string;
}
interface IStatesResponse {
  id: number;
  codigo: string;
  nome: string;
  sigla: string;
  valor: number;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
}
interface IInitialData {
  finalidades: IFinalityResponse[];
  estados_brasileiros: IStatesResponse[];
}
interface ISimulation {
  id: number;
  amortizacao: {
    id: number;
    amortizacao: string;
  };
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrRenda: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
  vlrParcInic: number;
  vlrParcFinal: number;
  vlrImovel: number;
  prazo: number;
  taxa: number;
  vlrSolicitado: number;
}

interface IParametersOrc {
  acabamento: Record<string, unknown>;
  eletrica: string;
  terreno: string;
}

interface IClient {
  id: number;
  usuario_id?: number;
  nome: string;
  documento: string;
  rg?: string;
  orgao_expedido?: string;
  nvEscolar_id?: number;
  vlrRenda?: number;
  vlrFgts?: number;
  numPis?: string;
  casado?: boolean;
  dt_nascimento?: Date;
  sexo?: number;
  estado_civil_id?: number;
  dt_uniao_estavel?: Date;
  mae?: string;
  pai?: string;
  pep?: string;
  email: string;
  telefone?: string;
  celular?: string;
  cep?: string;
  endereco?: string;
  bairro?: string;
  numero?: string;
  complemento?: string;
  data_expedicao?: Date;
  estado_id?: number;
  estado?: {
    id: number;
    nome: string;
  };
  municipio?: string;
  tempo_residencia?: number;
  profissao?: string;
  empresa?: string;
  cnpj?: string;
  dt_admissao?: Date;
  tipo_profissao_id?: number;
}

interface IConjuge {
  id: number;
  nome: string;
  vlrRenda: number;
  cpf: string;
  vlrFgts: number;
  profissao: string;
  numPis: string;
  nvEscolar_id: number;
}
interface ISpouse {
  id: number;
  os_id?: number;
  nome: string;
  cpf: string;
  dt_nascimento?: Date;
  data_expedicao?: Date;
  rg?: string;
  vlrRenda?: number;
  vlrFgts?: number;
  numPis?: string;
  profissao?: string;
  nvEscolar_id?: number;
}
interface IParticipante {
  id: number;
  os_id?: number;
  nome: string;
  cpf: string;
  dt_nascimento?: Date;
  data_expedicao?: Date;
  rg?: string;
  vlrRenda?: number;
  vlrFgts?: number;
  numPis?: string;
  profissao?: string;
  nvEscolar_id?: number;
}

interface IStatus {
  id: number;
}
interface IParticipantes {
  id: number;
  nome: string;
  vlrRenda: number;
  cpf: string;
  vlrFgts: number;
  profissao: string;
  numPis: string;
  nvEscolar_id: number;
}

interface IRoomSize {
  ambiente_id: number;
  tamanho_id: number;
}
interface IBudgetRoom {
  id: number;
  ambiente_tamanho: IRoomSize;
  metragem: number;
  vlrLucro: number;
}

interface IOsResponse {
  cliente: IClient;
  simulacao: ISimulation;
  conjuge: IConjuge;
  participantes: IParticipantes[];
  participant: IParticipant[];
  orc_parametros: IParametersOrc;
  orc_ambientes: IBudgetRoom[];
  status: IStatus;
  finalidade_id: number;
}

interface IOsResponse {
  user_create_id: number;
}

const Dashboard: React.FC = () => {
  const history = useHistory();
  const { finality } = useFinality();
  const { recoveryServiceOrderData, serviceOrder } = useServiceOrder();
  const [modalities, setModalities] = useState<IOption[]>([
    {
      id: 1,
      value: 'Aquisição de Terreno e construção',
      selected: false,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [, setStatesData] = useState<IStatesResponse[]>([]);
  const params = useParams<IParams>();
  const { user } = useAuthContext();

  useEffect(() => {
    api
      .get<IInitialData>(`queries/inicial_data`, {
        baseURL: `${process.env.REACT_APP_API_URL}`,
      })
      .then((response) => {
        const data: IOption[] = (
          response.data.finalidades as unknown as any[]
        ).map((finalityData) => ({
          id: finalityData.id,
          value: finalityData.finalidade,
          selected: finality.id === finalityData.id,
        }));
        setStatesData(response.data.estados_brasileiros);
        setModalities(data);
      });
  }, [finality.id]);

  // usar la misma estructura del traducior o finality osea usar el usecontext
  const [simulation, setSimulation] = useState<ISimulation>({
    id: 1,
    vlrFinanciado: 0,
    vlrEntrada: 0,
    vlrRenda: 0,
    vlrFgts: 0,
    vlrSubsidio: 0,
    vlrTerreno: 0,
    vlrParcInic: 0,
    vlrParcFinal: 0,
    vlrImovel: 0,
    amortizacao: {
      id: 1,
      amortizacao: '',
    },
    prazo: 0,
    taxa: 0,
    vlrSolicitado: 0,
  });

  const [parameters_orc, setParameters] = useState<IParametersOrc>({
    acabamento: {},
    eletrica: '',
    terreno: '',
  });

  const [, setParticipantes] = useState<IParticipantes[]>([]);

  const [, setConjuge] = useState<IConjuge>({
    id: 1,
    nome: '',
    vlrRenda: 0,
    cpf: '',
    vlrFgts: 0,
    profissao: '',
    numPis: '',
    nvEscolar_id: 0,
  });
  const [status, setStatus] = useState<IStatus>({
    id: 1,
  });
  const { client, setClient } = useClient();
  const { spouse, setSpouse } = useSpouse();
  const { setParticipants } = useParticipant();
  const [ambientes_orc, setAmbientes] = useState<IBudgetRoom[]>([]);
  const clientTranslate = useCallback(
    (clientResponse: IClient) => {
      setClient({
        ...client,
        id: clientResponse?.id,
        user_id: clientResponse?.usuario_id,
        name: clientResponse?.nome,
        document: clientResponse?.documento,
        identity_document: clientResponse?.rg,
        dispatching_agency: clientResponse?.orgao_expedido,
        school_level_id: clientResponse?.nvEscolar_id,
        salary: clientResponse?.vlrRenda,
        fgts_amount: clientResponse?.vlrFgts,
        pis_number: clientResponse?.numPis,
        married: clientResponse?.casado,
        birth_date: clientResponse?.dt_nascimento,
        sex: clientResponse?.sexo,
        marital_status_id: clientResponse?.estado_civil_id,
        date_stable_union: clientResponse?.dt_uniao_estavel,
        mother: clientResponse?.mae,
        father: clientResponse?.pai,
        pep: clientResponse?.pep,
        email: clientResponse?.email,
        cell: clientResponse?.celular,
        telephone: clientResponse?.telefone,
        zip_code: clientResponse?.cep,
        address: clientResponse?.endereco,
        number: clientResponse?.numero,
        complement: clientResponse?.complemento,
        district: clientResponse?.bairro,
        state_id: clientResponse?.estado_id,
        state: {
          id: clientResponse?.estado?.id,
          name: clientResponse?.estado?.nome,
        },
        residence_time: clientResponse?.tempo_residencia,
        profession: clientResponse?.profissao,
        company_name: clientResponse?.empresa,
        company_document: clientResponse?.cnpj,
        occupation_type_id: clientResponse?.tipo_profissao_id,
        shipping_date: clientResponse?.dt_admissao,
      });
    },
    [client, setClient]
  );

  useEffect(() => {
    if (params.os_id && !serviceOrder.key) {
      recoveryServiceOrderData(params.os_id).catch((_rejects) => {
        history.push('/');
      });
    } else if (!params.os_id || !serviceOrder?.key) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (params.os_id && serviceOrder.key && params.os_id !== serviceOrder.key) {
      setLoading(true);
      recoveryServiceOrderData(params.os_id)
        .then((_resolve) => setLoading(false))
        .catch((_rejects) => {
          history.push('/');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const spouseTranslate = useCallback(
    (spouseResponse: ISpouse) => {
      if (spouseResponse) {
        setSpouse({
          ...spouse,
          id: spouseResponse?.id,
          so_id: spouseResponse?.os_id,
          name: spouseResponse?.nome,
          document: spouseResponse?.cpf,
          birth_date: spouseResponse?.dt_nascimento,
          shipping_date: spouseResponse?.data_expedicao,
          identity_document: spouseResponse?.rg,
          salary: spouseResponse?.vlrRenda,
          fgts_amount: spouseResponse?.vlrFgts,
          pis_number: spouseResponse?.numPis,
          profession: spouseResponse?.profissao,
          school_level_id: spouseResponse?.nvEscolar_id,
        });
      }
    },
    [spouse, setSpouse]
  );

  const participantTranslate = useCallback(
    (participantResponse: IParticipante[]) => {
      const part: IParticipant[] = [];
      participantResponse?.forEach((participante) => {
        part.push({
          id: participante.id,
          so_id: participante.os_id,
          name: participante.nome,
          document: participante.cpf,
          birth_date: participante.dt_nascimento,
          shipping_date: participante.data_expedicao,
          identity_document: participante.rg,
          salary: participante.vlrRenda,
          fgts_amount: participante.vlrFgts,
          pis_number: participante.numPis,
          profession: participante.profissao,
          school_level_id: participante.nvEscolar_id,
        });
      });
      setParticipants(part);
    },
    [setParticipants]
  );

  useEffect(() => {
    if (Object.keys(serviceOrder.cliente || {}).length > 0) {
      clientTranslate(serviceOrder.cliente as unknown as any);
    }

    if (Object.keys(serviceOrder.conjuge || {}).length > 0) {
      spouseTranslate(serviceOrder.conjuge as unknown as any);
      setConjuge(serviceOrder.conjuge as unknown as any);
    }

    if (Object.keys(serviceOrder.participantes || {}).length > 0) {
      setParticipantes(serviceOrder.participantes as unknown as any);
    }

    if (Object.keys(serviceOrder.simulacao || {}).length > 0) {
      setSimulation(serviceOrder.simulacao as unknown as any);
    }

    if (Object.keys(serviceOrder.orc_parametros || {}).length > 0) {
      setParameters(serviceOrder.orc_parametros as unknown as any);
    }

    if (Object.keys(serviceOrder.orc_ambientes || {}).length > 0) {
      setAmbientes(serviceOrder.orc_ambientes as unknown as any);
    }

    if (Object.keys(serviceOrder.status || {}).length > 0) {
      setStatus(serviceOrder.status as unknown as any);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrder]);

  const handleClickBtnChat = useCallback(async () => {
    const response = await api.post(`chats`, {
      os_id: parseInt(params.os_id, 10),
      type: 'client',
    });

    history.push(`/chat/${response.data.id}`);
  }, [history, params.os_id]);

  return (
    <>
      <Container className="container">
        <UserData osId={serviceOrder.id || ''} osKey={serviceOrder.key} />

        <div className="row mt-4">
          {!user && (
            <div className="row mt-2">
              <Avisonar />
            </div>
          )}
          {status?.id === 6 && (
            <div className="row mt-2">
              <Avisoama simulation={simulation} />
            </div>
          )}
          {status?.id === 8 && (
            <div className="row mt-2">
              <Avisover simulation={simulation} />
            </div>
          )}

          {!serviceOrder?.key ? (
            <Mock />
          ) : (
            <>
              {/* este es para finalidad 1,2,5 y 7 */}
              {(serviceOrder!.finalidade_id === 1 ||
                serviceOrder!.finalidade_id === 2 ||
                serviceOrder!.finalidade_id === 5 ||
                serviceOrder!.finalidade_id === 7) && (
                <div className="row mt-2 container_dashboard_info d-flex align-items-start">
                  <ResourceComposition simulation={simulation} />
                  <PropertyValuation simulation={simulation} />
                  <PerformanceComparator simulation={simulation} />
                  {simulation && simulation?.amortizacao && (
                    <AmortizationSimulation simulation={simulation} />
                  )}
                  <SimulationConditions simulation={simulation} />
                  <BudgetAmount simulation={simulation} />
                  <PropertyEnvironments
                    parameters_orc={parameters_orc}
                    simulation={simulation}
                    osId={serviceOrder.id || -1}
                    osKey={serviceOrder.key || ''}
                    ambientes_orc={ambientes_orc}
                  />
                  <ProjeteiSuggestion osId={Number(serviceOrder.id)} />
                </div>
              )}
              {/* Este es para finalidad 3, 4 y 6 */}
              {(serviceOrder!.finalidade_id === 3 ||
                serviceOrder!.finalidade_id === 4 ||
                serviceOrder!.finalidade_id === 6) && (
                <div className="row mt-2 imovel d-flex align-items-start">
                  <YourProperty simulation={simulation} />
                  <AmortizationSimulation simulation={simulation} />
                  <div className="ms-5 p-4 imovel">
                    <BudgetResult simulation={simulation} />
                    <SimulationConditions simulation={simulation} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
      <ChatOptions>
        <button type="button" className="btn-chat" onClick={handleClickBtnChat}>
          <BsFillChatDotsFill size={20} color="#fff" />
        </button>
      </ChatOptions>
      <Loading active={loading} />
    </>
  );
};

export default Dashboard;
