/* eslint-disable */
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';
import List from 'react-chatview';
import styled, { keyframes } from 'styled-components';

const upDown = keyframes`
  0% {
    transform: translate(0px, -35px)
  }
  100% {
    transform: translate(0px, 0px)
  }
`;
interface IBuildersData {
    active: boolean;
    height: number;
}

interface IAvatar {
    src: string;
}

interface IAlert {background?: string}

interface IBuilderAvatar {
    src: string;
}

interface IImg {
    src: string;
}
interface IOs {
    active: boolean;
}

interface IBoxButtons {
    show: boolean;
}
interface IPropsButton {
    selected?: boolean;
}
export const Container = styled(List)`
  height: 100vh;

  .input-search {
    background: #fcfcfc;
    border: 1px solid #b8b9ba;
    width: calc(100% - 50px);
  }

  h1 {
    color: #414141;
    font-weight: 400 !important;
    text-align: center;

    b {
      font-weight: 700 !important;
    }
  }
`;

export const Profile = styled.div`
  p {
    color: #333333;
  }
`;
export const Button = styled.button<IPropsButton>`
  border-color: #079269 !important;
  background-color: #079269 !important;
  color: #ffffff !important;
  background-color: ${(props) =>
        props.selected ? '#ff1a50 !important' : '#079269 !important'};
  :hover {
    background-color: ${(props) =>
        props.selected ? '#ff1a50 !important' : '#079269 !important'};
  }
  border-color: ${(props) =>
        props.selected ? '#ff1a50 !important' : '#079269'};
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 38px;
  height: 38px;
  border-radius: 50%;

  @media screen and (min-width: 992px) {
    width: 51px;
    height: 51px;
  }
`;

export const Alert = styled.div<IAlert>`
  background-color: ${props => props.background || '#fffbd7'};
  border-radius: 10px;

  p {
    width: calc(100% - 70px);
  }

  @media screen and (min-width: 992px) {
    p {
      width: unset;
    }
  }
`;

export const Builder = styled.div`
  background: #fcfcfc;
  border: 1px solid #dedede;
  border-radius: 20px;
  transition-duration: 0.3s;
  margin-top: 40px;

  .buildings {
    border-radius: 20px;
    overflow: hidden;
    width: max-content;
  }

  @media screen and (min-width: 991px) {
    .buildings {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const BuilderAvatar = styled.div<IBuilderAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 72px;
  height: 72px;
  border-radius: 50%;
`;

export const Img = styled.div<IImg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 100%;
  height: 113px;

  + div {
    margin-left: 3px;
  }

  @media screen and (min-width: 992px) {
    height: 120px;

    + div {
      margin-left: 0;
      margin-top: 6px;
    }
  }
`;

export const Oss = styled.div`
  border-radius: 19px;
  overflow: hidden;
  border: 1px solid #dedede;
`;

export const Os = styled.div<IOs>`
  width: 100%;
  transition-duration: 0.3s;

  p {
    line-height: 32px;
    letter-spacing: 0em;
  }

  p.fw-semibold {
    color: #414142;
  }

  button.btn-dots {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  button.btn-add {
    background: #39a887;
    border-radius: 41px;
    color: #ffffff;
  }

  button.btn-yes,
  button.btn-no {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0em;
    color: #414142;

    span {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 11px;
    }
  }

  button.btn-yes {
    span {
      background: #c7fff2;
    }
  }

  button.btn-no {
    span {
      background: #ffe0e7;
    }
  }

  :hover {
    background: #f9f9f9;

    button.btn-dots {
      opacity: 1;
    }
  }

  + div {
    border-top: 1px solid #e2e2e2;
  }
`;

export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      color: #414141;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      z-index: 2;

      .sr-only {
        display: none;
      }
    }

    .btn-submit {
      color: #ffffff;
      background: #39a887;
      border-radius: 20px;
      transition-duration: 0.3s;

      :hover {
        background: ${darken(0.1, '#39a887')};
      }
    }
  }
`;

export const FloatBuilders = styled.div`
  .arrow_floatBuilders {
    position: absolute;
    top: -65px;
    right: 0;
    left: 0;
    margin: auto;
    color: #39a887;
    animation: ${upDown} 2s infinite;
  }

  position: absolute;
  right: 0px;
  bottom: 0px;

  button {
    right: 20px;
    bottom: 20px;
    z-index: 1;
    span {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #39a887;
      color: #fff;
    }
  }
`;

export const BuildersData = styled.div<IBuildersData>`
  transition-duration: 0.3s;
  position: absolute;
  right: 10px;
  bottom: 80px;
  width: ${(props) => (props.active ? '300px' : '0')};
  height: ${(props) => (props.active ? `36rem` : '0rem')};
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 20px;
  overflow: hidden;
  overflow-y: auto;
  opacity: ${(props) => (props.active ? '1' : '0')};

  .cursor-default {
    cursor: default;
  }

  .avatar {
    width: 50px !important;
    height: 50px !important;
  }

  > div {
    margin-top: 0;
    border: 0;
    border-radius: 0;

    + div {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #dedede;
    }
  }

  @media screen and (min-width: 375px) {
    width: ${(props) => (props.active ? '320px' : '0')};
    right: 40px;
    bottom: 40px;
  }
`;
