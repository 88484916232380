const initialsName = (str: string): string => {
  if (str && str !== 'N/A') {
    const fullName = str.replace(/\s(de|da|dos|das|e)\s/g, ' ');
    const iniciais = fullName.match(/\b(\w)/gi);
    return iniciais
      ? `${
          iniciais[0] +
          (iniciais.length > 1 ? iniciais[iniciais.length - 1] : '')
        }`
      : `${iniciais}`;
  }
  return str;
};

export default initialsName;
