import styled from 'styled-components';

export const Container = styled.div`
  .profile-icon {
    background-color: #03c2ff;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    p {
      -webkit-text-stroke-width: 0.2px;
      -webkit-text-stroke-color: #000000;
      -webkit-text-fill-color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .colored-odd:nth-child(odd),
  .colored-even:nth-child(even) {
    background: #f7f7fd;
    border-radius: 4px;
  }
  .p-relative {
    position: relative;
  }
  p {
    font-size: 14px;
    span {
      font-weight: 700;
      color: #39a887;
    }
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  h3 {
    font-size: 24px;
  }
  .dot {
    width: 7px;
    height: 7px;
    border-radius: 25px;
    background-color: #ff9900;
  }
  .sub-text {
    font-size: 12px;
  }
  .dot-2 {
    background-color: #48dcfc !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 14px;
    font-weight: 700;
    color: #414142;
  }

  @media screen and (min-width: 991px) {
    max-width: 97% !important;
  }

  @media screen and (min-width: 982px) {
    max-width: 95% !important;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    max-width: 100% !important;
  }
`;
