import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;

  > div {
    min-width: 320px;
    max-width: 400px;
    width: 100%;

    small {
      color: #6b6b6b;
    }

    select {
      border: none;
      border-bottom: 1px solid #7c7c7c;
      color: #ababab;
      font-size: 90%;
    }
  }
`;
