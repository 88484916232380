import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 8.5%;
  gap: 25px;
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 500 !important;
    font-size: 20px;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    background-color: #96d9ff !important;
  }
  .dot-3 {
    background-color: #ffba69 !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 988px) {
    //display: unset;
    //height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }
`;
