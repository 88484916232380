import styled from 'styled-components';

interface IAmortization {
  highlight?: boolean;
  titleColor?: string;
}

interface IButtonIcon {
  highlight?: boolean;
}

export const Amortization = styled.div<IAmortization>`
  background: ${(props) => (props.highlight ? '#11bb77' : '#ffffff')};
  border: 1px solid #f2f2f2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 21px;
  cursor: pointer;

  :hover {
    background: #11bb77;
    h2 {
      color: #ffffff;
    }
    p:not(.h4),
    small,
    span {
      color: #ffffff;
    }
  }

  h2 {
    color: ${(props) => (props.titleColor ? props.titleColor : '#fff')};
  }

  p:not(.h4),
  small,
  span {
    color: ${(props) => (props.highlight ? '#ffffff' : '#707070')};
    font-size: 14px;
  }
  p:not(.h4) {
    font-size: 16px;
    font-weight: 600;
  }
  p.h5 {
    font-size: 18px;
    color: ${(props) => (props.highlight ? '#ffffff' : '#414142')};
  }
  .rate {
    color: ${(props) => (props.highlight ? '#ffffff' : '#39A887')};
  }
  span.span_icon {
    font-weight: 500;
  }
  span {
    font-weight: 400;
  }

  .chain {
    background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .chain-top {
    :after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
    }
  }

  .chain-bottom {
    :before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(50% + 5px);
      background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
      top: 0;
    }
  }

  .installment {
    width: calc(100% - 25px);
  }
`;

export const ButtonIcon = styled.div<IButtonIcon>`
  button {
    top: 3rem;
    right: 3rem;
  }
`;
