import { ComponentType, FC, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
} from 'react-router-dom';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Pages
import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuthContext();

  const Layout = useMemo(() => {
    return isPrivate ? Default : Auth;
  }, [isPrivate]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate && !!user === true) {
          return (
            <Layout>
              <Component />
            </Layout>
          );
        }

        if (isPrivate) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: location,
              }}
            />
          );
        }

        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
