import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import Card from './components/Card';
import Slider from './components/Slider';

import { Container, ContainerOut } from './styles';

interface IProjeteiSuggestionProps {
  osId: number;
}

interface IDataBudgetE {
  quantity: IQuantityData[];

  [key: string]: any;
}
interface IRoomSize {
  ambiente_id: number;
  tamanho_id: number;
}

interface IQuantityData {
  cant: number;
  amb: string;
}

interface IProject {
  projectID: number;
  bathroomsNumber: number;
  suitesNumber: number;
  bedroomsNumber: number;
  garagesNumber: number;
  link: string;
  medias: IMedias[];
}

interface IMedias {
  source: string;
  title: string;
  description: string;
}

const ProjeteiSuggestion: React.FC<IProjeteiSuggestionProps> = ({ osId }) => {
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );
  const [projects, setProjects] = useState<IProject[]>([]);

  const history = useHistory();

  useEffect(() => {
    // `/service-orders/${osId}/budget/environments`
    api
      .get<IDataBudgetE>(
        `/service-orders/${osId}/budget/environments/get-quantities`
      )
      .then((response) => {
        const quantityEnvironments: IQuantityData[] = response.data[
          'quantity'
        ]?.map((element) => ({
          cant: element.cant,
          amb: element.amb,
        }));
        setQuantityAmbientes(quantityEnvironments);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osId]);

  useEffect(() => {
    if (!quantityAmbientes || quantityAmbientes.length <= 0) return;

    fetch(
      `https://search-api.server.123projetei.com.br/architectural-projects?bathroomsNumber=${quantityAmbientes[1]?.cant}&bedroomsNumber=${quantityAmbientes[0]?.cant}&garagesNumber=${quantityAmbientes[10]?.cant}`
    ).then(async (response) => {
      const projeteiData: IProject[] = await response.json();
      const sortMediasProjeteiData =
        projeteiData.length > 0 &&
        projeteiData.map((project) => {
          const medias = project.medias.sort((a, b) => {
            if (a.source.includes('youtube')) {
              return -1;
            }
            if (b.source.includes('jpg')) {
              return 1;
            }
            return 0;
          });
          return {
            ...project,
            medias,
          };
        });
      setProjects(sortMediasProjeteiData || []);
    });
  }, [quantityAmbientes]);

  return (
    <ContainerOut>
      <Container className="container card-dashboard col-md-12 col-11 p-5 mb-5 pb-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="h6 mb-0">Projetos com base no seu resultado</h2>
        </div>
        <Slider>
          {projects.length > 0 &&
            projects.map((item) => (
              <Card
                bathroomsNumber={item.bathroomsNumber}
                bedroomsNumber={item.bedroomsNumber}
                garagesNumber={item.garagesNumber}
                medias={item.medias}
                projectID={item.projectID}
                suitesNumber={item.suitesNumber}
                key={item.projectID}
                linkRedirect={item.link}
              />
            ))}
        </Slider>
        <div className="pb-5" />
      </Container>
    </ContainerOut>
  );
};

export default ProjeteiSuggestion;
