import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { format, getDaysInMonth } from 'date-fns';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Container, Calendar, DayPickerCaption } from './styles';
import InputMask from '../InputMask';

interface IYearMonthForm {
  date: Date;
  months: string[];
  onChange?(value: Date): void;
}

interface IInputDate {
  name: string;
  value?: Date;
  onChange?(value: Date): void;
  className?: string;
  idCalendar?: string;
  monthYearSelectable?: boolean;
  active?: boolean;
  setActive?(active: boolean): void;
  setError?(error: string): void;
}

const YearMonthForm: React.FC<IYearMonthForm> = ({
  date,
  months,
  onChange,
}) => {
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const fromMonth = new Date(currentYear - 100, 0);
    const toMonth = new Date(currentYear - 19, 0);
    const data = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      data.push(i);
    }

    return data;
  }, []);

  const handleChange = useCallback(
    (e) => {
      const { year, month } = e.target.form;
      if (onChange) {
        onChange(new Date(year.value, month.value));
      }
    },
    [onChange]
  );

  return (
    <DayPickerCaption className="DayPicker-Caption">
      <div>
        <select name="month" onChange={handleChange} value={date.getMonth()}>
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" onChange={handleChange} value={date.getFullYear()}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </DayPickerCaption>
  );
};

const InputDate: React.FC<IInputDate> = ({
  name,
  value,
  onChange,
  className,
  idCalendar,
  monthYearSelectable,
  active: show,
  setActive: setShow,
  setError: setErrorData,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const [formatedDate, setFormatedDate] = useState(
    value ? format(value, 'dd/MM/yyyy') : ''
  );
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(value);
  const [active, setActive] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (onChange) {
      onChange(selectedDate as Date);
    }
  }, [onChange, selectedDate]);

  useEffect(() => {
    setActive(!!show);
  }, [show]);

  const handleFocus = useCallback(() => {
    setActive(true);
    if (setShow) {
      setShow(true);
    }
  }, [setShow]);

  const handleBlur = useCallback(() => {
    if (selectedDate) {
      setFormatedDate(format(selectedDate, 'dd/MM/yyyy'));
    } else {
      setFormatedDate('');
    }
    setTimeout(() => {
      setActive(false);
    }, 100);
  }, [selectedDate]);

  useLayoutEffect(() => {
    const element = document.getElementById(`calendar-${name}`);
    if (element) {
      element.addEventListener('focusout', (e) => {
        if (e.relatedTarget) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const leavingParent = !element.contains(e.relatedTarget as any);
          if (leavingParent) {
            handleBlur();
          }
        }
      });

      element.addEventListener('focusin', () => {
        handleFocus();
      });
    }
  }, [handleBlur, handleFocus, name]);

  const handleDateChange = useCallback((day, modifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
      setFormatedDate(format(day, 'dd/MM/yyyy'));
      setActive(false);
    }
  }, []);

  const handleMonthChange = useCallback((month) => {
    setCurrentMonth(month);
  }, []);

  const handleChange = useCallback(
    (e) => {
      setError('');
      setErrorData && setErrorData('');
      if (e.target.value) {
        const [day, month, year] = e.target.value.split('/');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const initYear = currentYear - 100;
        const endYear = currentYear - 19;

        let [dayData, monthData, yearData] = e.target.value.split('/');

        if (!year) {
          yearData = new Date().getFullYear();
        }

        if (!month) {
          monthData = new Date().getMonth();
        } else if (monthData > 12) {
          monthData = 11;
        } else {
          monthData -= 1;
        }

        if (!day) {
          dayData = new Date().getDate();
        } else {
          const days = getDaysInMonth(month);
          if (dayData > days) {
            dayData = days;
          }
        }

        let dateFormated = '';

        if (day) {
          dateFormated = dayData;
        }

        if (month) {
          if (month > 12) {
            dateFormated += `12`;
          } else {
            dateFormated += `${month}`;
          }
        }

        if (year) {
          dateFormated += `/${yearData}`;
        }

        if (yearData < initYear || yearData > endYear) {
          setError('Data inválida');
          setErrorData && setErrorData('Data inválida');
        } else if (month < 1 || month > 12) {
          setError('Data inválida');
          setErrorData && setErrorData('Data inválida');
        } else {
          const lastDay = getDaysInMonth(new Date(yearData, month - 1, 1));
          if (day < 1 || day > lastDay) {
            setError('Data inválida');
            setErrorData && setErrorData('Data inválida');
          }
        }

        setFormatedDate(dateFormated);

        const date = new Date(yearData, monthData, dayData);
        setSelectedDate(date);
      } else {
        setFormatedDate('');
        setCurrentMonth(undefined);
        setSelectedDate(undefined);
      }
    },
    [setErrorData]
  );

  const handleChangeYearMonth = useCallback((valueData: Date) => {
    setCurrentMonth(valueData);
  }, []);

  return (
    <Container id={`calendar-${name}`} className={className}>
      <InputMask
        kind="datetime"
        options={{
          format: 'DD/MM/YYYY',
        }}
        name={name}
        placeholder="00/00/0000"
        className="border-none date-input"
        onChange={handleChange}
        value={formatedDate}
        error={error}
      />
      <Calendar id={idCalendar} active={active} className="calendar">
        <DayPicker
          weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
          months={[
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ]}
          month={currentMonth}
          selectedDays={selectedDate}
          modifiers={{
            available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
          }}
          onDayClick={handleDateChange}
          onMonthChange={handleMonthChange}
          captionElement={
            monthYearSelectable
              ? ({ date, months }) => (
                  <YearMonthForm
                    date={date}
                    months={months as string[]}
                    onChange={handleChangeYearMonth}
                  />
                )
              : undefined
          }
        />
      </Calendar>
    </Container>
  );
};

export default InputDate;
