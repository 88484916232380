import styled from 'styled-components';

export const Container = styled.div`
  .fondo-gris {
    background: #fcfcfc;
  }
  .input {
    width: 363px;
    border: none;
    background: #fcfcfc;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    .date-input {
      border: none;
      border-radius: 0;
      background: #fcfcfc;
    }

    input {
      color: #414142;
      background: #fcfcfc;
      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
  #calendarIcon {
    color: #b8b9ba;
    position: relative;
    left: 49%;
    top: -30px;
    @media (max-width: 1904px) {
      left: 48.7%;
    }
    @media (max-width: 1524px) {
      left: 48%;
    }
    @media (max-width: 956px) {
      left: 47%;
    }
  }
  .mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .input-date {
    position: unset;

    .calendar {
      bottom: 0;
      padding: 0 0 0 0rem;
      left: 120px;
      top: 400px;
      @media (min-width: 600px) {
        position: absolute;
        height: 100px;
        bottom: 0;

        padding: 0 0 0 0rem;
        width: 25%;

        left: 700px;
        top: 450px;
      }

      .DayPicker {
        padding: 0 10.25rem;
        height: 408px;
        background: rgba(255, 255, 255, 1);
        border-radius: 22px 22px 22px 22px;
        border: 0;
        filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.15));

        .DayPicker-wrapper {
          max-width: 364px;
          background: transparent;
          margin-right: auto !important;
          margin-left: auto !important;

          .DayPicker-Caption {
            div {
              color: #161616;
            }
          }

          .DayPicker-Weekday {
            color: black;
          }

          .DayPicker-Day--available:not(.DayPicker-Day--outside) {
            background: transparent;
            border-radius: 50% !important;
            :hover {
              color: #ffffff !important;
              background: #079269 !important;
            }
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
            color: #ffffff !important;
            background: #079269 !important;
          }

          .DayPicker-Day--outside {
            opacity: 0;
          }
        }
      }

      :before {
        content: '';
        background: rgba(0, 0, 0, 0);
        width: 0%;
        height: 0vh;
        position: absolute;
        top: -60vh;
      }
    }
  }

  @media (max-width: 440px) {
    .input-date {
      .calendar {
        width: 70%;
        .DayPicker {
          .DayPicker-wrapper {
            .DayPicker-Month {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;
