import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Container } from './styles';

import { useFinality } from '~/hooks/Finality';
import { useSimulation } from '~/hooks/Simulation';
import api from '~/services/api';

interface IOsResponse {
  id: number;
  created_at: string;
  construtor?: {
    name: string;
  };
  finalidade: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    id: number;
    os_id: number;
    amortizacao_id: number;
    vlrRenda: number;
    prazo: number;
    taxa: number;
    vlrParcInic: number;
    vlrParcFinal: number;
    vlrFinanciado: number;
    vlrImovPronto: number;
    vlrEntrada: number;
    vlrEntradaReal: number;
    vlrFgts: number;
    vlrTerreno: number;
    vlrSubsidio: number;
    vlrAvaliado: number;
    vlrAprovado: number;
    vlrSolicitado: number;
    vlrImovel: number;
    tipo_imovel_id?: number;
    comentario_analise?: string;
    dt_analise?: string;
    resultAnalise_id?: number;
    amortizacao?: {
      id: number;
      ordem: number;
      amortizacao: string;
      banco_id: number;
      taxa: number;
      percentual: number;
      prazo: number;
      funcao: string;
      indexador?: number;
      taxa_nominal?: number;
      teto_maximo?: number;
      renda_min?: number;
      renda_max?: number;
      observacao?: string;
      enfase: number;
      banco: {
        id: number;
        banco: string;
      };
    };
  };
  cliente: {
    dt_nascimento: string;
  };
}

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
}

interface IParams {
  os_id: string;
}

interface IBudgetAmountProps {
  simulation: ISimulation;
}

const BudgetAmount: React.FC<IBudgetAmountProps> = ({ simulation }) => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { setFinality } = useFinality();
  const { setSimulation } = useSimulation();
  const [os, setOs] = useState({} as IOsResponse);

  useEffect(() => {
    api.get(`service-orders/${params.os_id}`).then((response) => {
      setOs(response.data);
    });
  }, [params.os_id]);

  const handleClickEditar = useCallback(() => {
    setFinality({
      id: os.finalidade.id,
      title: os.finalidade.finalidade,
      description: os.finalidade.descricao,
      image: '',
    });

    const [year, month, day] = os.cliente.dt_nascimento.split('-');
    const birthdate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );

    setSimulation({
      os_id: os.id,
      birthdate,
      revenue: os.simulacao.vlrRenda,
      amortization: os.simulacao.amortizacao
        ? {
            id: os.simulacao.amortizacao.id,
            title: os.simulacao.amortizacao.amortizacao,
            financedValue: os.simulacao.vlrFinanciado.toFixed(2),
            entryValue: os.simulacao.vlrEntrada.toFixed(2),
            realEntryValue: os.simulacao.vlrEntradaReal.toFixed(2),
            fgtsAmount: os.simulacao.vlrFgts.toFixed(2),
            rate: os.simulacao.taxa.toFixed(2),
            firstInstallment: os.simulacao.vlrParcInic.toFixed(2),
            lastInstallment: os.simulacao.vlrParcFinal.toFixed(2),
            deadline: os.simulacao.prazo,
          }
        : undefined,
    });
    history.push(`${process.env.PUBLIC_URL}/simulador/resultado`);
  }, [history, os, setFinality, setSimulation]);

  return (
    <Container className="container col-md-6 col-11 card-dashboard p-4 mb-3">
      <div className="m-4 mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do orçamento</h2>
          {/* <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button> */}
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-4">
        <div className="dot me-3" />
        <p className="mb-0 sub-text">75% do valor financiado</p>
        <p className="mb-0 font-weight-700 ms-auto">
          {' '}
          R${' '}
          {new Intl.NumberFormat('pt-BR', {
            maximumSignificantDigits: 10,
          }).format(
            simulation.vlrFinanciado +
              simulation.vlrEntrada +
              simulation.vlrFgts +
              simulation.vlrSubsidio -
              simulation.vlrTerreno
          )}
        </p>
      </div>
      <hr className="m-4" />
      <div className="m-4 mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do terreno</h2>
          {/* <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button> */}
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-4">
        <div className="dot dot-2 me-3" />
        <p className="mb-0 sub-text">75% do valor financiado</p>
        <p className="mb-0 font-weight-700 ms-auto">
          {' '}
          R${' '}
          {new Intl.NumberFormat('pt-BR', {
            maximumSignificantDigits: 10,
          }).format(simulation.vlrTerreno)}
        </p>
      </div>
    </Container>
  );
};

export default BudgetAmount;
