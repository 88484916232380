import styled from 'styled-components';

interface IBall {
  color: string;
}

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 24px !important;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .logo {
    width: 12.5%;
  }

  .legend {
    max-width: 250px;

    p {
      width: calc(100% - 20px);
    }
  }
`;

export const Ball = styled.div<IBall>`
  background-color: ${(props) => props.color};
  width: 11px;
  height: 11px;
  border-radius: 50%;
`;

export const Container = styled.div`
  .btn-simulate-again {
    background: #f9f9f9;
    border-radius: 10px;
    color: #707070;
  }

  .box {
    background: #f7f7fd;
    border-radius: 10px;
  }

  .outline-box {
    border: 1px solid #f2f2f2;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
`;
