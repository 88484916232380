import React from 'react';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSimulations } from '~/hooks/Simulations';
import { formatPrice } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';
import { maximumValueProperty } from '~/utils/simulationCalculations';

const CardSimulationResults: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const { simulations } = useSimulations();

  return (
    <div className="col-lg-5 container-simulation">
      <div className="box px-4 py-5 h-100 d-flex flex-column justify-content-around">
        <h2 className="h4 fw-semibold">Sua Simulação</h2>
        <div>
          <p>Valor do imovél considerado pronto</p>
          <p className="h3 fw-semibold">
            {formatCurrency(maximumValueProperty(simulations))}
          </p>
        </div>
        <div>
          <p>Valor da renda </p>
          {serviceOrder.simulacao?.vlrRenda && (
            <p className="h3 fw-semibold">
              {formatPrice(serviceOrder.simulacao.vlrRenda)}
            </p>
          )}
        </div>
        <div>
          <p>Idade</p>
          <p className="h3 fw-semibold">
            {serviceOrder?.cliente?.dt_nascimento &&
              new Date().getFullYear() -
                new Date(
                  serviceOrder?.cliente?.dt_nascimento
                ).getFullYear()}{' '}
            anos
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardSimulationResults;
