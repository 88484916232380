import React, { useCallback } from 'react';
import { IoIosArrowBack } from 'react-icons/io';

import { Container } from './styles';

interface IParams {
  onClick: (target_step: unknown) => void;
  target_step: number;
}

const PreviousButton: React.FC<IParams> = ({ onClick, target_step }) => {
  const handleClick = useCallback(() => {
    onClick(target_step);
  }, [onClick, target_step]);

  return (
    <Container>
      <button
        type="button"
        className="btn btn-primary btn-back d-flex align-items-center justify-content-center me-2"
        onClick={() => handleClick()}
      >
        <IoIosArrowBack size={24} color="#fff" />
      </button>
    </Container>
  );
};

export default PreviousButton;
