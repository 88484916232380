import api from '~/services/api';

/**
 * Clear all local storage with security date
 * @readonly
 */
export function authMiddlewareSignOut(): void {
  try {
    localStorage.removeItem('@HarveyClients:token');
    localStorage.removeItem('@HarveyClients:auth');
  } catch (error: any) {
    throw new Error(
      `Something is wrong, do not possible remove the token - ${
        error?.message || 'fatal error'
      }`
    );
  }
}

/**
 * Set to local storage the token of authentication
 * @param token The token of authentication
 */
export function authMiddlewareToken(token: string | null): void {
  try {
    if (!token) throw new Error(`The token of authentication is required`);

    api.defaults.headers.authorization = `Bearer ${token}`;

    localStorage.setItem('@HarveyClients:token', token);
  } catch (error: any) {
    throw new Error(
      `Something is wrong, do not possible remove the token - ${
        error?.message || 'fatal error'
      }`
    );
  }
}

/**
 * Get the token of authentication from local storage
 * @returns string
 */
export function authMiddlewareGetToken(): string {
  try {
    const token =
      localStorage.getItem('@HarveyClients:token') ||
      JSON.parse(localStorage.getItem('@HarveyClients:auth') || '{}')?.token ||
      null;

    if (!token) throw new Error('The token is not available');

    api.defaults.headers.authorization = `Bearer ${token}`;

    return token;
  } catch (error: any) {
    throw new Error(
      `Something is wrong, do not possible recovery the token - ${
        error?.message || 'fatal error'
      }`
    );
  }
}
