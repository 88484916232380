import React from 'react';
import logo from '~/assets/logos/logo-mcf.svg';
import { Container } from './styles';

const CardMoreInfo: React.FC = () => {
  return (
    <Container className="col-lg-4 mt-5 my-lg-5 container_constructor">
      <div className="box px-4 py-5 h-100">
        <img src={logo} alt="Minha casa financiada" />
        <p className="small my-3">
          Saiba como ser um construtor credenciado do{' '}
          <a
            href="https://minhacasafinanciada.com"
            className="text-secondary fw-bold"
            target="_blank"
            rel="noreferrer"
          >
            <u>Minhacasafinanciada.com</u>
          </a>
        </p>
        <p className="small">
          Conheça melhor a oportunidade da década, dentro do mercado
          imobiliário.
        </p>
      </div>
    </Container>
  );
};

export default CardMoreInfo;
