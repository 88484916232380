import styled from 'styled-components';

export const Content = styled.div`
  padding-left: 6%;
  padding-right: 6%;

  .font-data {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    text-align: right;

    /* Primary */

    color: #39a887;
  }

  button {
    height: 54px;
    border-radius: 20px !important;
  }

  .btn-group {
    padding-left: 80%;
    @media (max-width: 1021px) {
      padding-left: 60%;
    }
    @media (max-width: 768px) {
      padding-left: 6%;
      padding-right: 6%;
    }
  }

  .btn-back {
    width: 62px;
  }

  .btn-next {
    position: absolute;
    left: 800px;
    color: white;
    width: 331px;
    height: 54px;
    background: #39a887;
    border-radius: 20px;
  }

  .box-title {
    padding-left: 6%;
    padding-right: 6%;
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 740px) {
    .button-prox {
      width: 100%;
    }
  }
`;
