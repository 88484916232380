import styled from 'styled-components';

export const Container = styled.div`
  .app-drop-down-container {
    z-index: 1;
    top: 10%;
    @media (max-width: 600px) {
      //position: absolute;
      left: 125%;
      top: 9%;
      height: 30%;
      width: 30%;
    }
    @media (max-width: 1440px) {
      left: 125%;
      top: 9%;
      height: 30%;
      width: 10%;
    }

    .border-0 {
      margin-left: 30%;
    }
  }

  .fondo {
    background-color: transparent;
    color: #ffffff;
    border: none;
  }

  .nav-item {
    margin: 10px auto;
    height: 18%;
    width: 200px;
    z-index: 1;
    margin-left: -8%;
    position: absolute;
    background-color: #f9f9f9;
    border: 2px solid #e5e5e5;
    border-top: none;
    border-radius: 0 0 5% 5%;
    @media (max-width: 1440px) {
      width: 230px;
      margin-left: -14%;
    }
    .border-0 {
      width: 100%;
      height: 38%;
      margin: 5% auto;
      background-color: #f9f9f9;
      :hover {
        background-color: #ebebeb;
      }
    }
  }
  .link-text {
    color: #707070;
    font-size: 14px;
  }
`;
