import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import { Background, Container, Content } from './styles';

import logo from '~/assets/logos/logo.svg';

interface IFormData {
  password: string;
  password_confirmation: string;
}

interface IParams {
  token: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('A nova senha é obrigatória'),
          password_confirmation: Yup.string().when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              'As senhas não combinam'
            ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('users/sessions/change-password', {
          token: params.token,
          password: data.password,
        });

        Toast.fire({
          icon: 'success',
          title: 'Checklist deletado!',
        });

        history.push(`${process.env.PUBLIC_URL}/`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [history, params.token]
  );

  const handleClickLogin = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/login`);
  }, [history]);
  return (
    <Container className="container-fluid">
      <div className="row justify-content-end">
        <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          <img src={logo} alt="logo" className="logo" />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div className="mb-5">
              <h1 className="h2 fw-semibold text-dark-gray mb-5">
                Criar uma nova senha
              </h1>
            </div>
            <div className="mb-5 py-5">
              <div className="mb-4">
                <label htmlFor="password" className="mb-2 fw-medium">
                  Nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Nova senha"
                  name="password"
                  className="input"
                />
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="mb-2 fw-medium"
                >
                  Confirmar nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Repetir senha"
                  name="password_confirmation"
                  className="input"
                />
              </div>
            </div>
            <div className="mb-5">
              <button
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
                onClick={handleClickLogin}
              >
                Enviar
              </button>
            </div>
          </Form>
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4">
          <div className="px-5 pb-5">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Background>
      </div>
    </Container>
  );
};

export default Login;
