import { Form } from '@unform/web';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import InputMask from '~/components/InputMask';
import InputRadio, { IOption } from '~/components/InputRadio';

import logo from '~/assets/logos/logo-gray.svg';

import formatCurrency from '~/utils/formatCurrency';
import {
  calculateEnvironmentsValues,
  getAccumulatedTotal,
  getBudget,
} from '~/utils/serviceOrderCalculations';

import { useServiceOrder } from '~/hooks/ServiceOrder';

import Input from '~/components/Input';
import { Ball, Box, Container } from './styles';

const Summary: React.FC = () => {
  const history = useHistory();
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [budget, setBudget] = useState(0);
  const [accumulatedTotal, setAccumulatedTotal] = useState(0);
  const [entryValue, setEntryValue] = useState<number>(
    serviceOrder.simulacao?.vlrEntrada ?? 0
  );
  const [changeEntryValue, setChangeEntryValue] = useState(false);
  const [diffBudget, setDiffBudget] = useState(budget - accumulatedTotal);
  const [changeEntryValueSelected, setChangeEntryValueSelected] =
    useState<IOption>({
      id: 'Não',
      value: 'Não',
    });
  const [bdi, setBdi] = useState(serviceOrder.orc_parametros?.margem ?? 0);

  const options = useMemo(
    () => ({
      labels: [
        'Total disponível para construção',
        'Valor do seu orçamento total',
        'Diferença entre seu valor e total disponível',
      ],
      colors: ['#5B4DBE', '#FFBE21', '#A1C64D'],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '80%',
          },
        },
      },
    }),
    []
  );

  useEffect(() => {
    const budgetValue = getBudget(serviceOrder);
    const accumulatedTotalValue = getAccumulatedTotal(serviceOrder);

    setBudget(budgetValue);
    setAccumulatedTotal(accumulatedTotalValue);
    setDiffBudget(budgetValue - accumulatedTotalValue);
  }, [serviceOrder]);

  const series = useMemo(
    () => [budget, accumulatedTotal, diffBudget],
    [budget, diffBudget, accumulatedTotal]
  );

  const handleChangeEntryValue = useCallback((option: IOption) => {
    setChangeEntryValueSelected(option);
    setChangeEntryValue(option.id === 'Sim');
  }, []);

  const handleChangeEntry = useCallback(
    (bdiValue: number, entry: number) => {
      setBdi(bdiValue);
      setEntryValue(entry);
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...serviceOrder.simulacao,
          vlrEntrada: entry,
        },
        orc_parametros: {
          ...serviceOrder.orc_parametros,
          margem: bdiValue,
        },
        orc_ambientes: calculateEnvironmentsValues(serviceOrder, bdiValue),
      });
    },
    [serviceOrder, setServiceOrder]
  );

  const handleClickNextDashboard = useCallback(async () => {
    await api.put(
      `service-orders/${serviceOrder.id}/simulations/${serviceOrder.simulacao?.id}/`,
      serviceOrder.simulacao
    );
    history.push(`/dashboard/${serviceOrder.key}`);
  }, [history, serviceOrder]);

  return (
    <>
      <Container>
        <div className="col-12 mt-5">
          <div className="row">
            <div className="col-lg-6">
              <Box className="h-100 p-5">
                <h2 className="h5 text-dark-gray fw-semibold">
                  Resultado do orçamento
                </h2>
                <div className="position-relative d-flex align-items-center justify-content-center">
                  <Chart
                    type="donut"
                    options={options}
                    series={series}
                    width={200}
                    height={200}
                    className="my-4"
                  />
                  <img
                    src={logo}
                    alt="logo"
                    className="logo position-absolute"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#5B4DBE" className="me-2" />
                    <p className="mb-0">Total disponível para construção</p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(budget)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center my-3">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#FFBE21" className="me-2" />
                    <p className="mb-0">Valor do seu orçamento total</p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(accumulatedTotal)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#A1C64D" className="me-2" />
                    <p className="mb-0">
                      Diferença entre seu valor e total disponível
                    </p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(diffBudget)}
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-lg-6">
              <Form
                onSubmit={() => {
                  return false;
                }}
                className="h-100 px-5 pb-0 d-flex flex-column justify-content-start gap-5"
              >
                <div>
                  <label className="w-100">
                    BDI:
                    <span className="text-primary">*</span>
                    <Input
                      className="input"
                      type="number"
                      placeholder="00.00"
                      name="bdi"
                      value={bdi}
                      onChange={(e) =>
                        handleChangeEntry(
                          parseInt(e.target.value, 10),
                          entryValue
                        )
                      }
                    />
                  </label>
                </div>
                <div>
                  <p>
                    Deseja alterar o valor da entrada?
                    <span className="text-primary">*</span>
                  </p>
                  <InputRadio
                    name="change_entry_value"
                    options={[
                      {
                        id: 'Sim',
                        value: 'Sim',
                      },
                      {
                        id: 'Não',
                        value: 'Não',
                      },
                    ]}
                    className="justify-content-start"
                    onChange={handleChangeEntryValue}
                    selected={changeEntryValueSelected}
                  />
                </div>
                {changeEntryValue && (
                  <label className="w-100">
                    Valor de entrada:
                    <span className="text-primary">*</span>
                    <InputMask
                      kind="money"
                      name="entry"
                      placeholder="R$0,00"
                      className="input"
                      value={formatCurrency(entryValue)}
                      onChange={(e) => handleChangeEntry(bdi, e.target.value)}
                    />
                  </label>
                )}
                <button
                  type="submit"
                  className="btn btn-primary py-3"
                  onClick={handleClickNextDashboard}
                >
                  Continuar
                </button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Summary;
