import React from 'react';
import { Content } from './styles';
import { StepsProvider } from '../Hooks/useStep';
import StepsForm from './StepsForm';

const Steps: React.FC = () => {
  return (
    <StepsProvider>
      <Content className="w-100 px-4 px-lg-0  mt-4 mt-lg-0">
        <StepsForm />
      </Content>
    </StepsProvider>
  );
};

export default Steps;
