/* eslint-disable */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import { Background, Container, Content, Step } from './styles';

import logo from '~/assets/logos/logo.svg';
import Pdf from '~/documents/TERMOMCFC50K.pdf';
import { useClient } from '~/hooks/Client';
import { useAuthContext } from '~/hooks/contexts/Auth';

interface IFormData {
  name: string;
  email: string;
  password: string;
  // foreign: string;
  // birthdate: string;
  // document: string;
  // profession: string;
  // city: string;
  // state: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [nameError, setNameError] = useState(false);
  const [step, setStep] = useState(1);
  const [stepTitle, setStepTitle] = useState('Crie sua conta gratuita');
  const [stepMessage, setStepMessage] = useState(
    'Para começar esta jornada, insira seus dados, por favor'
  );
  const { client } = useClient();
  const { signIn } = useAuthContext();
  const [nameLengthError, setNameLengthError] = useState('');

  const handleChangeName = useCallback((e) => {
    if (formRef.current) {
      formRef.current.setErrors({});
    }
    const nameParts = e.target.value.trim().split(' ');
    if (nameParts.length === 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, []);

  const handleClickChangeStep = useCallback(
    (newStep: number) => {
      if (!nameError) {
        switch (newStep) {
          case 2:
            setStepTitle('Queremos te conhecer!');
            setStepMessage(
              'Para sabermos melhor o seu perfil, insira os campos'
            );
            break;
          case 3:
            setStepTitle('Localização');
            setStepMessage('Em qual estado reside?');
            break;

          default:
            setStepTitle('Crie sua conta gratuita');
            setStepMessage(
              'Para começar esta jornada, insira seus dados, por favor'
            );
            break;
        }
        setStep(newStep);
      } else if (formRef.current) {
        formRef.current.setErrors({
          name: 'Preencha seu nome completo',
        });
      }
    },
    [nameError]
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(isChecked);
  }, [isChecked]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
          // birthdate: Yup.string().required('A data de nascimento é obrigatória'),
          // document: Yup.string().required('O CPF é obrigatório'),
          // profession: Yup.string().required('A profissão é obrigatória'),
          // city: Yup.string().required('A cidade é obrigatória'),
          // state: Yup.string().required('O estado é obrigatória'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        const nameParts = data.name.trim().split(' ');

        if (nameParts.length <= 1) {
          nameParts.length <= 1
            ? setNameLengthError('Digite seu nome completo')
            : setNameLengthError('');
        } else {
          setNameLengthError('');

          const response = await api.post(
            `/`,
            formData
          );
          Swal.fire(
            'Cadastro realizado',
            'Seu registro foi realizado com sucesso.',
            'success'
          );
          signIn(data);
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (
            Object.keys(errors).find(
              (key) => key === 'name' || key === 'email' || key === 'password'
            )
          ) {
            console.log(errors);
            setStep(1);
            setStepTitle('Bem-vindo!');
            setStepMessage('Para iniciar, complete os campos desejados');
          }
          // } else if (
          //   Object.keys(errors).find(
          //     (key) =>
          //       key === 'birthdate' || key === 'document' || key === 'profession'
          //   )
          // ) {
          //   console.log(errors);
          //   setStep(2);
          //   setStepTitle('Queremos te conhecer!');
          //   setStepMessage('Para sabermos melhor o seu perfil, insira os campos');
          // } else if (Object.keys(errors).find((key) => key === 'state')) {
          //   console.log(errors);
          //   setStep(3);
          //   setStepTitle('Localização');
          //   setStepMessage('Em qual estado reside?');
          // }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <>
      <Container className="container-fluid">
        <div className="row justify-content-end">
          <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4" />
          <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
            <img src={logo} alt="logo" className="logo" />
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="px-3 px-sm-4 px-md-5 px-lg-0"
            >
              <div className="mb-5">
                {step > 1 && (
                  <button
                    type="button"
                    onClick={() => handleClickChangeStep(step - 1)}
                    className="bg-transparent border-0 mb-3"
                  >
                    <IoIosArrowBack size={24} color="#fff" />
                  </button>
                )}
                <h1 className="h2 fw-semibold mb-0 text-dark-gray">
                  {stepTitle}
                </h1>
                <p className="text-gray">{stepMessage}</p>
              </div>
              <Step active>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    Nome Completo
                  </label>
                  <Input
                    placeholder="Nome Completo"
                    name="name"
                    onChange={handleChangeName}
                    className="input"
                  />
                  {nameLengthError && (
                    <span className="small text-danger error">
                      {nameLengthError}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    E-mail
                  </label>
                  <Input
                    type="email"
                    placeholder="email@example.com"
                    name="email"
                    className="input"
                    value={client?.email}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    Senha
                  </label>
                  <Input
                    type="password"
                    placeholder="******"
                    name="password"
                    className="input"
                  />
                </div>
                <div>
                  <div>
                    <div>
                      <div>
                        <input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          value="Paneer"
                          checked={isChecked}
                          onChange={handleOnChange}
                        />
                        <label htmlFor="email" className="mb-4 px-1 fw-medium">
                          Eu li e aceito os
                        </label>
                        <a
                          href={Pdf}
                          target="_blank"
                          className="mb-5 fw-medium text-decoration-underline text-gray fw-normal fs-6"
                        >
                          termos de uso.
                        </a>
                      </div>
                      <div className="result button-show text-alert">
                        {isChecked == true
                          ? ''
                          : 'O termos de uso é obrigatório'}
                      </div>
                    </div>
                  </div>
                </div>
              </Step>
              <div className="py-4">
                {/* {step === 3 && ( */}

                <button
                  type="submit"
                  className="btn btn-primary mb-4 w-100 fw-semibold"
                  disabled={!isChecked}
                >
                  Cadastrar
                </button>
                {/* )} */}
                {/* {step !== 3 && (
                  <button
                    type="button"
                    className="btn btn-primary mb-5 w-100 fw-semibold"
                    onClick={() => handleClickChangeStep(step + 1)}
                  >
                    Enviar
                  </button>
                )} */}

                <p className="text-gray text-center">
                  Já possui conta?{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/login`}
                    className="text-green fw-medium"
                  >
                    Entrar
                  </Link>
                </p>
              </div>
            </Form>
          </Content>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
