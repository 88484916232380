/* eslint-disable */
import styled from 'styled-components';

interface IBanner {
    src: string;
}

interface IAvatar {
    src: string;
}

interface IGallery {
    show: boolean;
}

interface IImgGallery {
    src: string;
}

export const Container = styled.div`
  .input-search {
    background: #fcfcfc;
    border: 1px solid #b8b9ba;
    width: calc(100% - 50px);
  }
`;

export const Banner = styled.div<IBanner>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  height: 353px;
  margin-bottom: -200px;
`;

export const Profile = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(185, 185, 185, 0.2);
  border-radius: 15px;

  @media screen and (min-width: 992px) {
    margin-top: 50px;
    h1 {
      width: calc(100% - 300px);
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 576px) {
    width: 95px;
    height: 95px;
    left: 15%;
    top: -90px;
  }

  @media screen and (min-width: 768px) {
    left: 8%;
  }

  @media screen and (min-width: 992px) {
    width: 125px;
    height: 125px;
    left: 50%;
    top: -80px;
  }
`;

export const Img = styled.button<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  border: none;
  margin-bottom: 25px;

  @media screen and (min-width: 992px) {
    height: 260px;
  }
`;

export const Gallery = styled.div<IGallery>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .opacity {
    border: none;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .photo {
    overflow: auto;
    width: 80%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    ::-webkit-scrollbar-thumb {
      background-color: #d2d2d255;
      border-radius: 4px;
    }
  }

  .button-close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

export const ImgGallery = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
`;

export const ButtonSubmit = styled.button`
  position:relative;
  width: 250px;
  height: 60px;
  font-size: 20px;
  color: #FFFF;
  border: none;
  border-radius: 4px;
  transition: width .5s
  border-radius .5s;
  transition: color 0.2s;
`;
