/* eslint-disable */
/* eslint-disable no-restricted-syntax */
import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
    BsFillArrowDownCircleFill,
    BsInfoCircleFill,
    BsStarFill,
    BsTrashFill,
} from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';
import { MdEngineering, MdWork } from 'react-icons/md';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '~/services/api';

import Loading from '~/components/Loading';
import {
    Alert,
    Builder,
    BuilderAvatar,
    BuildersData,
    Button,
    Container,
    FloatBuilders,
    Img
} from './styles';

import avatarPhoto from '~/assets/defaults/avatar.png';
import { useClient } from '~/hooks/Client';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useAuthContext } from '~/hooks/contexts/Auth';
import { IMBuilder } from '~/models/Builder';
import { IMCityOs } from '~/models/City';
import { IMPhoto } from '~/models/Photo';
import { debounce } from '~/utils/debounce';

interface IRequest {
    builder_id: number;
    answered: boolean;
}

interface IOs {
    requests: IRequest[];
}

interface ICity {
    municipio: string;
    estado: {
        nome: string;
        sigla: string;
    };
}


interface IBuilder {
    id: number;
    avatar: string;
    name: string;
    description: string;
    location: string;
    jobs: number;
    score: string;
    buildings: IMPhoto[];
    selected?: boolean;
    alreadySent?: boolean;
}

interface IBuilderData {
    data: IMBuilder[];
    last_page: number;
}

let cancelToken: CancelTokenSource | undefined;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

interface IParams {
    os_id: string;
    os_key: string;
}

const Builders: React.FC = () => {
    const history = useHistory();
    const params = useParams<IParams>();
    const { client } = useClient()
    const [builders, setBuilders] = useState<IBuilder[]>([]);
    const [buildersPagination, setBuildersPagination] = useState<IBuilder[]>([]);
    const [page, setPage] = useState(1);
    const [avatarPhotoValue, setAvatarPhotoValue] = useState(avatarPhoto);
    const [lastPage, setLastPage] = useState(0);
    const [search, setSearch] = useState('');
    const [selectedBuilders, setSelectedBuilders] = useState<IBuilder[]>([]);
    const [showSelectedBuilder, setShowSelectedBuilder] = useState(false);
    const [requestsQuantity, setRequestsQuantity] = useState(3);
    const [loading, setLoading] = useState(true);
    const [sendingBuilder, setSendingBuilder] = useState(false);
    const { serviceOrder, recoveryServiceOrderData, clearServiceOrder } = useServiceOrder();
    const { user } = useAuthContext();

    const [municipality, setMunicipality] = useState<IMCityOs>({} as IMCityOs);
    const [noConstrutor, setNoConstrutor] = useState('');

    const localization = useCallback(async () => {
        try {
            const local = await api.get<IMCityOs>(
                `service-orders/${params.os_id}/terrains`
            );
            setMunicipality({
                city_id: local.data.city_id,
                city: local.data.city,
                state: local.data.state,
                state_id: local.data.state_id,
                sigla: local.data.sigla,
            });
        } catch (err) {
            Toast.fire({
                icon: 'warning',
                title: `Erro ao carregar o endereço do terreno`,
            });
        }
    }, [params.os_id]);

    useEffect(() => {
        if ((params.os_key && !serviceOrder?.key) || (params.os_key && params.os_key !== serviceOrder.key)) {
            recoveryServiceOrderData(params.os_key).catch((_rejects) => {
                history.push('/solicitacoes');
            });
        } else if (!params.os_key || !serviceOrder?.key) {
            history.push('/solicitacoes');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localization();
    }, [localization, params.os_id]);

    const loadBuilders = useCallback(
        async (pageData: number, searchData?: string) => {
            if (cancelToken) {
                cancelToken.cancel('canceled');
            }

            try {
                setLoading(true)
                cancelToken = axios.CancelToken.source();

                const responseByCity = await api.get<IBuilderData>(
                    `service-orders/${serviceOrder.id}/builders`,
                    {
                        cancelToken: cancelToken.token,
                        params: {
                            page: pageData,
                            search: searchData,
                            city: municipality.city_id,
                        },
                    }
                );

                const responseAll = await api.get<IBuilderData>(
                    `service-orders/${serviceOrder.id}/builders`,
                    {
                        cancelToken: cancelToken.token,
                        params: {
                            page: pageData,
                            search: searchData,
                            city: municipality.city_id,
                            noCity: true,
                        },
                    }
                );

                const buildersRawFilter = [...responseByCity.data.data, ...responseAll.data.data]
                const buildersByCityAndState = buildersRawFilter.filter((builder, index) => builder.id !== buildersRawFilter[index + 1]?.id);

                const data: IBuilder[] = buildersByCityAndState.map((builder) => parseBuilder(builder) || {} as IBuilder);
                const builderAlready: IBuilder[] = []
                
                // eslint-disable-next-line no-restricted-syntax
                for (const request of serviceOrder.requests) {
                    const builderIndex = data.findIndex(
                        (builder) => builder.id === request.builder_id && !!request.answered
                    );

                    if (builderIndex >= 0) {
                        data[builderIndex].selected = true;
                        data[builderIndex].alreadySent = true;
                        
                        builderAlready.push(data[builderIndex])                        
                    }
                }

                setLastPage(responseAll.data.last_page);
                
                // if (serviceOrder.requests.length > 0) {
                //     setRequestsQuantity(serviceOrder.requests.length || 3);
                // }

                console.log('builderAlready');
                console.table(builderAlready);
                
                if(builderAlready.length > 0) {
                    setSelectedBuilders(builderAlready);
                }

                setLoading(false);
                setNoConstrutor(
                    'Infelizmente não foram localizadas construtoras neste estado'
                );

                setBuildersPagination(data);
                setLoading(false)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                // console.log(error);
                setLoading(false)
                if (error.message !== 'canceled') {
                    Toast.fire({
                        icon: 'error',
                        title: `Erro ao carregar construtores`,
                    });
                }
            }
        },
        [params.os_id, municipality.state_id, serviceOrder, selectedBuilders, setSelectedBuilders]
    );

    /**
     * Carrega todos os builders e aplica todas as regras de negocio para processar os dados dos construtores, 
     * liberando a interação do usuário com a pagina
     */
    useEffect(() => {
        if (serviceOrder?.key && params.os_id) loadBuilders(1)
    }, [serviceOrder, params]);

    useEffect(() => {
        if (buildersPagination.length > 0 && selectedBuilders.length <= 0) {
            const buildersRequested: IBuilder[] = []

            serviceOrder?.requests?.forEach(request => {
                console.log('requests', !!request.answered, request.answered)
                if (!!request.answered === false) buildersRequested.push(parseBuilder(request.builder, 'loadBuilders') || {} as IBuilder)
            })

            if (buildersRequested.length > 0) setSelectedBuilders((buildersRequested as unknown as IBuilder[]))
        }
    }, [buildersPagination, serviceOrder])


    const parseBuilder = (builder: IMBuilder, _caller:string = "") => {
        try {
            let { name } = builder;
            let description = '-';
            let location = '-';
            let avatar = `${process.env.REACT_APP_API_URL}builders/construtech/logo/${builder.id}`;
            const jobs = 0;
            let score = '0,0';
            let buildings: IMPhoto[] = [];

            if (builder.construtech) {
                name = builder.construtech.nome_fantasia || '';

                if (builder.engenheiro) {
                    description = builder.engenheiro.description;
                    buildings = builder.engenheiro.photos.slice(0, 3);
                    if (builder.engenheiro.municipios[0]) {
                        location = `${builder.engenheiro.municipios[0].municipio} - ${builder.engenheiro.municipios[0].estado.sigla}`;
                    }
                    if (builder.engenheiro.scores.length > 0) {
                        const totalScore = builder.engenheiro.scores.reduce(
                            (previousValue, currentValue) => {
                                return previousValue + currentValue.content;
                            },
                            0
                        );
                        score = (totalScore / builder.engenheiro.scores.length)
                            .toFixed(1)
                            .replace('.', ',');
                    }
                }

                if (builder.construtech.logo_url) {
                    avatar = builder.construtech.logo_url;
                }
            } else if (builder.engenheiro) {
                name = builder.engenheiro.nome;
                description = builder.engenheiro.description;
                buildings = builder.engenheiro.photos.slice(0, 3);

                const totalScore = builder.engenheiro.scores.reduce(
                    (previousValue, currentValue) => {
                        return previousValue + currentValue.content;
                    },
                    0
                );
                if (totalScore > 0) {
                    score = (totalScore / builder.engenheiro.scores.length)
                        .toFixed(1)
                        .replace('.', ',');
                } else {
                    score = totalScore
                        .toFixed(1)
                        .replace('.', ',');
                }

                if (builder.engenheiro.foto_url) {
                    avatar = builder.engenheiro.foto_url;
                }

                if (builder.engenheiro.municipios[0]) {
                    location = `${builder.engenheiro.municipios[0].municipio} - ${builder.engenheiro.municipios[0].estado.sigla}`;
                }
            }

            // TODO - LOG PRA DEBUG
            // console.table({
            //     id: builder.id,
            //     avatar,
            //     name,
            //     description,
            //     location,
            //     jobs,
            //     score,
            //     buildings,
            //     selected: false
            // })

            return {
                id: builder.id,
                avatar,
                name,
                description,
                location,
                jobs,
                score,
                buildings,
                selected: false
            };
        } catch (error) {
            // do anything
        }
    }
    
    const handleLoad = useCallback(async () => {
        try {
            if (page < lastPage) {
                loadBuilders(page + 1, search);
                setPage(page + 1);
            }
        } catch (error) {
            Toast.fire({
                icon: 'warning',
                title: `Erro ao carregar página`,
            });
        }
    }, [lastPage, loadBuilders, page, search]);

    const handleSearch = useCallback(
        (e) => {
            if (e.target.value?.trim() === "") return

            loadBuilders(1, e.target.value);
            setSearch(e.target.value);
        },
        [loadBuilders]
    );

    const handleClick = useCallback(
        (builder: IBuilder, index: number) => {
            const newBuilders = buildersPagination.slice();
            const cachedSelectedBuilders = selectedBuilders.slice();
            const selectedBuildersIndex = cachedSelectedBuilders.findIndex(
                (selectedBuilder) => selectedBuilder.id === builder.id
            );
            const totalSelectedBuilders = serviceOrder.requests.length + (selectedBuilders.filter(b => serviceOrder.requests.find(r => r.builder_id !== b.id))).length

            // TODO - LOG PARA DEBUG            
            // console.log('Builder entity', requestsQuantity, totalSelectedBuilders, serviceOrder.requests.length, selectedBuilders.length)
            // console.table(newBuilders)
            // console.table(buildersPagination)
            // console.table(newBuilders[index])
            

            if (newBuilders.length <=0) {
                loadBuilders(1)
                return
            }
            
            if (newBuilders[index]?.selected) {
                newBuilders[index]['selected'] = false;
                cachedSelectedBuilders.splice(selectedBuildersIndex, 1);

                setSelectedBuilders(cachedSelectedBuilders);
                setBuildersPagination(newBuilders);

                return;
            }

            if (totalSelectedBuilders < requestsQuantity) {
                newBuilders[index]['selected'] = true;

                setSelectedBuilders((state) => [...state, builder]);

                setBuildersPagination(newBuilders);
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: 'Maximo de construtores atingidos',
                });
            }
        },
        [buildersPagination, requestsQuantity, selectedBuilders,serviceOrder]
    );

    const handleClickRemove = useCallback(
        (selectedBuilder) => {
            const newBuilders = builders.slice();
            const builderIndex = newBuilders.findIndex(
                (builder) => builder.id === selectedBuilder.id
            );

            if (builderIndex >= 0) {
                newBuilders[builderIndex].selected = false;
                setBuilders(newBuilders);
            }

            const newSelectedBuilders = selectedBuilders.filter(
                (builder) => builder.id !== selectedBuilder.id
            );

            if (newSelectedBuilders.length === 0) {
                setShowSelectedBuilder(false);
            }

            setSelectedBuilders(newSelectedBuilders);
        },
        [builders, selectedBuilders]
    );

    const handleClickActive = useCallback(() => {
        setShowSelectedBuilder((state) => !state);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            setSendingBuilder(true)
            const selectedBuildersPromise = new Promise<void>((resolve) => {
                const newSelectedBuilders = selectedBuilders.filter(
                    (builder) => !builder.alreadySent
                );

                if (newSelectedBuilders.length > 0) {
                    newSelectedBuilders.forEach(async (selectedBuilder, index) => {
                        const formData = {
                            builder_id: selectedBuilder.id,
                            os_id: params.os_id,
                            status_id: 6,
                            answered: 0,
                        };
                        await api.post(
                            `requests`,
                            formData
                        );

                        if (newSelectedBuilders.length === index + 1) {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });

            await selectedBuildersPromise;

            const newSelectedBuilders = selectedBuilders.map((selectedBuilder) => ({
                ...selectedBuilder,
                alreadySent: true,
            }));
            
            const newBuilders = buildersPagination.map((builder) => {
                const builderSelected = newSelectedBuilders.find(
                    (selectedBuilder) => selectedBuilder.id === builder.id
                );
                if (builderSelected) {
                    return builderSelected;
                }
                return builder;
            });

            setSelectedBuilders(newSelectedBuilders);
            setBuildersPagination(newBuilders);
            setShowSelectedBuilder(false);
            history.push('/solicitacoes');
        } catch (error) {
            setSendingBuilder(false)
            Toast.fire({
                icon: 'warning',
                title: `Erro ao redirecionar página`,
            });
        }
    }, [builders, history, params.os_id, selectedBuilders]);

    return (
        <>
            <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-3 col-lg-1 order-0">
                            <Link
                                to={`${process.env.PUBLIC_URL}/solicitacoes`}
                                className="bg-transparent border-0"
                            >
                                <RiArrowLeftSLine size={46} color="#B8B9BA" />
                            </Link>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-0 ms-lg-n5 me-lg-5 mt-4 mt-lg-0">
                            <input
                                placeholder="Buscar"
                                className="w-100 rounded-pill px-4 py-3 input-search"
                                onChange={(event) => debounce(450, handleSearch, event)}
                            />
                        </div>
                        <div className="col-9 col-lg-5 order-0 order-lg-1 d-flex justify-content-end">
                            {
                                user?.name || ''
                            }
                        </div>
                        <div className="col-12 order-1 mt-5 mb-4">
                            <div className="row-12 d-flex flex-row align-items-center gap-2">
                                <Alert
                                    background='#76db9b'
                                    className="d-flex align-items-center justify-content-center w-100 w-lg-50 py-3 ms-auto mb-5"
                                >
                                    <BsInfoCircleFill size={25} color="#1da750" />
                                    <p className="mb-0 text-dark-gray ps-3">
                                        Você já tem selecionado
                                        {" "}
                                        {/* {
                                            selectedBuilders.filter(b => (serviceOrder?.requests?.find(r => r.builder_id !== b.id) || false))?.length || 0
                                        } */}
                                        <b>{(serviceOrder?.requests?.length || 0)} construtor</b>
                                    </p>
                                </Alert>

                                <Alert className="d-flex align-items-center justify-content-center w-100 w-lg-50 py-3 ms-auto mb-5">
                                    <BsInfoCircleFill size={25} color="#FFBA69" />
                                    <p className="mb-0 text-dark-gray ps-3">
                                        Você poderá solicitar apenas <b>3 propostas</b> por vez
                                    </p>
                                </Alert>

                            </div>
                            <h2 className="mb-4 text-dark-gray fw-semibold">
                                Escolher construtor(es)
                            </h2>
                            {
                                buildersPagination.map((builder, index) => (
                                    <Builder
                                        key={index}
                                        className="row align-items-center ps-lg-5 pe-0"
                                    >
                                        <div className="col-lg-10 py-5 d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                                            <BuilderAvatar
                                                src={builder.avatar}
                                                className="mb-3 mb-lg-0"
                                            />
                                            <div className="px-2 px-lg-4 w-100 w-sm-75 w-xl-auto">
                                                <h3 className="mb-3 text-dark-gray fw-semibold text-center text-lg-start">
                                                    {builder.name}
                                                </h3>
                                                <p className="text-gray text-center text-lg-start">
                                                    {builder.description}
                                                </p>
                                                <div className="d-flex justify-content-center my-4">
                                                    <div className="d-flex align-items-center">
                                                        <p className="text-light-gray small d-flex align-items-center">
                                                            <FaMapMarkerAlt
                                                                size={19}
                                                                color="#39A887"
                                                                className="me-1"
                                                            />
                                                            {builder.location}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mx-3">
                                                        <p className="text-light-gray small d-flex align-items-center">
                                                            <MdWork
                                                                size={19}
                                                                color="#8A8A8A"
                                                                className="me-1"
                                                            />
                                                            {builder.jobs}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <p className="text-light-gray small d-flex align-items-center">
                                                            <BsStarFill
                                                                size={19}
                                                                color="#FFBA41"
                                                                className="me-1"
                                                            />
                                                            {builder.score}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="d-flex d-lg-none buildings ms-auto w-100 mb-4">
                                                    {builder.buildings.map((building) => (
                                                        <Img key={building.id} src={building.photo_url} />
                                                    ))}
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center">
                                                    <Button
                                                        type="button"
                                                        className="btn px-lg-5 py-2 me-lg-2 mb-3 mb-lg-0 w-100 w-lg-auto buttonSelector"
                                                        onClick={() => handleClick(builder, index)}
                                                        disabled={builder.alreadySent}
                                                        selected={builder.selected}
                                                    >
                                                        {builder.alreadySent ? (
                                                            'Já enviado'
                                                        ) : (
                                                            <>
                                                                {builder.selected
                                                                    ? 'Remover construtor'
                                                                    : 'Selecionar construtor'}
                                                            </>
                                                        )}
                                                    </Button>
                                                    <Link
                                                        to={`/${params.os_key}/construtor/${builder.id}`}
                                                        className="btn btn-secondary px-lg-5 py-2 w-100 w-lg-auto"
                                                    >
                                                        Mais informações
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block col-lg-2 px-0">
                                            <div className="d-flex flex-column buildings ms-auto w-100">
                                                {builder.buildings.map((building) => (
                                                    <Img key={building.id} src={building.photo_url} />
                                                ))}
                                            </div>
                                        </div>
                                    </Builder>
                                ))
                            }

                            {
                                !loading && buildersPagination.length <= 0 && (
                                    <div className="d-flex justify-content-center mt-5">
                                        <h2 className="mb-4 text-gray">Infelizmente não foram localizadas construtoras neste estado</h2>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Container>
            {selectedBuilders.length > 0 && (
                <FloatBuilders>
                    {selectedBuilders.length > 0 && (
                        <span className="arrow_floatBuilders">
                            <BsFillArrowDownCircleFill size={38} />
                        </span>
                    )}
                    <button
                        type="button"
                        className="btn btn-light-gray p-4 rounded-circle position-relative"
                        onClick={handleClickActive}
                    >
                        <MdEngineering size={30} color="#fff" />
                        <span>{selectedBuilders.length}</span>
                    </button>
                    <BuildersData
                        active={showSelectedBuilder}
                        height={selectedBuilders.length * 200 + 50}
                    >
                        {selectedBuilders.map((selectedBuilder) => (
                            <Builder key={selectedBuilder.id} className="p-3">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <BuilderAvatar
                                            src={selectedBuilder.avatar}
                                            className="avatar"
                                        />
                                    </div>
                                    <div className="ms-2">
                                        <h3 className="h6 text-dark-gray fw-semibold">
                                            {selectedBuilder.name?.split(' ')[0] || ''}{' '}
                                            {selectedBuilder.name?.split(' ')[1] || ''}
                                        </h3>
                                        <p className="mb-0 small">{selectedBuilder.description}</p>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="d-flex align-items-center me-3">
                                        <p className="text-light-gray small d-flex align-items-center">
                                            <FaMapMarkerAlt
                                                size={19}
                                                color="#39A887"
                                                className="me-1"
                                            />
                                            {selectedBuilder.location}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <p className="text-light-gray small d-flex align-items-center">
                                            <MdWork size={19} color="#8A8A8A" className="me-1" />
                                            {selectedBuilder.jobs}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <p className="text-light-gray small d-flex align-items-center">
                                            <BsStarFill size={19} color="#FFBA41" className="me-1" />
                                            {selectedBuilder.score}
                                        </p>
                                    </div>
                                </div>
                                {!selectedBuilder.alreadySent ? (
                                    <button
                                        type="button"
                                        className="btn px-0"
                                        onClick={() => handleClickRemove(selectedBuilder)}
                                    >
                                        <BsTrashFill size={24} color="#FF1A50" />{' '}
                                        <b className="text-alert fw-normal">Remover Construtor</b>
                                    </button>
                                ) : (
                                    <p className="mb-0 btn px-0 cursor-default">
                                        <HiCheck size={24} color="#39A887" />{' '}
                                        <b className="text-primary fw-normal">Já enviado</b>
                                    </p>
                                )}
                            </Builder>
                        ))}
                        <button
                            type="button"
                            disabled={sendingBuilder}
                            className="btn btn-primary w-50 ms-3"
                            onClick={handleSubmit}
                        >
                            { sendingBuilder ? 'Aguarde...' : 'enviar' }
                        </button>
                    </BuildersData>
                </FloatBuilders>
            )}
            <Loading active={loading} />
        </>
    );
};

export default Builders;