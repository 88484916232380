import React from 'react';

import { useSimulations } from '~/hooks/Simulations';
import { formatPrice } from '~/utils/format';
import { Bar, Circle, Container } from './styles';

const CardPlotComparison: React.FC = () => {
  const { installments } = useSimulations();

  return (
    <Container className="col-lg-7 mt-5 mt-lg-0">
      <div className="outline-box card-comparation py-5 h-100">
        <h2 className="h4 px-4 title-comparation">Comparação das parcelas</h2>
        <div className="d-flex justify-content-center my-5">
          {installments.map((installment) => (
            <Bar
              key={installment.title}
              percent={installment.percent}
              color={installment.color}
            />
          ))}
        </div>
        {installments.length > 0 &&
          installments.map((installment) => (
            <div
              key={installment.title}
              className="d-flex justify-content-lg-between px-lg-4 align-items-lg-center titles-comparation my-lg-3"
            >
              <div className="d-flex align-items-center ps-4 ps-lg-0">
                <Circle color={installment.color} />
                <p className="ms-2 mb-0">{installment.title}</p>
              </div>
              <p className="mb-0 fw-bold text-dark-gray ms-3">
                {formatPrice(installment.installment)}
              </p>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default CardPlotComparison;
