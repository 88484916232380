import styled from 'styled-components';

export const Container = styled.div`
  .ancho {
    //600px
    min-width: calc(350px * ${(props) => props.color});
  }
  h11 {
    /* Subtitle */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }
  pp {
    /* Subtitle */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .btn-simulate-again {
    background: #f9f9f9;
    border-radius: 10px;
    color: #707070;
  }

  .body-card {
    padding: 0px 5rem;
    @media (max-width: 375px) {
      padding: 0px 4px;
    }
  }

  .box {
    background: #f7f7fd;
    border-radius: 10px;
  }

  .outline-box {
    border: 1px solid #f2f2f2;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .title-comparation {
    margin: 0px 2rem;
  }

  .titles-comparation {
    margin: 0px 11rem;
    @media (max-width: 1400px) {
      margin: 0;
      align-self: start;
    }
  }

  .container_user {
    @media (max-width: 991px) {
      margin-top: 3%;
      margin-left: 3rem;
    }
  }

  .box-container-secundary {
    padding: 0px 5rem;
    @media (max-width: 768px) {
      padding: 0px 3rem;
    }
    @media (max-width: 425px) {
      padding: 0px 1rem;
      margin-left: 1rem;
    }
  }

  .card-comparation {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .container-simulation {
    padding: 0px 1rem;
  }

  @media (max-width: 740px) {
    .container_button {
      padding: 0px 21px !important;
    }
    .container_info {
      padding: 0px 21px !important;
    }
    .container_info_secondary {
      position: relative;
      .button_clickeame {
        position: absolute;
        left: 35%;
        top: 50%;
      }
    }
    .container_simulacion {
      order: 2;
    }
    .container_constructor {
      order: 3;
    }
  }
`;
