import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBoxButtons {
  show?: boolean;
}
export const Container = styled.div`
  .profile-icon {
    background-color: #03c2ff;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    p {
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: #000000;
      -webkit-text-fill-color: #fff;
      font-weight: bold;
    }
  }
  .client-name {
    font-size: 12px;
  }
  .new-notification {
    svg {
      color: #707070;
    }
    div {
      background: #ff1a50;
      width: 8px;
      height: 8px;
      border-radius: 25px;
      top: 0;
      position: absolute;
      right: -4px;
    }
  }
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 600;
  }
  .add-financing {
    background-color: #e6e7e8;
    border-radius: 25px;
    width: 40px;
    height: 40px;
  }
  .refazer-button {
    background-color: #f9f9f9;
    color: #707070;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    .form-input {
      border: none;
      border-bottom: 0.3px solid #b8b9ba;
      border-radius: 0;
    }
    .add-financing {
      background-color: #e6e7e8;
      border-radius: 25px;
      width: 40px;
      height: 40px;
    }

    .profile-icon {
      background-color: #03c2ff;
      border-radius: 25px;
      height: 40px;
      width: 40px;
      p {
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #000000;
        -webkit-text-fill-color: #fff;
        font-weight: bold;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      color: #414142;
    }

    .border-none {
      border: none;
    }

    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #707070;
    }

    label {
      font-weight: 500;
      color: #707070;
      font-size: 14px;
      line-height: 17px;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .btn-take-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid transparent;
      color: #ffffff;
      font-weight: bold;
      background: #2e90fd;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
    }

    .buttons-group {
      button {
        width: 300px;
        border-radius: 30px;
        border: 2px solid transparent;
      }

      .btn-cancel,
      .btn-another {
        color: #d9d9d9;
        font-weight: 500;
        border-color: #d9d9d9;
      }

      .btn-submit {
        color: #ffffff;
        font-weight: bold;
        background: #39a887;
      }
    }
  }
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;
export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;
