import styled from 'styled-components';

export const Container = styled.div`
  .btn-nextt {
    color: white;
    background: #39a887;
    border-radius: 20px;
    margin-right: 40px;
    width: 110%;
    :hover {
      background: #079269;
    }
  }
`;
