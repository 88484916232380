/* eslint-disable */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import { Background, Container, Content } from './styles';

import logo from '~/assets/logos/logo.svg';

interface IFormData {
    email: string;
}

const Login: React.FC = () => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = useCallback(async (data: IFormData) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string()
                    .email('Informe um e-mail válido')
                    .required('O e-mail é obrigatório'),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.post('/forgot-password', {
                email: data.email,
                origin: 'client',
            });

            history.push(`${process.env.PUBLIC_URL}/login`);
            setEmailSent(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErros(error);
                formRef.current?.setErrors(errors);
            } else {
                Swal.fire(
                    'Opss...',
                    'Ocorreu um erro ao logar, verifique seus dados.',
                    'error'
                );
            }
        }
    }, []);
    // const handleClickResetarSenha = useCallback(() => {
    //   history.push(`${process.env.PUBLIC_URL}/resetar-senha/2`);
    // }, [history]);

    return (
        <Container className="container-fluid">
            <div className="row justify-content-end">
                <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
                    <img src={logo} alt="logo" className="logo" />
                    {!emailSent ? (
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            className="px-3 px-sm-4 px-md-5 px-lg-0"
                        >
                            <div className="mb-5">
                                <h1 className="h2 text-dark-gray fw-semibold mb-1">
                                    Esqueceu a senha?
                                </h1>
                                <p className="text-gray">
                                    Tudo bem, insira seu e-mail para restaurar
                                </p>
                            </div>
                            <div className="mb-5 py-5">
                                <label htmlFor="email" className="mb-2 fw-medium">
                                    E-mail
                                </label>
                                <Input
                                    type="email"
                                    placeholder="email@example.com"
                                    name="email"
                                    className="input"
                                />
                            </div>
                            <div className="mb-5">
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-5 w-100 fw-semibold"
                                // onClick={handleClickResetarSenha}
                                >
                                    Enviar
                                </button>
                                <p className="text-gray text-center">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/login`}
                                        className="text-gray fw-medium"
                                    >
                                        Voltar ao login
                                    </Link>
                                </p>
                            </div>
                        </Form>
                    ) : (
                        <div className="px-3 px-sm-4 px-md-5 px-lg-0">
                            <div className="mb-4 pb-5">
                                <h2 className="fw-semibold mb-1 text-center">
                                    Email enviado com sucesso
                                </h2>
                                <p className="text-gray text-center">
                                    Verifique sua caixa de entrada, spam ou lixo eletrônico
                                </p>
                            </div>
                            {/* <Lottie
                options={{
                  animationData: sendMail,
                  autoplay: true,
                  loop: false,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={160}
                width={160}
              /> */}
                            <Link
                                to={`${process.env.PUBLIC_URL}/`}
                                className="btn btn-primary mt-4 w-100 fw-semibold"
                            >
                                Voltar para Login
                            </Link>
                        </div>
                    )}
                </Content>
                <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4" />
            </div>
        </Container>
    );
};

export default Login;