import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { gapi } from 'gapi-script';

import { useAuthContext } from '~/hooks/contexts/Auth';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import { Background, Container, Content } from './styles';

import logo from '~/assets/logos/logo.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useClient } from '~/hooks/Client';
import { useServiceOrder } from '~/hooks/ServiceOrder';

const clientId =
  '278279255619 - bsou6i8n4go465j653r3ee7g812f4fhq.apps.googleusercontent.com';
interface IFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn, recovery } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { client } = useClient();
  const { serviceOrder } = useServiceOrder();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn(data).then(() => {
          recovery().then(() => {
            if (serviceOrder.key) {
              history.push(`dashboard/${serviceOrder.key}`);
            } else {
              history.push('solicitacoes');
            }
          });
        });
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn, serviceOrder]
  );

  useEffect(() => {
    function start() {
      gapi.client.init({ clientId, scope: '' });
    }
    gapi.load('client:auth2', start);
  });

  return (
    <Container className="container-fluid">
      <div className="row justify-content-end">
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4" />
        <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          <img src={logo} alt="logo" className="logo" />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div className="mb-5">
              <h1 className="fw-semibold text-dark-gray">Entrar</h1>
              <p className="text-gray">
                Bem-vindo de volta! {client?.name} Por favor acesse sua conta e
                aproveite
              </p>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="mb-2 fw-medium">
                E-mail
              </label>
              <Input
                type="email"
                placeholder="email@example.com"
                name="email"
                className="input"
                value={client?.email}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="mb-2 fw-medium">
                Senha
              </label>
              <Input
                type="password"
                placeholder="******"
                name="password"
                className="input"
              />
            </div>
            <Link
              to={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
              className="d-block mt-4 mb-5 text-gray text-end fw-medium"
            >
              Esqueceu a senha?
            </Link>
            <div className="mb-5">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                {loading ? 'Aguarde...' : 'Entrar'}
              </button>

              <p className="text-gray text-center">
                Não possui conta?{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/cadastre-se`}
                  className="text-green fw-medium"
                >
                  Cadastre-se
                </Link>
                {/* <div className="py-2">
                  <LoginButton /> */}
                {/* <LogoutButton /> */}
                {/* </div> */}
              </p>
            </div>
          </Form>
        </Content>
      </div>
    </Container>
  );
};

export default Login;
