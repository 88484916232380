// Models
import { IMUser } from '~/models/User';

// Types
import { AuthAction, AuthState } from '../types';

// Middlewares
import { authMiddlewareSignOut, authMiddlewareToken } from '../middleware';
import { authSetTokenCacheStorage } from '../middleware/cache';

export function authActionSignOut(): AuthAction {
  authMiddlewareSignOut();

  return {
    type: 'auth_sign_out',
    payload: {} as IMUser,
  };
}

export function authActionUpdateUser(userData: Partial<IMUser>): AuthAction {
  return {
    type: 'auth_user_update',
    payload: userData,
  };
}

export function authActionUpdateAuthToken(token: string | null): AuthAction {
  authMiddlewareToken(token);

  return {
    type: 'auth_token_update',
    payload: token,
  };
}

export function authActionUpdateAuth(authData: AuthState): AuthAction {
  authSetTokenCacheStorage(authData);

  return {
    type: 'auth_update',
    payload: authData,
  };
}
