import styled from 'styled-components';

interface IStep {
  active: boolean;
}

export const Container = styled.div``;

export const Step = styled.div<IStep>`
  display: ${(props) => (props.active ? 'block' : 'none')};

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    .date-input {
      border: none;
      border-radius: 0;
    }

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }

  .input-date {
    position: unset;

    .calendar {
      width: 100%;
      top: unset;
      bottom: 0;
      left: 0;

      .DayPicker {
        padding: 0 15.25rem;
        height: 420px;
        background: #ffffff;
        border-radius: 22px 22px 0px 0px;
        border: 0;
        filter: drop-shadow(0px -4px 20px rgba(0, 0, 0, 0.15));

        .DayPicker-wrapper {
          max-width: 364px;
          background: transparent;
          margin-right: auto !important;
          margin-left: auto !important;

          .DayPicker-Caption {
            div {
              color: #161616;
            }
          }

          .DayPicker-Weekday {
            color: #333333;
          }

          .DayPicker-Day--available:not(.DayPicker-Day--outside) {
            background: transparent;
            border-radius: 50% !important;
            color: #afafaf;

            :hover {
              color: #ffffff !important;
              background: #079269 !important;
            }
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
            color: #ffffff !important;
            background: #079269 !important;
          }

          .DayPicker-Day--outside {
            opacity: 0;
          }
        }
      }

      :before {
        content: '';
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: -60vh;
      }
    }
  }
`;
