import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import NavBar from '~/components/NavBar';

import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    api.get(`session`).catch(() => {
      // signOut();
    });
  }, [signOut, location.pathname]);

  return <>{children}</>;
};

export default DefaultLayout;
