import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useServiceOrder } from '~/hooks/ServiceOrder';

import Loading from '~/components/Loading';
import AddBoxInfo from './Components/AddBoxInfo';
import ButtonNext from './Components/ButtonNext';
import CardAnnualProjection from './Components/CardAnnualProjection';
import CardRangeValueAndYears from './Components/CardRangeValueAndYears';
import CardSimulationConditions from './Components/CardSimulationConditions';
import CardTotalValueFInanced from './Components/CardTotalValueFInanced';
import ModalInformation from './Components/ModalInformation';
import SimulationRedoButton from './Components/SimulationRedoButton';
import { Container } from './styles';

const Amortization: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!serviceOrder?.finalidade_id) {
      history.push('/');
    }
  }, [serviceOrder?.finalidade_id]);

  useEffect(() => {
    if (serviceOrder?.simulacao?.amortizacao) {
      setLoading(false);
    }
  }, [serviceOrder?.simulacao?.amortizacao]);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 row my-4">
            <div className="col-6 d-flex justify-content-between align-items-center">
              <AddBoxInfo setShowModal={setShowModal} />
            </div>
            <div className="col-6 d-flex justify-content-between align-items-center">
              <SimulationRedoButton />
            </div>
          </div>

          <div className="row box-details">
            <div className="col-lg-7 mb-4 mb-lg-5">
              <CardTotalValueFInanced />
            </div>
            <div className="col col-lg-5 mb-4 mb-lg-5">
              <CardSimulationConditions />
            </div>
            <div className="container_tempo col-lg-7 mb-4 mb-lg-0">
              <CardRangeValueAndYears />
            </div>
            <div className="col-lg-5 mb-4 mb-lg-0">
              <CardAnnualProjection />
            </div>
            <div className="container_button col-lg-5 ms-auto mt-5">
              <ButtonNext />
            </div>
          </div>
        </div>
      </div>
      <ModalInformation
        showDetail={showModal}
        handleCloseDetail={() => setShowModal(false)}
      />
      <Loading
        active={loading}
        textMessage="Estamos preparando a melhor simulação para você"
      />
    </Container>
  );
};

export default Amortization;
