import React, { useMemo } from 'react';

import Chart from 'react-apexcharts';
import logo from '~/assets/logos/logo-gray.svg';
import { formatPrice } from '~/utils/format';
import { Ball, Box } from './styles';

interface ISimulation {
  vlrFinanciado: number;
  prazo: number;
  taxa: number;
}

interface IParams {
  simulation: ISimulation;
}

const SimulationConditions: React.FC<IParams> = ({ simulation }) => {
  const options = useMemo(
    () => ({
      options: {
        labels: ['Valor financiado', 'Valor de entrada', 'Valor do FGTS'],
        colors: ['#8675FE', '#FEBA6A', '#FF718B'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
      },
      series: [simulation.vlrFinanciado],
    }),
    [simulation.vlrFinanciado]
  );
  return (
    <div className="" style={{ height: '516px', width: '45%', padding: '0px' }}>
      <Box className="h-100 p-5">
        <h2 className="h5 text-dark-gray fw-semibold">
          Resultado do orçamento
        </h2>
        <div className="position-relative d-flex align-items-center justify-content-center">
          <Chart
            type="donut"
            options={options.options}
            series={options.series}
            width={200}
            height={200}
            className="my-4"
          />
          <img src={logo} alt="logo" className="logo position-absolute" />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center legend">
            <Ball color="#5B4DBE" className="me-2" />
            <p className="mb-0">Total disponível para construção</p>
          </div>
          <p className="mb-0 text-dark-gray fw-bold">
            {formatPrice(simulation.vlrFinanciado)}
          </p>
        </div>
      </Box>
    </div>
  );
};

export default SimulationConditions;
