import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Services
import api from '~/services/api';

// Components
import { IOption } from '~/components/Select';

// Modesl
import { IMAmortization } from '~/models/Amortization';
import { IMFinality } from '~/models/Finality';
import { IMImmobileType } from '~/models/InmobileTypes';
import { IMRoom } from '~/models/Room';
import { IMRoomSize } from '~/models/RoomSize';
import { IMSchoolingLevel } from '~/models/SchoolingLevels';
import { IMStates } from '~/models/States';

// Hooks
import { useServiceOrder } from './ServiceOrder';

interface DefaultDataContextData {
  ambientes: IMRoom[];
  ambientes_tamanhos: IMRoomSize[];
  estados_brasileiros: IMStates[];
  finalidades: IMFinality[];
  schooling_levels: IOption[];
  tipos_imoveis: IMImmobileType;
  setDefaultData(): void;
  amortizations: IMAmortization[];
}

export const DefaultDataContext = createContext<DefaultDataContextData>(
  {} as DefaultDataContextData
);

interface ISchoolingData {
  id: number;
  schooling_level: string;
  selected: boolean;
}

export const DefaultDataProvider: React.FC = ({ children }) => {
  const { serviceOrder } = useServiceOrder();
  const [data, setData] = useState({} as DefaultDataContextData);
  const [schoolingLevels, setSchoolingLevels] = useState<IOption[]>([]);
  const [amortizations, setAmortizations] = useState<IMAmortization[]>([]);

  useEffect(() => {
    if (serviceOrder?.finalidade_id) {
      const payload = `?revenue=${serviceOrder.simulacao?.vlrRenda}${
        serviceOrder.simulacao?.vlrImovel
          ? `&property_value=${serviceOrder.simulacao?.vlrImovel || 0.0} `
          : ''
      }`;

      api
        .get<IMAmortization[]>(
          `amortizations/${serviceOrder.finalidade_id}${payload}`
        )
        .then((response) => {
          setAmortizations(response.data);
        });
    }
  }, [serviceOrder]);

  const getSchoolingLevels = useCallback(async () => {
    try {
      const response = await api.get<IMSchoolingLevel>(`schooling-levels`);

      const dataSchool: IOption[] = (
        response.data as unknown as ISchoolingData[]
      ).map((schoolingData) => ({
        id: schoolingData.id,
        value: schoolingData.schooling_level,
        selected: false,
      }));
      setSchoolingLevels(dataSchool);
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    getSchoolingLevels();
  }, [getSchoolingLevels]);

  const getDefaultData = useCallback(() => {
    if (!schoolingLevels.length) {
      getSchoolingLevels();
    }
    return new Promise((resolve, reject) => {
      if (!data.finalidades) {
        api
          .get<DefaultDataContextData>(`queries/inicial_data`, {
            baseURL: `${process.env.REACT_APP_API_URL}`,
          })
          .then((response) => {
            setData(response.data);
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(true);
      }
    });
  }, [data.finalidades, getSchoolingLevels, schoolingLevels]);

  const defaultDataParams = useMemo(() => {
    return {
      ambientes: data.ambientes,
      ambientes_tamanhos: data.ambientes_tamanhos,
      estados_brasileiros: data.estados_brasileiros,
      finalidades: data.finalidades,
      tipos_imoveis: data.tipos_imoveis,
      schooling_levels: schoolingLevels,
      setDefaultData: getDefaultData,
      amortizations,
    };
  }, [data, getDefaultData, schoolingLevels, amortizations]);

  return (
    <DefaultDataContext.Provider value={defaultDataParams}>
      {children}
    </DefaultDataContext.Provider>
  );
};

export function useDefaultData(): DefaultDataContextData {
  const context = useContext(DefaultDataContext);

  if (!context) {
    throw new Error(
      'useDefaultData must be used within an DefaultDataProvider'
    );
  }

  return context;
}
