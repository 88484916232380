/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import { Container } from './styles';

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
}

interface IParams {
  simulation: ISimulation;
}
const PropertyValuation: React.FC<IParams> = ({ simulation }) => {
  const options = useMemo(
    () => ({
      options: {
        chart: {
          height: 350,
          type: 'radialBar' as const,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: false,
              total: {
                show: false,
                label: 'Total',
              },
            },
            hollow: {
              size: '50%',
            },
            startAngle: 0,
            endAngle: 275,
            track: {
              startAngle: 0,
              endAngle: 360,
            },
          },
        },
        labels: [
          'Avaliação de mercado final do imóvel pós executado',
          'Mais Barato do que comprar um imóvel novo',
          'Valor Disponivel',
        ],
        colors: ['#8676FF', '#96D9FF', '#FFBA69'],
      },
      series: [
        simulation.vlrFinanciado * 1.55,
        ((simulation.vlrFinanciado +
          simulation.vlrEntrada +
          simulation.vlrFgts +
          simulation.vlrSubsidio -
          simulation.vlrTerreno) /
          (simulation.vlrFinanciado * 1.55)) *
          100,
        ((simulation.vlrFinanciado * 1.55 -
          simulation.vlrFinanciado +
          simulation.vlrEntrada +
          simulation.vlrFgts +
          simulation.vlrSubsidio -
          simulation.vlrTerreno) /
          (simulation.vlrFinanciado * 1.55)) *
          100,
      ],
    }),
    [
      simulation.vlrFinanciado * 1.55,
      simulation.vlrFinanciado +
        simulation.vlrEntrada +
        simulation.vlrFgts +
        simulation.vlrSubsidio -
        simulation.vlrTerreno,
      simulation.vlrFinanciado * 1.55 -
        simulation.vlrFinanciado +
        simulation.vlrEntrada +
        simulation.vlrFgts +
        simulation.vlrSubsidio -
        simulation.vlrTerreno,
    ]
  );

  return (
    <Container className="container col-md-6 col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h6 mb-0">Avaliação do Imóvel</h2>
      </div>
      <div className="position-relative d-flex align-items-center justify-content-center my-5">
        <Chart
          options={options.options}
          series={options.series}
          type="radialBar"
          height={350}
          className="p-0 m-0"
        />
      </div>
      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot me-3" />
            <p className="mb-0 w-75">
              Avaliação de mercado final do imóvel pós executado
            </p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrFinanciado * 1.55)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-2 me-3" />
            <p className="mb-0 w-75">
              Mais Barato do que comprar um imóvel novo
            </p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(
              simulation.vlrFinanciado +
                simulation.vlrEntrada +
                simulation.vlrFgts +
                simulation.vlrSubsidio -
                simulation.vlrTerreno
            )}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-3 me-3" />
            <p className="mb-0 w-75">Valor Disponivel</p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(
              simulation.vlrFinanciado * 1.55 -
                simulation.vlrFinanciado +
                simulation.vlrEntrada +
                simulation.vlrFgts +
                simulation.vlrSubsidio -
                simulation.vlrTerreno
            )}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PropertyValuation;
