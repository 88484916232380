import { RiArrowDownSLine } from 'react-icons/ri';

import { Container } from './styles';

const StepMenu: React.FC = () => {
  return (
    <Container>
      <div>
        <button type="button" className="border-0 fondo">
          <RiArrowDownSLine size={25} color="#707070" />
        </button>
      </div>
    </Container>
  );
};

export default StepMenu;
