import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '~/services/api';
import { Container } from './styles';

import { useFinality } from '~/hooks/Finality';
import { useSimulation } from '~/hooks/Simulation';

interface IOsResponse {
  id: number;
  key: string;
  created_at: string;
  construtor?: {
    name: string;
  };
  finalidade: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    id: number;
    os_id: number;
    amortizacao_id: number;
    vlrRenda: number;
    prazo: number;
    taxa: number;
    vlrParcInic: number;
    vlrParcFinal: number;
    vlrFinanciado: number;
    vlrImovPronto: number;
    vlrEntrada: number;
    vlrEntradaReal: number;
    vlrFgts: number;
    vlrTerreno: number;
    vlrSubsidio: number;
    vlrAvaliado: number;
    vlrAprovado: number;
    vlrSolicitado: number;
    vlrImovel: number;
    tipo_imovel_id?: number;
    comentario_analise?: string;
    dt_analise?: string;
    resultAnalise_id?: number;
    amortizacao?: {
      id: number;
      ordem: number;
      amortizacao: string;
      banco_id: number;
      taxa: number;
      percentual: number;
      prazo: number;
      funcao: string;
      indexador?: number;
      taxa_nominal?: number;
      teto_maximo?: number;
      renda_min?: number;
      renda_max?: number;
      observacao?: string;
      enfase: number;
      banco: {
        id: number;
        banco: string;
      };
    };
  };
  cliente: {
    dt_nascimento: string;
  };
}

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
  vlrSolicitado: number;
}

interface IParametersOrc {
  acabamento: Record<string, unknown>;
  eletrica: string;
  terreno: string;
}
interface IPropertyEnvironmentsProps {
  simulation: ISimulation;
  osId: number;
  osKey?: string;
  parameters_orc: IParametersOrc;
  ambientes_orc: IBudgetRoom[];
}
interface IDataBudgetE {
  quantity: IQuantityData[];
}
interface IRoomSize {
  ambiente_id: number;
  tamanho_id: number;
}
interface IBudgetRoom {
  id: number;
  ambiente_tamanho: IRoomSize;
  metragem: number;
  vlrLucro: number;
}
interface IQuantityData {
  cant: number;
  amb: string;
}

interface IParams {
  os_id: string;
}
const PropertyEnvironments: React.FC<IPropertyEnvironmentsProps> = ({
  simulation,
  osId,
  parameters_orc,
  ambientes_orc,
  osKey,
}) => {
  const params = useParams<IParams>();
  const { setFinality } = useFinality();
  const { setSimulation } = useSimulation();
  const [os, setOs] = useState({} as IOsResponse);
  const [metreage, setMetreage] = useState(0);
  const [orc_ambiente, setAmbientes] = useState<IBudgetRoom[]>([]);
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );

  useEffect(() => {
    api.get(`service-orders/${osKey}`).then((response) => {
      setOs(response.data);
    });
  }, [osKey]);

  const [data] = useState([
    { name: 'Quartos', id: 1, quantity: 0 },
    { name: 'Banheiro', id: 2, quantity: 0 },
    { name: 'Closets', id: 3, quantity: 0 },
    { name: 'Cozinha', id: 4, quantity: 0 },
    { name: 'Despensa', id: 5, quantity: 0 },
    { name: 'Área Gourmet', id: 6, quantity: 0 },
    { name: 'Área de serviço', id: 7, quantity: 0 },
    { name: 'Sala', id: 8, quantity: 0 },
    { name: 'Lavabo', id: 9, quantity: 0 },
    { name: 'Escritório', id: 10, quantity: 0 },
    { name: 'Garagem', id: 11, quantity: 0 },
    { name: 'Piscina', id: 12, quantity: 0 },
  ]);

  useEffect(() => {
    api
      .get<IDataBudgetE>(
        `/service-orders/${osId}/budget/environments/get-quantities`
      )
      .then((response) => {
        const quantityEnvironments: IQuantityData[] = response.data[
          'quantity'
        ]?.map((element) => ({
          cant: element.cant,
          amb: element.amb,
        }));
        setQuantityAmbientes(quantityEnvironments);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const meteringEnvironments = ambientes_orc.reduce(
      (previousValue, currentValue) => previousValue + currentValue.metragem,
      0
    );
    const weightedAverage =
      ambientes_orc
        .map((enviroment) => enviroment.metragem * enviroment.vlrLucro)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        ) / meteringEnvironments;

    const dataBudget =
      simulation.vlrSolicitado +
      simulation.vlrFgts +
      simulation.vlrEntrada -
      simulation.vlrTerreno;

    setMetreage(dataBudget / weightedAverage);
    setAmbientes(ambientes_orc);
  }, [
    ambientes_orc,
    simulation.vlrEntrada,
    simulation.vlrFgts,
    simulation.vlrSolicitado,
    simulation.vlrTerreno,
  ]);

  const history = useHistory();
  const handleClickEditar = useCallback(() => {
    setFinality({
      id: os.finalidade.id,
      title: os.finalidade.finalidade,
      description: os.finalidade.descricao,
      image: '',
    });

    const [year, month, day] = os.cliente.dt_nascimento.split('-');
    const birthdate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );

    setSimulation({
      os_id: os.id,
      birthdate,
      revenue: os.simulacao.vlrRenda,
      amortization: os.simulacao.amortizacao
        ? {
            id: os.simulacao.amortizacao.id,
            title: os.simulacao.amortizacao.amortizacao,
            financedValue: os.simulacao.vlrFinanciado.toFixed(2),
            entryValue: os.simulacao.vlrEntrada.toFixed(2),
            realEntryValue: os.simulacao.vlrEntradaReal.toFixed(2),
            fgtsAmount: os.simulacao.vlrFgts.toFixed(2),
            rate: os.simulacao.taxa.toFixed(2),
            firstInstallment: os.simulacao.vlrParcInic.toFixed(2),
            lastInstallment: os.simulacao.vlrParcFinal.toFixed(2),
            deadline: os.simulacao.prazo,
          }
        : undefined,
    });
    history.push(`${process.env.PUBLIC_URL}/monte-sua-casa/${os.key || -1}`);
  }, [history, os, setFinality, setSimulation]);

  return (
    <Container className="container col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="mx-4 mt-4 mt-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="h6 mb-0">Ambientes do Imóvel</h2>
          {/* <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button> */}
        </div>
        <div className="d-flex justify-content-between align-items-center mb-0">
          <p>Tamanho do m² estimado da casa.</p>
        </div>
        <p className="mb-0">
          Acabamento escolhido:{' '}
          <span> {parameters_orc?.acabamento?.tipo_acabamento || ''}</span>
        </p>
      </div>
      <div className="d-flex subtitle align-items-center justify-content-end mb-4 mx-4">
        <h3 className="mb-0 font-weight-700 ms-auto">
          <h2 className="h6 mt-4 text-grey">
            Harvey sugere essa metragem para você construir
          </h2>
          <div className="mt-3">{metreage.toFixed(2)} m²</div>
        </h3>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(data.length / 2) >= index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center ms-3">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux) => (
                          <>
                            {aux.ambiente_tamanho.tamanho_id === 1 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon d-flex justify-content-center align-items-center bg-info">
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1 &&
                                        'G'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 2 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon d-flex justify-content-center align-items-center bg-warning">
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2 &&
                                        'M'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 3 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center"
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3 &&
                                        'P'}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(data.length / 2) < index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center ms-3">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux) => (
                          <>
                            {aux.ambiente_tamanho.tamanho_id === 1 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon d-flex justify-content-center align-items-center bg-info">
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1 &&
                                        'G'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 2 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon d-flex justify-content-center align-items-center bg-warning">
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2 &&
                                        'M'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 3 &&
                              aux.ambiente_tamanho.ambiente_id ===
                                index + 1 && (
                                <div className="col-2">
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center"
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3 &&
                                        'P'}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PropertyEnvironments;
