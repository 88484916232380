import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 26%;
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #8675fe;
  }
  .dot-2 {
    background-color: #feba6a !important;
  }
  .dot-3 {
    background-color: #ff718b !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }

  @media (max-width: 988px) {
    display: flex;
    height: 23%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    height: auto;
  }
`;

export const Box = styled.div`
  .logo {
    padding-top: 5%;
    width: 17%;
  }
`;
