import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAmortization {
  highlight?: boolean;
  titleColor?: string;
}
interface IButtonIcon {
  highlight?: boolean;
}
interface IBar {
  percent: number;
  color: string;
}

interface ICircle {
  color: string;
}

interface IRange {
  percent: number;
}

export const Container = styled.div`
  h11 {
    /* Subtitle */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }
  pp {
    /* Subtitle */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .btn-simulate-again {
    background: #f9f9f9;
    border-radius: 10px;
    color: #707070;
  }

  .box {
    background: #f7f7fd;
    border-radius: 10px;
  }

  .outline-box {
    border: 1px solid #f2f2f2;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .title-comparation {
    margin: 0px 2rem;
  }

  .titles-comparation {
    margin: 0px 12rem;
    @media (max-width: 767px) {
      margin: 0px;
    }
  }

  .container_user {
    @media (max-width: 991px) {
      margin-top: 3%;
      margin-left: 3rem;
    }
  }

  @media (max-width: 740px) {
    .container_button {
      padding: 0px 21px !important;
    }
    .container_info {
      padding: 0px 21px !important;
    }
    .container_info_secondary {
      position: relative;
      .button_clickeame {
        position: absolute;
        left: 35%;
        top: 50%;
      }
    }
    .container_simulacion {
      order: 2;
    }
    .container_constructor {
      order: 3;
    }
  }
`;

export const ButtonIcon = styled.div<IButtonIcon>`
  button {
    top: 3rem;
    right: 3rem;
  }
`;
export const Amortization = styled.div<IAmortization>`
  background: ${(props) => (props.highlight ? '#11bb77' : '#ffffff')};
  border: 1px solid #f2f2f2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 21px;
  cursor: pointer;

  :hover {
    background: #11bb77;
    h2 {
      color: #ffffff;
    }
    p:not(.h4),
    small,
    span {
      color: #ffffff;
    }
  }

  h2 {
    color: ${(props) => (props.titleColor ? props.titleColor : '#fff')};
  }

  p:not(.h4),
  small,
  span {
    color: ${(props) => (props.highlight ? '#ffffff' : '#707070')};
    font-size: 14px;
  }
  p:not(.h4) {
    font-size: 16px;
    font-weight: 600;
  }
  p.h5 {
    font-size: 18px;
    color: ${(props) => (props.highlight ? '#ffffff' : '#414142')};
  }
  .rate {
    color: ${(props) => (props.highlight ? '#ffffff' : '#39A887')};
  }
  span.span_icon {
    font-weight: 500;
  }
  span {
    font-weight: 400;
  }

  .chain {
    background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .chain-top {
    :after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
    }
  }

  .chain-bottom {
    :before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(50% + 5px);
      background: ${(props) => (props.highlight ? '#0A8151' : '#B8B9BA')};
      top: 0;
    }
  }

  .installment {
    width: calc(100% - 25px);
  }
`;

export const Bar = styled.div<IBar>`
  background: rgba(215, 215, 215, 0.3);
  border-radius: 10px;
  height: 115px;
  width: 16px;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    transition-duration: 0.3s;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
    height: ${(props) => props.percent}%;
    background: ${(props) => props.color};
  }

  + div {
    margin-left: 24px;
  }
`;

export const Circle = styled.div<ICircle>`
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

export const Range = styled.label<IRange>`
  input[type='range'] {
    -webkit-appearance: none;

    :focus {
      outline: none;
    }

    ::-webkit-slider-runnable-track {
      height: 4px;
      background: rgb(57, 168, 135);
      background: linear-gradient(
        90deg,
        rgba(57, 168, 135, 1) 0%,
        rgba(57, 168, 135, 1) ${(props) => props.percent}%,
        rgba(230, 231, 232, 1) ${(props) => props.percent}%,
        rgba(230, 231, 232, 1) 100%
      );
      border-radius: 4px;
    }

    ::-webkit-slider-thumb {
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
      border: 3px solid #ffffff;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #39a887;
      -webkit-appearance: none;
      margin-top: -6px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }
  }
  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
  .modal-header {
    border-bottom: 0 none;
  }
  .modal-footer {
    border-top: 0 none;
  }
`;

export const ModalDetail = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 3rem;
    h2 {
      color: #414142;
    }
    .close {
      font-size: 3.2rem;
      font-weight: 100;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;

      .sr-only {
        display: none;
      }
    }
    .modal-header {
      svg {
        margin-right: 1rem;
      }
    }
    .modal-body {
      margin-top: 3rem;
    }

    .modal-detail-amortization_data {
      color: #079269;
      font-weight: 600;
      margin-left: 2rem;
    }
  }
`;
