import React, { useEffect, useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { Link, useHistory, useParams } from 'react-router-dom';

import Loading from '~/components/Loading';

import mcfHouse from '~/assets/icons/mcf-house.svg';
import tools from '~/assets/icons/tools.svg';

import { useDefaultData } from '~/hooks/DefaultData';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import ModalBudget from './ModalBudget';
import { Container } from './styles';
import Summary from './Summary';

interface IParams {
  osId: string;
}

const BuildYourHouse: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { recoveryServiceOrderData, serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();
  const [establishedParameters, setEstablishedParameters] = useState(false);
  const [establishedEnvironments, setEstablishedEnvironments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalBudget, setShowModalBudget] = useState(false);

  useEffect(() => {
    if (params.osId && !serviceOrder?.key) {
      recoveryServiceOrderData(params.osId).then((_resolver) =>
        setLoading(false)
      );
    } else if (!params.osId || !serviceOrder?.key) {
      history.push('/');
    }
  }, [params.osId]);

  useEffect(() => {
    if (!serviceOrder?.simulacao?.vlrFinanciado) {
      history.push(`/simulador/${params.osId}`);
      return;
    }

    if (serviceOrder.id) {
      setEstablishedParameters(!!serviceOrder.orc_parametros);
      setEstablishedEnvironments(
        !!serviceOrder.orc_ambientes && serviceOrder.orc_ambientes.length > 0
      );
    }
  }, [serviceOrder]);

  return (
    <>
      <Container>
        <div className="mx-0 mx-lg-5 mt-2 mt-lg-5 mb-5">
          <div className="row mb-3 ">
            <div className="col-auto col-lg-8">
              <div
                className="row mt-4"
                style={{
                  marginLeft: '60px',
                }}
              >
                <p className="text-gray mb-4">
                  Nesta parte do processo, você definirá exatamente como será a
                  sua casa.
                </p>
              </div>
            </div>

            <div className="col-lg-4 container_user">
              <div className="container_budget mt-4">
                <button
                  type="button"
                  className="border-0"
                  onClick={() => setShowModalBudget(true)}
                  style={{
                    width: '200px',
                    height: '50px',
                    backgroundColor: '#39A887',
                    borderRadius: '50px',
                  }}
                >
                  <p
                    style={{
                      marginTop: '15px',
                      color: '#FFFFFF',
                      fontStyle: 'normal',
                      fontSize: '15px',
                      lineHeight: '12px',
                    }}
                  >
                    Já tenho orçamento
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div className="row box-phases">
            <div className="col-2 col-md-1 d-flex justify-content-center position-relative line-bottom pb-5">
              <div className="badge d-flex align-items-center justify-content-center">
                <HiLockOpen size="16" color="#fff" />
              </div>
            </div>
            <div className="col-10 col-md-11 pb-5">
              <div
                className={`row box py-5 px-4 ${
                  establishedParameters ? 'old' : ''
                }`}
              >
                <div className="col-9 col-md-11">
                  <h2 className="h5 text-dark-gray">
                    Acabamento{' '}
                    {establishedParameters && (
                      <span>
                        <BsCheckCircleFill size={16} color="#0BE9B6" />
                      </span>
                    )}
                  </h2>
                </div>
                <div className="col-3 col-md-1">
                  <img src={tools} alt="tools" className="ms-auto d-block" />
                </div>
                <div className="col-md-11">
                  <p className="small normal-md">
                    Escolha tipo de acabamento, tipo de terreno.
                  </p>
                </div>

                <div className="col-7 col-sm-5 col-md-4 col-lg-3 mt-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/monte-sua-casa/${params.osId}/acabamento`}
                    className={`btn btn-light  ${
                      establishedParameters ? 'old' : ''
                    }`}
                  >
                    {!establishedParameters ? 'Iniciar' : 'Modificar'}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2 col-md-1 d-flex justify-content-center position-relative line-top">
              <div
                className={`badge ${
                  !establishedParameters ? 'disabled' : ''
                } d-flex align-items-center justify-content my-auto`}
              >
                {!establishedParameters ? (
                  <HiLockClosed size="16" color="#b2b2b2" />
                ) : (
                  <HiLockOpen size="16" color="#fff" />
                )}
              </div>
            </div>
            <div className="col-10 col-md-11">
              <div
                className={`row box ${
                  !establishedParameters ? 'disabled' : ''
                } ${establishedEnvironments ? 'old' : ''} py-5 px-4`}
              >
                <div className="col-9 col-md-11">
                  <h2 className="h5 text-dark-gray">
                    Sobre a casa{' '}
                    {establishedEnvironments && (
                      <span>
                        <BsCheckCircleFill size={16} color="#0BE9B6" />
                      </span>
                    )}
                  </h2>
                </div>
                <div className="col-3 col-md-1">
                  <img
                    src={mcfHouse}
                    alt="mcf-house"
                    className="ms-auto d-block"
                  />
                </div>
                <div className="col-lg-11">
                  <p
                    className="small normal-md"
                    style={{
                      width: '377px',
                      height: '15px',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      lineHeight: '15px',
                    }}
                  >
                    Escolha todos os cômodos e quantidade de quartos e banheiros
                  </p>
                </div>
                {establishedParameters && (
                  <div className="col-7 col-sm-5 col-md-4 col-lg-3 mt-4">
                    <Link
                      to={`${process.env.PUBLIC_URL}/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/quantidade`}
                      className={`btn btn-light ${
                        establishedEnvironments ? 'old' : ''
                      }`}
                    >
                      {!establishedEnvironments ? 'Iniciar' : 'Modificar'}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row box-details">
            {establishedEnvironments && <Summary />}
          </div>
        </div>
        <Loading active={loading} />
        <ModalBudget
          show={showModalBudget}
          onHide={() => setShowModalBudget(false)}
        />
      </Container>
    </>
  );
};

export default BuildYourHouse;
