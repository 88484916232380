import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IFinality {
  id: number;
  title: string;
  description: string;
  image: string;
}

interface FinalityContextData {
  finality: IFinality;
  setFinality(finality: IFinality): void;
}

export const FinalityContext = createContext<FinalityContextData>(
  {} as FinalityContextData
);

export const FinalityProvider: React.FC = ({ children }) => {
  const [finality, setFinality] = useState(() => {
    const finalityData = localStorage.getItem('@HarveyClient:finality');

    if (finalityData) {
      return JSON.parse(finalityData);
    }

    return {} as IFinality;
  });

  const handleSetFinality = useCallback((finalityData: IFinality) => {
    localStorage.setItem(
      '@HarveyClient:finality',
      JSON.stringify(finalityData)
    );
    setFinality(finalityData);
  }, []);

  return (
    <FinalityContext.Provider
      value={{ finality, setFinality: handleSetFinality }}
    >
      {children}
    </FinalityContext.Provider>
  );
};

export function useFinality(): FinalityContextData {
  const context = useContext(FinalityContext);

  if (!context) {
    throw new Error('useFinality must be used within an FinalityProvider');
  }

  return context;
}
