import styled from 'styled-components';

export const Container = styled.div`
  .container_user {
    @media (max-width: 991px) {
      margin-top: 3%;
      margin-left: 3rem;
    }
  }
`;
export const Step = styled.div`
  .container_user {
    @media (max-width: 991px) {
      margin-top: 3%;
      margin-left: 3rem;
    }
  }
`;
