import styled from 'styled-components';

interface IBall {
  color: string;
}

export const Container = styled.div`
  .line-bottom {
    ::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #b8b9ba;
      z-index: -1;
    }
  }

  .line-top {
    ::before {
      content: '';
      position: absolute;
      height: 50%;
      width: 1px;
      background-color: #b8b9ba;
      z-index: -1;
    }
  }

  .badge {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: #39a887;
    border: 3px solid #cde9e1;
  }

  .badge.disabled {
    background-color: #f4f4f4;
    border-color: transparent;
  }

  .box {
    width: 552px;
    height: 180px;
    border: 1px solid #079269;
    border-radius: 17px;
    @media (max-width: 992px) {
      width: 412px;
      height: 200px;
      border: 1px solid #079269;
      border-radius: 17px;
    }
  }

  .box.disabled {
    border-color: #b8b9ba;
    opacity: 0.5;
  }

  .box.old {
    border-color: #f2f2f2;
  }

  .btn-light {
    background-color: #f4f4f4;
    color: #39a887;
    font-weight: 700;
    width: 120px;
    height: 38px;
    margin-top: -30px;
  }

  .btn-light.old {
    color: #b8b9ba;
  }

  .input {
    border: 0;
    border-radius: 0;
    border-bottom: 0.5px solid #c6c6c9;
  }

  button.btn-primary {
    border-radius: 15px;
    font-size: large;
    font-weight: bold;
  }

  .box-finishing {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid black;
    justify-items: self-start;
    justify-self: flex-start;
  }

  .box-phases {
    padding: 0px 40% 0px 5%;
    @media (max-width: 991px) {
      padding: 0px 20% 0px 3%;
    }
    @media (max-width: 425px) {
      padding: 0px 6% 0px 0px;
    }
  }

  .box-details {
    padding: 0px 20% 0px 9%;
    @media (max-width: 991px) {
      padding: 0px 20% 0px 9%;
    }
    @media (max-width: 767px) {
      padding: 0px 18% 0px 14%;
    }
    @media (max-width: 425px) {
      padding: 0px 3% 0px 13%;
    }
  }

  .container_user {
    display: flex;
    flex-direction: column;
    @media (max-width: 1399px) {
      margin-left: 6%;
      margin-bottom: 5%;
    }
    @media (max-width: 991px) {
      margin-left: 9%;
    }
    @media (max-width: 767px) {
      margin-left: 12%;
    }
    @media (max-width: 493px) {
      margin-left: 13%;
    }
    @media (max-width: 425px) {
      margin-left: 16%;
    }
    @media (max-width: 375px) {
      margin-left: 18%;
    }
    @media (max-width: 320px) {
      margin-left: 20%;
    }
  }

  .container_user_avatar {
    width: 75%;
    @media (max-width: 1199px) {
      width: 90%;
    }
  }

  .container_budget {
    margin-left: 50px;
    @media (max-width: 1399px) {
      margin-left: 0px;
    }
  }

  @media screen and (min-width: 992px) {
    .line {
      ::before {
        content: '';
        position: absolute;
        height: 82%;
        width: 1px;
        background-color: #b8b9ba;
        left: 2.6%;
        z-index: -1;
      }
    }
  }
`;

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .logo {
    width: 12.5%;
  }

  .legend {
    max-width: 250px;

    p {
      width: calc(100% - 20px);
    }
  }
`;

export const Ball = styled.div<IBall>`
  background-color: ${(props) => props.color};
  width: 11px;
  height: 11px;
  border-radius: 50%;
`;
