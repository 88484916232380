import styled from 'styled-components';

export const Container = styled.div`
  .input-simulator {
    padding-left: 6%;
    padding-right: 6%;
    @media (max-width: 768px) {
      padding-left: 0%;
      padding-right: 0%;
    }
  }
  .font-renda {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #666666;
  }
  .fondo-gris {
    background: #fcfcfc;
  }
  .input {
    width: 363px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    input {
      color: #414142;
      background: #fcfcfc;
      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
`;
