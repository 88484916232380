import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

interface IOs {
  active?: boolean;
}

interface IBoxButtons {
  show?: boolean;
}

export const Container = styled.div`
  h1 {
    color: #414141;
    font-weight: 400 !important;
    text-align: center;

    b {
      font-weight: 700 !important;
    }
  }
`;

export const Profile = styled.div`
  p {
    color: #333333;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
  width: 51px;
  height: 51px;
  border-radius: 50%;
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
  }
`;

export const RequestsInfo = styled.div`
  > div {
    width: 50%;

    > div {
      border: 1px solid #dedede;
      border-radius: 20px;
      width: 100%;
      height: 100%;

      p {
        color: #414142;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: -0.019em;
      }
    }

    :last-child {
      width: 50%;

      > div {
        width: 100%;
        background: #39a887;
        border-radius: 20px;
        border: none;

        button {
          color: #ffffff;
          background: #079269;
          border-radius: 30px;
          font-size: 14px;
        }

        img {
          position: absolute;
          right: 0.5rem;
          bottom: -3px;
          width: 55%;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    > div {
      :last-child {
        > div {
          img {
            right: 1rem;
            bottom: -3px;
            width: 60%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    > div {
      :last-child {
        > div {
          img {
            right: 1rem;
            bottom: -3px;
            width: 50%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    > div {
      :last-child {
        > div {
          img {
            right: 1.5rem;
            bottom: -4px;
            width: 35%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    > div {
      width: 20%;

      :last-child {
        width: 40%;

        > div {
          button {
            font-size: 16px;
          }

          img {
            right: 1.5rem;
            bottom: -4px;
            width: auto;
          }
        }
      }
    }
  }
`;

export const Oss = styled.div`
  border-radius: 19px;
  overflow: hidden;
  border: 1px solid #dedede;
  position: relative;

  .linkConstructor {
    justify-content: center;
    align-items: center;
    background: #39a887;
    color: #ffffff;
    width: auto;
    border-radius: 41px;
  }
  .linkDashboard {
    justify-content: center;
    align-items: center;
    background: #39a887;
    color: #ffffff;
    width: auto;
    border-radius: 41px;
  }

  .loading-box {
    height: 100%;
    position: absolute;
  }
`;

export const Os = styled.div<IOs>`
  width: 100%;
  transition-duration: 0.3s;

  p {
    line-height: 32px;
    letter-spacing: 0em;
  }

  p.fw-semibold {
    color: #414142;
  }

  button.btn-dots {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  a.btn-add,
  button.btn-add {
    background: #39a887;
    border-radius: 41px;
    color: #ffffff;
  }

  button.btn-yes,
  button.btn-no {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0em;
    color: #414142;

    span {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 11px;
    }
  }

  button.btn-yes {
    span {
      background: #c7fff2;
    }
  }

  button.btn-no {
    span {
      background: #ffe0e7;
    }
  }

  :hover {
    background: #f9f9f9;

    button.btn-dots {
      opacity: 1;
    }
  }

  + div {
    border-top: 1px solid #e2e2e2;
  }
`;

export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      color: #414141;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      z-index: 2;

      .sr-only {
        display: none;
      }
    }

    .btn-submit {
      color: #ffffff;
      background: #39a887;
      border-radius: 20px;
      transition-duration: 0.3s;

      :hover {
        background: ${darken(0.1, '#39a887')};
      }
    }
  }
`;
