import React from 'react';

import { format } from 'date-fns';

import ptBr from 'date-fns/locale/pt-BR';

import { Container } from './styles';

interface ISimulation {
  amortizacao: {
    id: number;
    amortizacao: string;
  };
  vlrFinanciado: number;
  vlrParcInic: number;
  vlrParcFinal: number;
  prazo: number;
}

interface IParams {
  simulation: ISimulation;
}

const AmortizationSimulation: React.FC<IParams> = ({ simulation }) => {
  const datetoday = format(new Date(), "dd 'de' MMMM", {
    locale: ptBr,
  });
  const dateyear = format(new Date(), 'yyyy');
  const convert_to_number_year = parseInt(dateyear, 10);
  return (
    <Container className="container col-md-5 col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="m-4 mt-0">
        <h2 className="h6 mb-3">
          {simulation?.amortizacao?.amortizacao || ''}
        </h2>
        <p className="mb-4">Valor total financiado</p>
        <h3 className="h1">
          R${' '}
          {new Intl.NumberFormat('pt-BR', {
            maximumSignificantDigits: 10,
          }).format(simulation?.vlrFinanciado || 0)}
        </h3>
      </div>
      <div className="m-4 pt-5">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="me-3 dot" />
            <div className="w-75">
              <h4 className="mb-0">Primeira Parcela</h4>
              <p className="mb-0">
                {datetoday} {convert_to_number_year}
              </p>
            </div>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrParcInic)}
          </p>
        </div>
        <hr className="dot-link" />
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="me-3 dot" />
            <div className="w-75">
              <h4 className="mb-0">Última Parcela</h4>
              <p className="mb-0">
                {datetoday} {simulation.prazo + convert_to_number_year}
              </p>
            </div>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrParcFinal)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default AmortizationSimulation;
