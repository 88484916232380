import React from 'react';

import { ClientProvider } from './Client';
import { DefaultDataProvider } from './DefaultData';
import { FinalityProvider } from './Finality';
import { OrderServiceProvider } from './OrderService';
import { OrderServiceBuilderProvider } from './OrderServiceBuilder';
import { ParticipantProvider } from './Participants';
import { PermissionProvider } from './Permission';
import { ReleasesOssProvider } from './ReleaseOss';
import { ServiceOrderProvider } from './ServiceOrder';
import { SimulationProvider } from './Simulation';
import { SimulationsProvider } from './Simulations';
import { SpouseProvider } from './Spouse';
import { AuthProvider } from './contexts/Auth';

const AppProvider: React.FC = ({ children }) => (
  <ReleasesOssProvider>
    <FinalityProvider>
      <OrderServiceBuilderProvider>
        <OrderServiceProvider>
          <ClientProvider>
            <SpouseProvider>
              <ParticipantProvider>
                <SimulationProvider>
                  <ServiceOrderProvider>
                    <DefaultDataProvider>
                      <SimulationsProvider>
                        <AuthProvider>
                          <PermissionProvider>{children}</PermissionProvider>
                        </AuthProvider>
                      </SimulationsProvider>
                    </DefaultDataProvider>
                  </ServiceOrderProvider>
                </SimulationProvider>
              </ParticipantProvider>
            </SpouseProvider>
          </ClientProvider>
        </OrderServiceProvider>
      </OrderServiceBuilderProvider>
    </FinalityProvider>
  </ReleasesOssProvider>
);

export default AppProvider;
