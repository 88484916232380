import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 8.5%;
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  hr {
    background-color: #c7c7c7;
  }
  .dot {
    width: 7px;
    height: 7px;
    border-radius: 25px;
    background-color: #ff9900;
  }
  .sub-text {
    font-size: 12px;
  }
  .dot-2 {
    background-color: #48dcfc !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 988px) {
    //display: unset;
    //height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }
`;
