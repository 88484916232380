import React, { useCallback } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';

import { useHistory, useParams } from 'react-router-dom';
import { IParams } from '~/models/Simulation/Result';

const SimulationRedoButton: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();

  const handleClickRezafer = useCallback(() => {
    if (params.builderId) {
      history.push(
        `${process.env.PUBLIC_URL}/simulador/nova-os/${params.builderId}/${params.bdi}`
      );
    } else if (params.osId !== undefined) {
      history.push(`${process.env.PUBLIC_URL}/simulador/${params.osId}`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/simulador`);
    }
  }, [history, params.bdi, params.builderId, params.osId]);

  return (
    <button
      type="button"
      className="d-block btn btn-simulate-again ms-auto px-4 box-rezise"
      onClick={handleClickRezafer}
    >
      <span className="fw-semibold text-gray">Refazer a Simulação</span>{' '}
      <BsArrowCounterclockwise size={20} color="#707070" />
    </button>
  );
};

export default SimulationRedoButton;
