import { differenceInYears } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Models
import { IMParticipant } from '~/models/Participant';
import { IMServiceOrder } from '~/models/ServiceOrder';

// Services
import api from '~/services/api';

interface ServiceOrderContextData {
  serviceOrder: IMServiceOrder;
  participants: IMParticipant[];
  setServiceOrder(serviceOrder: IMServiceOrder): void;
  clearServiceOrder(): void;
  getServiceOrderData(serviceOrderId: any): Promise<void>;
  recoveryServiceOrderData(serviceOrderId: any): Promise<void>;
}

export const ServiceOrderContext = createContext<ServiceOrderContextData>(
  {} as ServiceOrderContextData
);

export const ServiceOrderProvider: React.FC = ({ children }) => {
  const [serviceOrder, setServiceOrder] = useState<IMServiceOrder>(
    {} as IMServiceOrder
  );
  const [participants, setParticipants] = useState<IMParticipant[]>([]);

  const handleSetServiceOrder = useCallback(
    (ServiceOrderData: IMServiceOrder) => {
      setServiceOrder((state) => ({ ...state, ...ServiceOrderData }));
    },
    [setServiceOrder]
  );

  const handleClearServiceOrder = useCallback(() => {
    setServiceOrder({} as IMServiceOrder);
    setParticipants([]);
  }, [setServiceOrder]);

  useEffect(() => {
    if (serviceOrder['cliente']) {
      const age = differenceInYears(
        new Date(),
        new Date(serviceOrder.cliente.dt_nascimento) || ''
      );

      const payloadOS: IMServiceOrder = {
        ageOlder: age,
        requests: [],
      };

      serviceOrder['cliente']
        ? (payloadOS['birthdateOlder'] =
            serviceOrder['cliente']['dt_nascimento'])
        : null;

      setServiceOrder((state) => ({ ...state, ...payloadOS }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceOrderData = useCallback(
    async (serviceOrderId: any) => {
      if (serviceOrderId && serviceOrderId !== serviceOrder.id) {
        handleClearServiceOrder();

        const response = await api.get<IMServiceOrder>(
          `service-orders/${serviceOrder.key}`
        );
        setServiceOrder(response.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceOrder.id, handleClearServiceOrder]
  );

  /**
   * Recover the service order from external database
   * @param serviceOrderId - The service order identification
   */
  const recoveryServiceOrderData = useCallback(
    async (serviceOrderId: any) => {
      if ((typeof serviceOrder).match('string|number')) {
        throw new Error('Não é possivel recuperar a OS');
      }

      const response = await api.get<IMServiceOrder>(
        `service-orders/${serviceOrderId}`
      );

      setServiceOrder(response.data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceOrder.id, handleClearServiceOrder]
  );

  useEffect(() => {
    const participantsData = [] as IMParticipant[];
    participantsData.push({
      type: 'client',
      id: serviceOrder?.cliente?.id,
      nome: serviceOrder?.cliente?.nome,
      cpf: serviceOrder?.cliente?.documento,
      email: serviceOrder?.cliente?.email,
      celular: serviceOrder?.cliente?.celular,
      dt_nascimento: serviceOrder?.cliente?.dt_nascimento,
      profissao: serviceOrder?.cliente?.profissao,
      vlrRenda: serviceOrder?.vlrRenda || serviceOrder?.simulacao?.vlrRenda,
      vlrFgts: serviceOrder?.vlrFgts || serviceOrder?.simulacao?.vlrFgts,
      nvEscolar_id: serviceOrder?.cliente?.nvEscolar_id,
      numPis: serviceOrder?.cliente?.numPis,
      casado: serviceOrder?.cliente?.casado,
    });

    if (
      serviceOrder?.cliente?.casado ||
      (serviceOrder.conjuge && Object.keys(serviceOrder.conjuge).length > 0)
    ) {
      participantsData.push({
        type: 'spouse',
        id: serviceOrder?.conjuge?.id,
        nome: serviceOrder?.conjuge?.nome,
        cpf: serviceOrder?.conjuge?.cpf,
        email: serviceOrder?.conjuge?.email,
        celular: serviceOrder?.conjuge?.celular,
        dt_nascimento: serviceOrder?.conjuge?.dt_nascimento,
        profissao: serviceOrder?.conjuge?.profissao,
        vlrRenda: serviceOrder?.conjuge?.vlrRenda,
        vlrFgts: serviceOrder?.conjuge?.vlrFgts,
        nvEscolar_id: serviceOrder?.conjuge?.nvEscolar_id,
        numPis: serviceOrder?.conjuge?.numPis,
      });
    }

    if (serviceOrder.participantes) {
      serviceOrder.participantes.forEach((participant) => {
        participantsData.push({
          type: 'participant',
          id: participant.id,
          nome: participant.nome,
          cpf: participant.cpf,
          email: participant.email,
          celular: participant.celular,
          dt_nascimento: participant.dt_nascimento,
          profissao: participant.profissao,
          vlrRenda: participant.vlrRenda,
          vlrFgts: participant.vlrFgts,
          nvEscolar_id: participant.nvEscolar_id,
          numPis: participant.numPis,
        });
      });
    }
    setParticipants(participantsData);
  }, [serviceOrder]);

  const serviceOrderParams = useMemo(() => {
    return {
      serviceOrder,
      participants,
      setServiceOrder: handleSetServiceOrder,
      clearServiceOrder: handleClearServiceOrder,
      getServiceOrderData,
      recoveryServiceOrderData,
    };
  }, [
    serviceOrder,
    participants,
    handleSetServiceOrder,
    handleClearServiceOrder,
    getServiceOrderData,
    recoveryServiceOrderData,
  ]);

  return (
    <ServiceOrderContext.Provider value={serviceOrderParams}>
      {children}
    </ServiceOrderContext.Provider>
  );
};

export function useServiceOrder(): ServiceOrderContextData {
  const context = useContext(ServiceOrderContext);

  if (!context) {
    throw new Error(
      'useServiceOrder must be used within an ServiceOrderProvider'
    );
  }

  return context;
}
