import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import api from '~/services/api';

import InputRadio from '~/components/InputRadio';
import { Modal } from './styles';

export interface IDataForm {
  school_level_id: string | number | undefined;
  numPis: string | number | readonly string[] | undefined;
  os_id: any;
  schooling_level_id?: number;
  id?: number;
  name: string;
  salary?: string;
  document: string;
  cpf: string;
  profession?: string;
  fgts?: boolean;
  fgts_amount?: string;
  pis_number?: string;
  vlrRenda?: string;
  income_value: string;
}

interface IModalProps {
  show: boolean;
  data: IDataForm;
  onSubmit: (data: IDataForm) => void;
  onHide: () => void;
  osId?: number;
}
interface ISchoolingLevel {
  id: number;
  schooling_level: string;
}
interface ISchoolingLevels {
  schooling_level_type: ISchoolingLevel[];
}

const ModalForm: React.FC<IModalProps> = ({
  show,
  data,
  onSubmit,
  onHide,
  osId,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [schooling_level, setSchoolingLevel] = useState<IOption[]>([
    {
      id: 1,
      value: 'Test',
      selected: false,
    },
  ]);

  const [schooling_level_type] = useState({} as ISchoolingLevel);
  const [hasFGTS, setHasFGTS] = useState(false);
  const [cpfError, setCPFError] = useState('');
  const [nameLengthError, setNameLengthError] = useState('');
  const salary = () => {
    let salaryParticipant = '';
    if (data.salary !== '0') {
      salaryParticipant = parseFloat(data.salary || '0').toFixed(2);
    } else if (data.income_value) {
      salaryParticipant = parseFloat(data.income_value).toFixed(2);
    }
    return salaryParticipant;
  };
  useEffect(() => {
    api.get<ISchoolingLevels>(`schooling-levels`).then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataSchool: IOption[] = (response.data as unknown as any[]).map(
        (schoolingData) => ({
          id: schoolingData.id,
          value: schoolingData.schooling_level,
          selected: schooling_level_type.id === schoolingData.id,
        })
      );

      setSchoolingLevel(
        dataSchool.map((school) => {
          if (
            school.id === data.schooling_level_id ||
            school.id === data.school_level_id
          ) {
            return { ...school, selected: true };
          }
          return { ...school, selected: false };
        })
      );
    });
  }, [show]);
  useEffect(() => {
    setHasFGTS(data.fgts_amount !== '0');
  }, [show]);

  const handleChangeHasFGTS = useCallback(
    (option) => {
      setHasFGTS(option.value === 'Sim');
    },
    [hasFGTS]
  );

  const handleChangeSchoolLevel = useCallback(
    (option) => {
      const newSchoolingLevel = schooling_level.map((schoolingLevel) => {
        if (schoolingLevel.id === option.id) {
          return { ...schoolingLevel, selected: true };
        }

        return { ...schoolingLevel, selected: false };
      });

      setSchoolingLevel(newSchoolingLevel);
    },
    [schooling_level]
  );
  const handleValidateCPF = useCallback((value: string) => {
    if (!value) return false;
    return cpfValidator.isValid(value.replace(/\D/g, ''));
  }, []);

  const handleSubmit = useCallback(
    async (dataForm: IDataForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          salary: Yup.string().required('O valor da renda é obrigatório'),
          document: Yup.string().required('O documento é obrigatório'),
          profession: Yup.string().required('A profissão é obrigatório'),
          schooling_level_id: Yup.string().required(
            'A nível de escolaridade é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        // eslint-disable-next-line no-param-reassign
        dataForm['os_id'] = osId;

        console.log(dataForm);

        const nameParts = dataForm.name.trim().split(' ');

        if (nameParts.length <= 1 || !handleValidateCPF(dataForm.document)) {
          nameParts.length <= 1
            ? setNameLengthError('Digite seu nome completo')
            : setNameLengthError('');

          handleValidateCPF(dataForm.document) === false
            ? setCPFError('CPF incorreto')
            : setCPFError('');
        } else {
          setNameLengthError('');
          setCPFError('');
          onSubmit(dataForm);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [handleValidateCPF, onSubmit]
  );

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={data}
        className="p-4"
      >
        <Modal.Header className="border-none ps-4 pt-4" closeButton>
          <h3>{data.id ? 'Editar dados' : 'Cadastrar informações'} 2</h3>
        </Modal.Header>
        <Modal.Body className="mb-4 mx-2">
          <div className="row mt-1">
            <h4>Dados:</h4>
            <div className="col-lg-12 mb-3 mt-4">
              <label>
                Nome Completo:
                <b className="text-primary">*</b>
                <Input
                  className="form-input"
                  name="name"
                  placeholder="Nome Completo"
                />
                {nameLengthError && (
                  <span className="small text-danger error">
                    {nameLengthError}
                  </span>
                )}
              </label>
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                Renda: <b className="text-primary">*</b>
                <InputMask
                  kind="money"
                  className="form-input"
                  name="salary"
                  placeholder="R$ 0,00"
                  value={salary()}
                />
              </label>
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                CPF: <b className="text-primary">*</b>
                <InputMask
                  kind="cpf"
                  className="form-input"
                  name="document"
                  placeholder="000.000.000-00"
                  value={data.document ? data.document : data.cpf}
                />
                {cpfError && (
                  <span className="small text-danger error">{cpfError}</span>
                )}
              </label>
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>Profissão</label>
              <Input
                className="form-input"
                name="profession"
                placeholder="Profissão"
              />
            </div>
            <div className="col-lg-3 mb-3 mt-4">
              <label>Possui FGTS ?</label>
              <InputRadio
                name="fgts"
                options={[
                  { id: 1, value: 'Sim' },
                  { id: 2, value: 'Não' },
                ]}
                selected={
                  hasFGTS ? { id: 1, value: 'Sim' } : { id: 2, value: 'Não' }
                }
                onChange={handleChangeHasFGTS}
                className="mt-4"
              />
            </div>
            {hasFGTS && (
              <div className="col-lg-6 mb-3 mt-4">
                <label>Valor FGTS</label>
                <InputMask
                  kind="money"
                  className="form-input"
                  name="fgts_amount"
                  placeholder="R$ 0,00"
                  value={
                    data.fgts_amount
                      ? parseFloat(data.fgts_amount).toFixed(2)
                      : ''
                  }
                />
              </div>
            )}
            <div className="col-lg-6 mb-3 mt-4">
              <label>PIS:</label>
              <Input
                className="form-input"
                name="pis_number"
                placeholder="PIS"
                defaultValue={data.numPis}
              />
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>Nível de escolaridade: *</label>
              <Select
                type="name"
                placeholder="Selecione"
                name="schooling_level_id"
                className="input"
                options={schooling_level}
                onChange={handleChangeSchoolLevel}
              />
            </div>

            <Input name="os_id" type="hidden" defaultValue={osId} />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons-group">
            <div>
              <button type="submit" className="btn-submit py-3">
                Completar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalForm;
