import axios, { AxiosError, AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_PREFIX_ROUTE}`,
});

api.interceptors.response.use(
  (request) => {
    try {
      return request;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (reject: AxiosResponse & AxiosError) => {
    try {
      if (reject.response?.status === 401) {
        api.defaults.headers.Authorization = '';
        localStorage.removeItem('@HarveyClients:token');
        localStorage.removeItem('@HarveyClients:auth');

        if (window?.location) window.location.assign('/login');
      }

      return Promise.reject(reject);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default api;
