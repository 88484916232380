import styled from 'styled-components';

export const ContainerOut = styled.div`
  padding: 35px;
  padding-top: 0;

  @media (max-width: 1500px) {
    padding: 30px;
    padding-top: 0;
  }
  @media (max-width: 1200px) {
    padding: 26px;
    padding-top: 0;
  }
  @media (max-width: 990px) {
    padding: 0px;
    padding-top: 0;
  }
`;

export const Container = styled.div`
  .profile-icon {
    background-color: #03c2ff;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    p {
      -webkit-text-stroke-width: 0.2px;
      -webkit-text-stroke-color: #000000;
      -webkit-text-fill-color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .col-lg-12 {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
`;
