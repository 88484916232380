import styled from 'styled-components';

interface IRange {
  percent: number;
}

export const Container = styled.div``;

export const Range = styled.label<IRange>`
  input[type='range'] {
    -webkit-appearance: none;

    :focus {
      outline: none;
    }

    ::-webkit-slider-runnable-track {
      height: 4px;
      background: rgb(57, 168, 135);
      background: linear-gradient(
        90deg,
        rgba(57, 168, 135, 1) 0%,
        rgba(57, 168, 135, 1) ${(props) => props.percent}%,
        rgba(230, 231, 232, 1) ${(props) => props.percent}%,
        rgba(230, 231, 232, 1) 100%
      );
      border-radius: 4px;
    }

    ::-webkit-slider-thumb {
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
      border: 3px solid #ffffff;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #39a887;
      -webkit-appearance: none;
      margin-top: -6px;
    }
  }
`;
