import styled from 'styled-components';

interface IRadioInput {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const RadioInput = styled.label<IRadioInput>`
  display: flex;
  align-items: center;
  margin: 5px 20px 5px 0;
  cursor: pointer;

  span {
    color: #707070;
    font-weight: 500;
  }

  > div {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) =>
      props.selected ? '#FFFFFF' : 'rgba(57, 168, 135, 0.24)'};
    border: ${(props) =>
      props.selected
        ? '5px solid #39A887'
        : '1px solid rgba(57, 168, 135, 0.24)'};
  }

  input {
    display: none;
  }
`;
