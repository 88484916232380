import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  height: 170px;
  margin: 0;
  .section_info_imovel {
    h5 {
      color: #414142;
    }
  }
  .section_info_imovel_secundary {
    font-size: 16px;
    h5 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  div.container_icon_house {
    width: 40px;
    height: 40px;
    background: #cde9e1;
    border-radius: 50%;
    position: relative;
  }
  div.container_icon_house img {
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 0;
    right: -5px;
    margin: auto;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    background-color: #96d9ff !important;
  }
  .dot-3 {
    background-color: #ffba69 !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
`;
