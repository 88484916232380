import React, { useCallback } from 'react';

import { Container } from './styles';

interface IParams {
  onClick: () => void;
}

const NextButton: React.FC<IParams> = ({ onClick }) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Container>
      <button
        type="button"
        className="button-prox btn btn-nextt fw-semibold"
        onClick={() => handleClick()}
      >
        Próximo
      </button>
    </Container>
  );
};

export default NextButton;
