import React, { useCallback, useEffect, useState } from 'react';
import { BsStarFill } from 'react-icons/bs';
import { MdWork } from 'react-icons/md';

import { FaMapMarkerAlt } from 'react-icons/fa';

import { CgClose } from 'react-icons/cg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '~/services/api';

import Loading from '~/components/Loading';

import {
  Avatar,
  Banner,
  ButtonSubmit,
  Container,
  Gallery,
  Img,
  ImgGallery,
  Profile,
} from './styles';

import bannerPhoto from '~/assets/banners/banner.png';
import avatarPhoto from '~/assets/defaults/avatar.png';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { Button } from '../styles';

interface IRequest {
  builder_id: number;
  answered: boolean;
}

interface IOs {
  requests: IRequest[];
}

interface ICity {
  municipio: string;
  estado: {
    sigla: string;
  };
}

interface IPhotoResponse {
  id: number;
  photo_url: string;
}

interface IScore {
  id: number;
  content: number;
}

interface IBuilderResponse {
  id: number;
  name: string;
  description: string;
  jobs: number;
  score: number;
  construtech?: {
    nome_fantasia: string;
    logo_url?: string;
    description: string;
    jobs: number;
    score: number;
  };
  engineer: {
    id: number;
    user_id: number;
    wallpaper_id: null;
    nome: string;
    description: null;
    cpf: string;
    profession: null;
    telefone: string;
    celular: string;
    foto: null;
    deleted_at: null;
    user_create_id: number;
    user_update_id: number;
    created_at: Date;
    updated_at: Date;
    foto_url: string;
    social_works: null;
    gallery: any[];
    wallpaper: any;
    municipios: [
      {
        id: number;
        engenheiro_id: number;
        estado_id: number;
        municipio: string;
        municipio_id: number;
        deleted_at: null;
        user_create_id: number;
        user_update_id: number;
        created_at: Date;
        updated_at: Date;
        estado: {
          id: number;
          nome: string;
          sigla: string;
          codigo: string;
          region: string;
          valor: number;
          created_at: Date;
          updated_at: Date;
          deleted_at: Date;
        };
      }
    ];
    photos: any[];
    scores: any[];
  };
  engenheiro?: {
    nome: string;
    description: string;
    telefone: string;
    foto_url?: string;
    wallpaper?: {
      wallpaper_url: string;
    };
    municipios: ICity[];
    photos: IPhotoResponse[];
    scores: IScore[];
  };
}

interface IBuilder {
  id: number;
  avatar: string;
  name: string;
  description: string;
  location: string;
  jobs: number;
  score: string;
  wallpaper: string;
  alreadySent?: boolean;
}

interface IPhoto {
  id: number;
  url: string;
}

interface IParams {
  os_id: string;
  builder_id: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Builder: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const [showGallery, setShowGallery] = useState(false);
  const [builder, setBuilder] = useState({} as IBuilder);
  const [photos, setPhotos] = useState<IPhoto[]>([]);
  const [photoBefore, setPhotoBefore] = useState({} as IPhoto);
  const [photoSelected, setPhotoSelected] = useState({} as IPhoto);
  const [photoAfter, setPhotoAfter] = useState({} as IPhoto);
  const [totalRequests, setTotalRequests] = useState(0);
  const [loading, setLoading] = useState(true);
  const [textButton, setTextButton] = useState('');
  const [buttonEnable, setButtonEnable] = useState(false);
  const [buttonColor, setButtonColor] = useState('');
  const [visibleSpinner, setVisibleSpinner] = useState(false);
  const { serviceOrder, recoveryServiceOrderData } = useServiceOrder();

  useEffect(() => {
    if (params.os_id && !serviceOrder.key) {
      recoveryServiceOrderData(params.os_id).catch((_rejects) => {
        history.push('/solicitacoes');
      });
    } else if (!params.os_id || !serviceOrder?.key) {
      history.push('/solicitacoes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api
      .get<IBuilderResponse>(`construtechs/${params.builder_id}`)
      .then(async (response) => {
        let { nome } = response.data.engineer;
        let description = '-';
        let location = '-';
        let avatar = avatarPhoto;
        let wallpaper = bannerPhoto;
        const jobs = 0;
        let score = '0,0';
        let data: IPhoto[] = [];
        let alreadySent = false;
        if (response.data.construtech) {
          nome = response.data.construtech.nome_fantasia;
          description = response.data.construtech.description;
          if (response.data.construtech.logo_url) {
            avatar = response.data.construtech.logo_url;
          }
          if (response.data.engineer) {
            data = response.data.engineer.photos.map((photo) => ({
              id: photo.id,
              url: photo.photo_url,
            }));

            if (response.data.engineer.scores.length > 0) {
              const totalScore = response.data.engineer.scores.reduce(
                (previousValue, currentValue) => {
                  return previousValue + currentValue.content;
                },
                0
              );
              score = (totalScore / response.data.engineer.scores.length)
                .toFixed(1)
                .replace('.', ',');
            }

            if (response.data.engineer.wallpaper) {
              wallpaper = response.data.engineer.wallpaper.wallpaper_url;
            }
            if (response.data.engineer.municipios[0]) {
              location = `${response.data.engineer.municipios[0].municipio} - ${response.data.engineer.municipios[0].estado.sigla}`;
            }
          }
        } else if (response.data.engineer) {
          nome = response.data.engineer.nome;
          description = response.data.engineer.description || '';

          const totalScoreRaw = response.data.engineer.scores.reduce(
            (previousValue, currentValue) => {
              return previousValue + currentValue.content;
            },
            0
          );

          const totalScore = totalScoreRaw > 0 ? totalScoreRaw : 1;

          const scoreRaw =
            response.data.engineer.scores.length > 0
              ? response.data.engineer.scores.length
              : 1;

          score = (totalScore / scoreRaw).toFixed(1).replace('.', ',');

          if (response.data.engineer.foto_url) {
            avatar = response.data.engineer.foto_url;
          }

          if (response.data.engineer.municipios[0]) {
            location = `${response.data.engineer.municipios[0].municipio} - ${response.data.engineer.municipios[0].estado.sigla}`;
          }

          if (response.data.engineer.wallpaper) {
            wallpaper = response.data.engineer.wallpaper.wallpaper_url;
          }

          data = response.data.engineer.photos.map((photo) => ({
            id: photo.id,
            url: photo.photo_url,
          }));
        }

        const responseRequest = await api.get<IOs>(
          `service-orders/${params.os_id}`
        );

        const request = responseRequest.data.requests.find(
          (requestData) =>
            requestData.builder_id === response.data.id && !requestData.answered
        );

        if (request) {
          alreadySent = true;
        }

        setBuilder({
          id: response.data.id,
          avatar,
          name: nome,
          description,
          location,
          jobs,
          score,
          wallpaper,
          alreadySent,
        });
        setPhotos(data);
        setTotalRequests(
          responseRequest.data.requests.filter(
            (requestData) => !requestData.answered
          ).length
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.builder_id, params.os_id]);

  const handleTextStatus = useCallback(() => {
    builder.alreadySent === true
      ? setTextButton('Já enviado')
      : setTextButton('Solicitar orçamento');

    builder.alreadySent === true
      ? setButtonEnable(true)
      : setButtonEnable(false);

    builder.alreadySent === true
      ? setButtonColor('btn-danger')
      : setButtonColor('bg-primary');

    builder.alreadySent === true
      ? setVisibleSpinner(false)
      : setVisibleSpinner(false);
  }, [builder.alreadySent]);

  useEffect(() => {
    handleTextStatus();
  }, [handleTextStatus]);

  const handleClick = useCallback(
    (photoIndex: number) => {
      if (photoIndex === 0) {
        setPhotoBefore(photos[photos.length - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      } else if (photos.length - 1 === photoIndex) {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[0]);
      } else {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      }
      setShowGallery(true);
    },
    [photos]
  );

  const handleClickBefore = useCallback(() => {
    const photoIndex = photos.findIndex((photo) => photo.id === photoBefore.id);
    if (photoIndex >= 0) {
      if (photoIndex === 0) {
        setPhotoBefore(photos[photos.length - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      } else if (photos.length - 1 === photoIndex) {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[0]);
      } else {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      }
    }
  }, [photoBefore.id, photos]);

  const handleClickAfter = useCallback(() => {
    const photoIndex = photos.findIndex((photo) => photo.id === photoAfter.id);
    if (photoIndex >= 0) {
      if (photoIndex === 0) {
        setPhotoBefore(photos[photos.length - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      } else if (photos.length - 1 === photoIndex) {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[0]);
      } else {
        setPhotoBefore(photos[photoIndex - 1]);
        setPhotoSelected(photos[photoIndex]);
        setPhotoAfter(photos[photoIndex + 1]);
      }
    }
  }, [photoAfter.id, photos]);

  const handleClose = useCallback(() => {
    setShowGallery(false);
  }, []);

  const handleClickRequestQuote = useCallback(async () => {
    try {
      if (!serviceOrder?.id) {
        history.push('/solicitacoes');
      }

      if (totalRequests < 3) {
        const formData = {
          builder_id: builder.id,
          os_id: serviceOrder.id,
          status_id: 1,
          answered: 0,
        };
        setVisibleSpinner(true);
        await api.post(`requests`, formData);
        setTextButton('Já enviado');
        setButtonEnable(true);
        setButtonColor('btn-danger');
        setTotalRequests(totalRequests + 1);
        setBuilder({
          ...builder,
          alreadySent: false,
        });
        setVisibleSpinner(false);
        Toast.fire({
          icon: 'success',
          title: 'Orçamento enviado com sucesso',
        });
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'Maximo de construtores atingidos',
        });
      }
    } catch (error) {
      Toast.fire({
        icon: 'warning',
        title: `Erro na escolha de construtora`,
      });
    }
  }, [builder, history, serviceOrder.id, totalRequests]);

  return (
    <>
      {Object.keys(builder).length > 0 && (
        <>
          <Container>
            <Banner src={builder.wallpaper} />
            <div className="container py-5">
              <div className="row justify-content-between mt-n5 px-2 px-sm-0">
                <div className="col-12 mt-n5">
                  <div className="row">
                    <div className="col-lg-6 d-flex align-items-start">
                      <Link
                        to={`/${serviceOrder.id}/selecionar-construtor/${serviceOrder.key}`}
                        className="border-0 bg-white rounded-circle d-flex align-items-center justify-constent-center p-2 p-lg-4 mt-n5 ms-2 ms-lg-0"
                      >
                        <IoIosArrowBack size={24} color="#414142" />
                      </Link>
                    </div>
                    <div className="col-lg-6">
                      <div className="bg-green rounded-pill px-5 py-1 w-25 ms-auto d-none d-lg-block" />
                      <h2 className="h1 fw-light text-white text-end mt-5 d-none d-lg-block">
                        Minha Casa <b className="fw-semibold">Financiada</b>
                      </h2>
                    </div>
                  </div>
                </div>
                <Profile className="col-12 px-3 py-5 px-sm-5">
                  <div className="row">
                    <div className="col-lg-2 position-relative">
                      <Avatar src={builder.avatar} />
                    </div>
                    <div className="col-lg-10">
                      <div className="d-flex flex-column flex-lg-row align-items-center">
                        <h1 className="h2 fw-semibold text-dark-gray mb-0 order-1 order-lg-0">
                          {builder.name}
                        </h1>
                        <div className="d-flex align-items-center mx-auto ms-sm-0 me-sm-auto me-lg-0 ms-lg-auto order-0 order-lg-1 my-4 my-lg-0">
                          <div className="d-flex align-items-center me-4">
                            <FaMapMarkerAlt
                              size={19}
                              color="#39A887"
                              className="me-2"
                            />
                            <p className="mb-0">{builder.location}</p>
                          </div>
                          <div className="d-flex align-items-center me-4">
                            <MdWork
                              size={19}
                              color="#8A8A8A"
                              className="me-2"
                            />
                            <p className="mb-0">{builder.jobs}</p>
                          </div>
                          <div className="d-flex align-items-center me-4">
                            <BsStarFill
                              size={19}
                              color="#FFBA41"
                              className="me-2"
                            />
                            <p className="mb-0">{builder.score}</p>
                          </div>
                        </div>
                      </div>
                      <span className="d-block my-3 ">Construtor</span>
                      <p className="w-75 mb-4">{builder.description}</p>
                      <ButtonSubmit
                        type="button"
                        className={`btn ${buttonColor}`}
                        onClick={handleClickRequestQuote}
                        disabled={buttonEnable}
                      >
                        {`${textButton} `}
                        {visibleSpinner && (
                          <span
                            className="spinner-border text-danger spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </ButtonSubmit>
                    </div>
                  </div>
                </Profile>
                <div className="col-12 mt-5 mb-3">
                  <h2 className="h3 text-dark-gray fw-semibold">
                    Alguns trabalhos
                  </h2>
                </div>
                {photos.map((photo, index) => (
                  <div key={photo.id} className="col-6 col-lg-4">
                    <Img src={photo.url} onClick={() => handleClick(index)} />
                  </div>
                ))}

                {photos.length <= 0 && (
                  <>
                    <div>
                      <p className="mb-4 text-gray">
                        Nenhum trabalho disponivel
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Container>
          {Object.keys(photoSelected).length > 0 && (
            <Gallery
              show={showGallery}
              className="d-flex align-items-center justify-content-center"
            >
              <button type="button" onClick={handleClose} className="opacity" />
              <button
                type="button"
                onClick={handleClose}
                className="border-0 bg-transparent button-close"
              >
                <CgClose size={40} color="#fff" />
              </button>
              <button
                type="button"
                onClick={handleClickBefore}
                className="mx-4 border-0 bg-transparent"
              >
                <IoIosArrowBack size={37} color="#FFFFFF" />
              </button>
              <div className="photo">
                <ImgGallery src={photoSelected.url} />
              </div>
              <button
                type="button"
                onClick={handleClickAfter}
                className="mx-4 border-0 bg-transparent"
              >
                <IoIosArrowForward size={37} color="#FFFFFF" />
              </button>
            </Gallery>
          )}
        </>
      )}

      {!loading && Object.keys(builder).length <= 0 && (
        <>
          <div
            style={{
              width: '50%',
              margin: 'auto',
              marginTop: '20%',
              marginBottom: '20%',
            }}
          >
            <h2 className="mb-4 text-gray">
              Infelizmente não foi possivel localizar o construtor
            </h2>
            <Button
              type="button"
              title="Clique aqui para voltar a pagina de solicitações"
              className="btn px-lg-5 py-2 me-lg-2 mb-3 mb-lg-0 w-100 w-lg-auto buttonSelector"
              onClick={() => history.push('/solicitacoes')}
            >
              voltar
            </Button>
          </div>
        </>
      )}

      <Loading active={loading} />
    </>
  );
};

export default Builder;
