import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useHistory, useParams } from 'react-router-dom';

import logo from '~/assets/logos/logo-gray.svg';
import { Box, Container } from './styles';

import { useFinality } from '~/hooks/Finality';
import { useSimulation } from '~/hooks/Simulation';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';

interface IOsResponse {
  id: number;
  created_at: string;
  construtor?: {
    name: string;
  };
  finalidade: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    id: number;
    os_id: number;
    amortizacao_id: number;
    vlrRenda: number;
    prazo: number;
    taxa: number;
    vlrParcInic: number;
    vlrParcFinal: number;
    vlrFinanciado: number;
    vlrImovPronto: number;
    vlrEntrada: number;
    vlrEntradaReal: number;
    vlrFgts: number;
    vlrTerreno: number;
    vlrSubsidio: number;
    vlrAvaliado: number;
    vlrAprovado: number;
    vlrSolicitado: number;
    vlrImovel: number;
    tipo_imovel_id?: number;
    comentario_analise?: string;
    dt_analise?: string;
    resultAnalise_id?: number;
    amortizacao?: {
      id: number;
      ordem: number;
      amortizacao: string;
      banco_id: number;
      taxa: number;
      percentual: number;
      prazo: number;
      funcao: string;
      indexador?: number;
      taxa_nominal?: number;
      teto_maximo?: number;
      renda_min?: number;
      renda_max?: number;
      observacao?: string;
      enfase: number;
      banco: {
        id: number;
        banco: string;
      };
    };
  };
  cliente: {
    dt_nascimento: string;
  };
}

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
}

interface IResourceCompositionProps {
  simulation: ISimulation;
}

interface IParams {
  os_id: string;
}

const ResourceComposition: React.FC<IResourceCompositionProps> = ({
  simulation,
}) => {
  const params = useParams<IParams>();
  const history = useHistory();
  const [series, setSeries] = useState([0]);

  const handleSeries = useCallback((simulations: ISimulation) => {
    setSeries([
      simulations.vlrFinanciado,
      simulations.vlrEntrada,
      simulations.vlrFgts,
    ]);
  }, []);

  useEffect(() => {
    handleSeries(simulation);
  }, [handleSeries, simulation]);

  const convertedValue = useCallback((value: number) => {
    const newValue = formatCurrency(
      parseFloat(String(value) === null || undefined ? '0' : String(value))
    );

    return newValue;
  }, []);

  const options = useMemo(
    () => ({
      options: {
        labels: ['Valor financiado', 'Valor de entrada', 'Valor do FGTS'],
        colors: ['#8675FE', '#FEBA6A', '#FF718B'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            show: true,
            formatter(val: number) {
              return `${convertedValue(val)}`;
            },
            title: {
              formatter: (seriesName: string) => `${seriesName}: `,
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
      },
      series,
    }),
    [series, convertedValue]
  );
  const { setFinality } = useFinality();
  const { setSimulation } = useSimulation();
  const [os, setOs] = useState({} as IOsResponse);

  useEffect(() => {
    api.get(`service-orders/${params.os_id}`).then((response) => {
      setOs(response.data);
    });
  }, [params.os_id]);

  const handleClickEditar = useCallback(() => {
    setFinality({
      id: os.finalidade.id,
      title: os.finalidade.finalidade,
      description: os.finalidade.descricao,
      image: '',
    });

    const [year, month, day] = os.cliente.dt_nascimento.split('-');
    const birthdate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );

    setSimulation({
      os_id: os.id,
      birthdate,
      revenue: os.simulacao.vlrRenda,
      amortization: os.simulacao.amortizacao
        ? {
            id: os.simulacao.amortizacao.id,
            title: os.simulacao.amortizacao.amortizacao,
            financedValue: os.simulacao.vlrFinanciado.toFixed(2),
            entryValue: os.simulacao.vlrEntrada.toFixed(2),
            realEntryValue: os.simulacao.vlrEntradaReal.toFixed(2),
            fgtsAmount: os.simulacao.vlrFgts.toFixed(2),
            rate: os.simulacao.taxa.toFixed(2),
            firstInstallment: os.simulacao.vlrParcInic.toFixed(2),
            lastInstallment: os.simulacao.vlrParcFinal.toFixed(2),
            deadline: os.simulacao.prazo,
          }
        : undefined,
    });
    history.push(`${process.env.PUBLIC_URL}/simulador/resultado`);
  }, [history, os, setFinality, setSimulation]);

  return (
    <Container className="container col-md-5 col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h6 mb-0">Composição do recursos</h2>
        {/* <button
          type="button"
          className="d-flex align-items-center btn py-0"
          onClick={handleClickEditar}
        >
          <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
        </button> */}
      </div>

      <Box>
        <div className="position-relative d-flex align-items-center justify-content-center my-5">
          <Chart
            options={options.options}
            series={options.series}
            type="donut"
            className="p-0 m-0"
          />
          <img src={logo} alt="logo" className="logo position-absolute" />
        </div>
      </Box>
      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot me-3" />
          <p className="mb-0">Valor financiado</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrFinanciado)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-2 me-3" />
          <p className="mb-0">Valor de entrada</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrEntrada)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-3 me-3" />
          <p className="mb-0">Valor do FGTS</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrFgts)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ResourceComposition;
