/* eslint-disable prefer-object-spread */
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Services
import api from '~/services/api';

// Models
import { IMUser } from '~/models/User';

// Types
import {
  AuthCredentials,
  AuthState,
  IContextAuthDefaultData,
  IContextAuthProps,
} from './types';

// Middleware
import { authMiddlewareGetToken } from './middleware';
import { authGetCacheStorage } from './middleware/cache';

// Actions
import {
  authActionSignOut,
  authActionUpdateAuth,
  authActionUpdateAuthToken,
  authActionUpdateUser,
} from './actions';

// Reducer
import { reducerAuth } from './reducer';

// Context
import { AuthContext, AuthContextDispatch } from './context';

export function AuthProvider({ children }: IContextAuthProps): JSX.Element {
  const [state, dispatch] = useReducer(reducerAuth, authGetCacheStorage());
  const history = useHistory();

  const signIn = useCallback(
    async ({ email, password }: AuthCredentials): Promise<true | void> => {
      try {
        const response = await api.post<AuthState>(`auth/login`, {
          email,
          password,
        });

        if (response.status !== 200) throw new Error('Have some erro to auth');

        dispatch(authActionUpdateAuth(response.data));

        return true;
      } catch (error) {
        Swal.fire('Opss...', 'Dados de login inválidos!', 'error');
      }
    },
    []
  );

  const recovery = useCallback(async (): Promise<true | void> => {
    try {
      api.defaults.headers.authorization = `Bearer ${authMiddlewareGetToken()}`;

      const response = await api.get<IMUser>(`auth/me`);

      if (response.status !== 200) {
        return;
      }

      dispatch(authActionUpdateUser(response.data));

      return true;
    } catch (error) {
      // do anything
    }
  }, [state]);

  const autoSignIn = useCallback(
    async (hash: string) => {
      try {
        const response = await api.post<AuthState>(`auth/auto-login`, {
          hash,
        });
        const { token } = response.data;

        dispatch(authActionUpdateAuthToken(token));
      } catch (error) {
        Swal.fire('Opss...', 'Dados de login inválidos!', 'error');
      }
    },
    [state]
  );

  const signOut = useCallback(async () => {
    dispatch(authActionSignOut());
  }, [state]);

  const updateUser = useCallback(
    (data: Partial<IMUser>) => {
      dispatch(authActionUpdateUser(data));
    },
    [state]
  );

  useEffect(() => {
    try {
      if (
        (!state.user && state.token) ||
        (!state?.user && !state.token && authMiddlewareGetToken())
      ) {
        recovery()
          .then((_resolver) => ({}))
          .catch((_reject) => ({}));
      }
    } catch (error) {
      // do anything
    }
  }, [state]);

  return (
    <AuthContext.Provider value={state}>
      <AuthContextDispatch.Provider
        value={{ signIn, signOut, recovery, autoSignIn, updateUser }}
      >
        {children}
      </AuthContextDispatch.Provider>
    </AuthContext.Provider>
  );
}

export function useAuthContext(): IContextAuthDefaultData {
  return { ...useContext(AuthContext), ...useContext(AuthContextDispatch) };
}
