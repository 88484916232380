import React, { useEffect, useState } from 'react';

import { useStep } from '~/pages/Simulation/Hooks/useStep';
import { Container } from './styles';

const StepInfo: React.FC = () => {
  const { step, totalSteps } = useStep();
  const [stepDescription, setStepDescription] = useState(
    'Total da renda dos proponentes'
  );

  useEffect(() => {
    switch (step) {
      case 2:
        setStepDescription('Data de nascimento do integrante mais velho');
        break;
      case 3:
        setStepDescription('Tipo de imóvel desejado');
        break;
      case 4:
        setStepDescription('Valor do imóvel');
        break;
      default:
        setStepDescription('Total da renda dos proponentes');
        break;
    }
  }, [step]);

  return (
    <Container className="d-flex flex-column align-items-end mb-0 w-100">
      <p className="mb-0 fw-medium text-light-gray">
        Parte{' '}
        <span className="fw-bold">{step.toString().padStart(2, '0')}</span>/
        {totalSteps.toString().padStart(2, '0')}
      </p>
      <p className="font-data fw-bold">{stepDescription}</p>
    </Container>
  );
};

export default StepInfo;
