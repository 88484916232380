import React, { useCallback, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddFinancing from '~/assets/icons/add-financing.svg';
import { useClient } from '~/hooks/Client';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSpouse } from '~/hooks/Spouse';
import api from '~/services/api';
import initialsName from '~/utils/initialsName';
import { IDataForm } from '../ModalForm';

interface IModalProps {
  onSelectAvatar: (showForm: number, data: IDataForm) => void;
  osId: number;
  clientId: number;
}

interface IDataOss {
  vlrRenda: number;
}
const Avatars: React.FC<IModalProps> = ({ onSelectAvatar, osId, clientId }) => {
  const [participantes, setParticipantes] = useState<any[]>([]);
  const { client } = useClient();
  const { spouse } = useSpouse();
  const { serviceOrder } = useServiceOrder();

  useEffect(() => {
    api.get(`/os_participants/oss/${serviceOrder.id}`).then((response) => {
      setParticipantes(response.data);
    });
  }, [serviceOrder]);

  const handleAvatarSelected = useCallback(
    (formNumber: number, data: IDataForm) => {
      onSelectAvatar(formNumber, data);
    },
    [onSelectAvatar]
  );

  console.log('particpante', participantes, osId);

  return (
    <div className="d-flex justify-content-end mt-3">
      <button
        type="button"
        className="btn p-0"
        onClick={() => {
          handleAvatarSelected(1, {
            ...client,
            salary: serviceOrder?.vlrRenda
              ? serviceOrder.vlrRenda.toFixed(2)
              : '0',
            fgts_amount: client.fgts_amount
              ? client.fgts_amount.toFixed(2)
              : '0',
            cpf: '',
            income_value: '',
            os_id: undefined,
            numPis: client.pis_number,
            school_level_id: client.school_level_id,
          });
        }}
      >
        <div
          className="profile-icon d-flex justify-content-center align-items-center me-2"
          style={{ backgroundColor: '#8675FE' }}
        >
          <OverlayTrigger
            overlay={(props) => (
              <Tooltip id={client?.document} {...props}>
                {client?.name}
              </Tooltip>
            )}
            placement="top"
          >
            <p
              className="mb-0 p-2"
              data-bs-toggle="tooltip"
              title="Some tooltip text!"
            >
              {client?.name ? initialsName(client?.name) : 'N/A'}
            </p>
          </OverlayTrigger>
        </div>
      </button>
      {spouse && (
        <button
          type="button"
          className="btn p-0"
          onClick={() => {
            handleAvatarSelected(2, {
              ...spouse,
              salary: spouse.salary ? spouse.salary.toFixed(2) : '0',
              fgts_amount: spouse.fgts_amount
                ? spouse.fgts_amount.toFixed(2)
                : '0',
              cpf: spouse.document,
              income_value: '',
              os_id: undefined,
              numPis: spouse.pis_number,
              schooling_level_id: spouse.schoolinglevel_id,
              school_level_id: spouse.school_level_id,
            });
          }}
        >
          <div className="profile-icon d-flex justify-content-center align-items-center me-2 bg-warning">
            <OverlayTrigger
              overlay={(props) => (
                <Tooltip id={spouse?.document} {...props}>
                  {spouse?.name}
                </Tooltip>
              )}
              placement="top"
            >
              <p
                className="mb-0 p-2"
                data-bs-toggle="tooltip"
                title="Some tooltip text!"
              >
                {spouse?.name ? initialsName(spouse?.name) : 'N/A'}
              </p>
            </OverlayTrigger>
          </div>
        </button>
      )}

      {participantes &&
        participantes?.map((participant) => (
          <button
            type="button"
            className="btn p-0"
            onClick={() => {
              handleAvatarSelected(3, {
                ...participant,
                salary: participant.salary
                  ? participant.salary.toFixed(2)
                  : '0',
                fgts_amount:
                  participant.fgts_value !== 0
                    ? participant.fgts_value.toFixed(2)
                    : '0',
              });
            }}
          >
            <div
              className="profile-icon d-flex justify-content-center align-items-center me-2"
              style={{ backgroundColor: '#FF718B' }}
            >
              <OverlayTrigger
                overlay={(props) => (
                  <Tooltip id={participant?.document} {...props}>
                    {participant?.name}
                  </Tooltip>
                )}
                placement="top"
              >
                <p
                  className="mb-0 p-2"
                  data-bs-toggle="tooltip"
                  title="Some tooltip text!"
                >
                  {participant?.name ? initialsName(participant?.name) : 'N/A'}
                </p>
              </OverlayTrigger>
            </div>
          </button>
        ))}

      <button
        type="button"
        className="btn p-0"
        onClick={() => handleAvatarSelected(3, {} as IDataForm)}
      >
        <div className="add-financing d-flex align-items-center justify-content-center">
          <img src={AddFinancing} alt="" className="p-2" />
        </div>
      </button>
    </div>
  );
};

export default Avatars;
