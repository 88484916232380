import styled from 'styled-components';

interface IBackground {
  src: string;
}

export const Container = styled.div`
  > div {
    @media (max-width: 1500px) {
      min-height: 126vh;
    }

    @media (max-width: 1240px) {
      min-height: 130vh;
    }

    @media (max-width: 1060px) {
      min-height: 135vh;
    }

    @media (max-width: 1024px) {
      min-height: 100vh;
    }
  }
  @media (max-width: 768px) {
    padding-right: 25px;
  }
  .text-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }
  .text-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .centro {
    //centrar el cuadro con w-50

    @media (min-width: 600px) {
      margin-top: 5%;
      margin-left: auto;
      margin-right: auto;

      width: 80%;
    }
  }

  .title-card {
    padding-left: 5%;
    padding-right: 6%;
    @media (max-width: 992px) {
      padding-right: 12%;
    }
    @media (max-width: 864px) {
      padding-left: 0%;
      padding-right: 20%;
    }
    @media (max-width: 767px) {
      padding-left: 0%;
      padding-right: 0%;
    }
  }

  .user-card {
    @media (max-width: 767px) {
      display: flex;
      margin-left: 10%;
    }
  }

  .justify-content {
    justify-content: end;
  }

  @media (max-width: 992px) {
    .justify-content {
      justify-content: center;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  overflow: auto;
  img {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  hr {
    background-color: #f5f5f5;
  }
`;

export const Background = styled.div<IBackground>`
  background: url(${(props) => props.src}), #272727;
  background-size: cover;
  background-position: center;
  color: white;
`;

export const BackgroundVector = styled.div`
  height: 100vh;
  width: 100%;
  background-image: radial-gradient(#505050 1%, transparent 0);
  background-size: 600px;
  background-repeat: repeat repeat;
`;

export const Bar = styled.div`
  background: #39a887;
  border-radius: 26px;
  width: 67px;
  height: 9px;
  margin-left: 13px;
  margin-bottom: 30px;
`;
