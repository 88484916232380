import { IMBudgetRoom } from '~/models/BudgetRoom';
import { IMServiceOrder } from '~/models/ServiceOrder';

export const getCreditValue = (serviceOrder: IMServiceOrder): number => {
  return (
    (serviceOrder.simulacao?.vlrFinanciado ?? 0) +
    (serviceOrder.simulacao?.vlrFgts ?? 0) +
    (serviceOrder.simulacao?.vlrEntrada ?? 0) +
    (serviceOrder.simulacao?.vlrSubsidio ?? 0)
  );
};

export const getBudget = (serviceOrder: IMServiceOrder): number => {
  return (
    getCreditValue(serviceOrder) -
    (serviceOrder.finalidade_id === 1
      ? serviceOrder.simulacao?.vlrTerreno ?? 0
      : 0)
  );
};

export const getMarketValue = (serviceOrder: IMServiceOrder): number => {
  return (serviceOrder.simulacao?.vlrFinanciado ?? 0) * 1.55;
};

export const getTotalEnvironmetValue = (
  serviceOrder: IMServiceOrder
): number => {
  return (
    serviceOrder?.orc_ambientes?.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.vlrFinal ?? 0);
    }, 0) ?? 0
  );
};

export const getAccumulatedTotal = (serviceOrder: IMServiceOrder): number => {
  const totalEnvironmentValue = getTotalEnvironmetValue(serviceOrder);

  let accumulatedTotal = 0;

  if (serviceOrder.orc_parametros?.acabamento) {
    accumulatedTotal +=
      (totalEnvironmentValue *
        (serviceOrder.orc_parametros?.acabamento.margem ?? 1)) /
      100;
  }

  if (serviceOrder.orc_parametros?.terreno) {
    accumulatedTotal +=
      (totalEnvironmentValue *
        (serviceOrder.orc_parametros?.terreno.margem ?? 1)) /
      100;
  }

  if (serviceOrder.orc_parametros?.estilo_construcao) {
    accumulatedTotal +=
      (totalEnvironmentValue *
        (serviceOrder.orc_parametros?.estilo_construcao.margem ?? 1)) /
      100;
  }

  if (serviceOrder.orc_parametros?.eletrica) {
    accumulatedTotal +=
      (totalEnvironmentValue *
        (serviceOrder.orc_parametros?.eletrica.margem ?? 1)) /
      100;
  }

  if (serviceOrder.orc_parametros?.hidraulica) {
    accumulatedTotal +=
      (totalEnvironmentValue *
        (serviceOrder.orc_parametros?.hidraulica.margem ?? 1)) /
      100;
  }

  return totalEnvironmentValue + accumulatedTotal;
};

export const getFootage = (serviceOrder: IMServiceOrder): number => {
  return (
    serviceOrder.orc_ambientes
      ?.map((elem) => elem.metragem)
      .reduce((total, atual) => total + atual, 0) ?? 0
  );
};

export const getWeightedAverage = (serviceOrder: IMServiceOrder): number => {
  return (
    (serviceOrder.orc_ambientes
      ?.map((elem) => elem.metragem * elem.vlrLucro)
      .reduce((total, atual) => total + atual, 0) ?? 0) /
    getFootage(serviceOrder)
  );
};

export const getSuggestedFootage = (serviceOrder: IMServiceOrder): number => {
  return getBudget(serviceOrder) / getWeightedAverage(serviceOrder);
};

export const calculateEnvironmentsValues = (
  serviceOrder: IMServiceOrder,
  bdi: number
): IMBudgetRoom[] => {
  const environments = serviceOrder.orc_ambientes?.map((elem) => {
    const stateValue =
      serviceOrder?.terreno?.estado?.valor ?? 1 * elem?.metragem;
    const valueFinishing =
      stateValue * (serviceOrder?.orc_parametros?.acabamento?.margem ?? 1);
    const valueProfit = valueFinishing * (1 + bdi / 100);
    const valueFinal = valueProfit * elem.ambiente_tamanho.metragem;

    console.log(bdi, 1 + bdi / 100);
    return {
      ...elem,
      vlrEstado: stateValue,
      vlrAcabamento: valueFinishing,
      vlrLucro: valueProfit,
      vlrFinal: valueFinal,
    };
  });

  return environments ?? [];
};
