import './style.css';

export function Mock(): JSX.Element {
  return (
    <div className="mock">
      <div className="mock-card mock-animation" />
      <div className="mock-card mock-animation" />
      <div className="mock-card mock-animation" />
      <div className="mock-card-big mock-animation" />
      <div className="mock-card mock-animation" />
      <div className="mock-card mock-animation" />
      <div className="mock-card mock-animation" />
    </div>
  );
}
