import styled from 'styled-components';

interface IImg {
  src: string;
}

export const Container = styled.div``;

export const Title = styled.div`
  div {
    height: 5px;
    width: 40px;
    background: #39a887;
    border-radius: 26px;
  }
`;

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  overflow: hidden;
  @media (max-width: 740px) {
    margin: 0 !important;
  }
`;

export const Img = styled.div<IImg>`
  background: url(${(props) => props.src}), rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  width: 465px;
  height: 345px;
  @media (mam-width: 720px) {
    width: 100%;
    height: 240px;
    border-radius: 25px 25px 0 0;
  }
`;

export const Content = styled.div`
  width: calc(100% - 400px);
  button {
    border-radius: 20px;
    box-shadow: 0px 5px 9px 3px rgba(185, 201, 196, 0.5);
  }
  button.btn-primary {
    border-radius: 15px;
    font-size: large;
    margin: auto;
  }
  @media (max-width: 1200px) {
    width: 100%;
    height: 350px;
    .content-info {
      padding: 0 !important;
      margin: 2rem 0px 4rem 0px;
    }
    .content-info_button {
      padding: 0 !important;
      button {
        border-radius: 20px !important;
        padding: 10px 11px !important;
      }
    }
  }
`;
