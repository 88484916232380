/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BsBoxArrowInRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '~/hooks/contexts/Auth';
import { Container } from './styles';

const DropDownMenu: React.FC = () => {
  const { signOut, user } = useAuthContext();

  const redireccion = useHistory();

  const redirectContru = () => {
    redireccion.push('/perfil/dados-pessoais');
  };

  return (
    <Container>
      <ul
        className="w-[12.5rem] h-auto absolute mt-3 left-[-10.5rem] rounded-xl p-1 
        bg-white border-gray-300 border-[0.1rem] flex flex-col gap-2 z-50"
      >
        <li
          className="hover:bg-gray-100 transition-all cursor-pointer flex flex-row flex-nowrap items-center gap p-2 rounded-xl"
          onClick={redirectContru}
        >
          Perfil
        </li>

        {user && (
          <li
            className="hover:bg-gray-100 transition-all cursor-pointer flex flex-row flex-nowrap items-center gap p-2 rounded-xl"
            onClick={() => redireccion.push('/solicitacoes')}
          >
            Solitações
          </li>
        )}

        <li
          className="hover:bg-gray-100 transition-all cursor-pointer flex flex-row flex-nowrap items-center gap-3 p-2 rounded-xl"
          onClick={() => signOut()}
          style={{ color: 'red' }}
        >
          <BsBoxArrowInRight className="" />
          Sair
        </li>
      </ul>
    </Container>
  );
};

export default DropDownMenu;
