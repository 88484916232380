import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }
  }
  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
  .modal-header {
    border-bottom: 0 none;
  }
  .modal-footer {
    border-top: 0 none;
  }
`;

export const ModalDetail = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 3rem;
    h2 {
      color: #414142;
    }
    .close {
      font-size: 3.2rem;
      font-weight: 100;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;

      .sr-only {
        display: none;
      }
    }
    .modal-header {
      svg {
        margin-right: 1rem;
      }
    }
    .modal-body {
      margin-top: 3rem;
    }

    .modal-detail-amortization_data {
      color: #079269;
      font-weight: 600;
      margin-left: 2rem;
    }
  }
`;
