import React, { createContext, useState, useContext, useCallback } from 'react';

interface ReleasesOssContextData {
  releasesOss: number[];
  setReleasesOss: (value: number) => void;
}

export const ReleasesOssContext = createContext<ReleasesOssContextData>(
  {} as ReleasesOssContextData
);

export const ReleasesOssProvider: React.FC = ({ children }) => {
  const [releasesOss, setReleasesOss] = useState<number[]>(() => {
    const releasesOssData = localStorage.getItem('@HarveyClient:ReleasesOss');

    if (releasesOssData) {
      return JSON.parse(releasesOssData);
    }

    return [];
  });

  const handleSetReleasesOss = useCallback(
    (releasesOssData: number) => {
      const newReleasesOss = releasesOss.slice();
      newReleasesOss.push(releasesOssData);
      localStorage.setItem(
        '@HarveyClient:ReleasesOss',
        JSON.stringify(newReleasesOss)
      );
      setReleasesOss(newReleasesOss);
    },
    [releasesOss]
  );

  return (
    <ReleasesOssContext.Provider
      value={{ releasesOss, setReleasesOss: handleSetReleasesOss }}
    >
      {children}
    </ReleasesOssContext.Provider>
  );
};

export function useReleasesOss(): ReleasesOssContextData {
  const context = useContext(ReleasesOssContext);

  if (!context) {
    throw new Error(
      'useReleasesOss must be used within an ReleasesOssProvider'
    );
  }

  return context;
}
