/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { useProfile } from '~/hooks/Profile';

import MiniAvatar from '~/components/MiniAvatar';
import Notifications from '~/components/Notifications';
import { useAuthContext } from '~/hooks/contexts/Auth';
import DropDownMenu from '../MiniAvatarDropDown';
import { Avatar, Buttons2, Container, Container2, Container3 } from './styles';

interface IProps {
  home?: boolean;
  ordem?: boolean;
  handleClickNovaOS?(): void;
  solicitacoes?: boolean;
  chat?: boolean;
  marketing?: boolean;
  fluxo?: boolean;
  ferramentas?: boolean;
  projetos?: boolean;
  criarOS?: boolean;
  IniciarSimulacao?: boolean;
  Simulacao?: boolean;
  amortizacion?: boolean;
  DadosCliente?: boolean;
  MonteSuaCasa?: boolean;
  OrdemServico?: boolean;
  DetalhesOS?: boolean;
  awards?: boolean;
}

const UserDefault: React.FC<IProps> = ({
  home,
  ordem,
  handleClickNovaOS,
  solicitacoes,
  chat,
  marketing,
  fluxo,
  ferramentas,
  projetos,
  criarOS,
  IniciarSimulacao,
  Simulacao,
  amortizacion,
  DadosCliente,
  MonteSuaCasa,
  OrdemServico,
  DetalhesOS,
  awards,
}) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { photoEngineer } = useProfile();

  const redirectProfile = () => {
    history.push('/perfil/dados-pessoais');
  };
  const [open, setOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleDropdownFocus = (state: boolean) => {
    setOpen(!state);
  };

  const handleClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleBack = (): void => {
    history.goBack();
  };

  return (
    <>
      <div className="container mt-5 mt-lg-0">
        <div className="d-flex align-items-center mobile ps-0 ps-lg-0 px-lg-0">
          {!awards && (
            <div className="d-flex align-items-start">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center justify-content-start mb-2"
                onClick={handleBack}
              >
                <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
              </button>
            </div>
          )}
          <Container2 className="w-100">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              {home && (
                <div className="pe-0">
                  <h1 className="h4-lg fw-medio text-dark-gray mt-5 mt-lg-0">
                    Olá, {user!.name} 👋
                  </h1>
                </div>
              )}
              {ordem && (
                <div className="d-flex flex-row col align-items-center gap-4 pe-0">
                  <h1 className="text-title object-fit-content">
                    Ordem de serviço
                  </h1>
                  {(!user!.isConstrutor?.quantity ||
                    user!.isConstrutor?.quantity > user!.qtdOS) &&
                    handleClickNovaOS && (
                      <div className="col-5 px-2">
                        <button
                          type="button"
                          className="border-0 w-100 w-lg-75 btn-os d-flex align-items-center justify-content-center"
                          onClick={handleClickNovaOS}
                        >
                          <BsPlus size={22} color="#ffffff" />
                          Nova OS
                        </button>
                      </div>
                    )}
                </div>
              )}
              {solicitacoes && (
                <div className="pe-0">
                  <h1 className="text-title">Orçamentos solicitados</h1>
                </div>
              )}
              {chat && (
                <div className="pe-0">
                  <h1 className="text-title">Chat</h1>
                </div>
              )}
              {marketing && (
                <div className="pe-0">
                  <h1 className="text-title">Marketing</h1>
                </div>
              )}
              {fluxo && (
                <div>
                  <h1 className="text-title">Fluxo de ações</h1>
                </div>
              )}
              {ferramentas && (
                <div>
                  <h1 className="text-title">Ferramentas</h1>
                </div>
              )}
              {projetos && (
                <div>
                  <h1 className="text-title">Projetos</h1>
                </div>
              )}
              {criarOS && (
                <div>
                  <h1 className="text-title">Criar OS</h1>
                </div>
              )}
              {IniciarSimulacao && (
                <div>
                  <h1 className="text-title">Iniciar Simulação</h1>
                </div>
              )}
              {Simulacao && (
                <div>
                  <h1 className="h2 lg-h1 fw-semibold text-dark-gray ms-3">
                    Simulação
                  </h1>
                </div>
              )}
              {amortizacion && (
                <div>
                  <h1 className="h2 lg-h1 fw-semibold text-dark-gray ms-3">
                    Amortização
                  </h1>
                </div>
              )}
              {DadosCliente && (
                <div>
                  <h1 className="h2 lg-h1 fw-semibold text-dark-gray ms-4">
                    Dados do cliente
                  </h1>
                </div>
              )}
              {MonteSuaCasa && (
                <div>
                  <h1 className="h2 lg-h1 fw-semibold text-dark-gray ms-4">
                    Monte sua casa
                  </h1>
                </div>
              )}
              {OrdemServico && (
                <div>
                  <h1 className="h2 lg-h1 fw-semibold text-dark-gray ms-4">
                    Ordem de serviço
                  </h1>
                </div>
              )}
              {DetalhesOS && (
                <div>
                  <h1 className="h3 lg-h1 fw-semibold text-dark-gray">
                    Detalhes do Orçamento
                  </h1>
                </div>
              )}

              <div className="col-lg-4 top-align mt-4 mt-lg-0 mobile">
                <Container3>
                  <div className="align-center">
                    <div
                      className="app-drop-down-container bg-transparent fondo"
                      ref={dropdownRef}
                    >
                      <div
                        className="align-items-center user-login mt-2"
                        onClick={() => handleDropdownFocus(open)}
                        onKeyPress={() => handleDropdownFocus(open)}
                        role="button"
                        tabIndex={0}
                      >
                        <div className="me-4">
                          <Avatar src={photoEngineer} className="mx-auto" />
                        </div>
                        <div className="me-4">
                          <div>
                            <p className="fw-semibold small text-dark-gray mb-0">
                              {user!.name}
                            </p>
                            {user!.isConstrutor && (
                              <p className="fw-light m-0"> Construtor </p>
                            )}
                          </div>
                        </div>
                        <div className="relative">
                          <MiniAvatar />
                          {open && <DropDownMenu />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Container3>
              </div>
            </div>
          </Container2>
          {!awards && (
            <div className="row">
              <Container>
                <div className="row justify-content-center">
                  <div className="col-1 me-4 no-mobile">
                    <div
                      role="button"
                      onClick={redirectProfile}
                      onKeyPress={redirectProfile}
                      tabIndex={0}
                    >
                      <Avatar src={photoEngineer} />
                    </div>
                  </div>
                  <div className="col-1 col-lg-auto ms-1">
                    <Buttons2>
                      <Notifications />
                    </Buttons2>
                  </div>
                </div>
              </Container>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDefault;
