import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IOption } from '~/components/Select';
import { IPropertyTypes } from '~/models/Simulation/Steps';
import api from '~/services/api';
import { formatToFloat } from '~/utils/format';

interface StepsContextData {
  errorData: string;
  setErrorData(error: string): void;
  step: number;
  setStep(step: number): void;
  handleClickNext(step: number): void;
  totalSteps: number;
  setTotalSteps(step: number): void;
  revenue: string;
  setRevenue(revenue: string): void;
  birthdate: Date | undefined;
  setBirthdate(date: Date | undefined): void;
  errorBirthdate: string;
  setErrorBirthdate(msgError: string): void;
  activeCalendar: boolean;
  setActiveCalendar(state: boolean): void;
  typesimovel: IOption[];
  propertyType: IOption;
  setPropertyType(option: IOption): void;
  propertyPrice: string;
  setPropertyPrice(price: string): void;
  setIDImovel(id: number): void;
}

export const StepsContext = createContext<StepsContextData>(
  {} as StepsContextData
);

export const StepsProvider: React.FC = ({ children }) => {
  const [errorData, setErrorData] = useState('');
  const [step, setStep] = useState(1);
  const [revenue, setRevenue] = useState('');
  const [propertyPrice, setPropertyPrice] = useState('');

  const [totalSteps, setTotalSteps] = useState(0);
  const [errorBirthdate, setErrorBirthdate] = useState('');

  const [birthdate, setBirthdate] = useState<Date | undefined>(undefined);
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [propertyType, setPropertyType] = useState({} as IOption);
  const [typesimovel, setTypesimovel] = useState<IOption[]>([
    {
      id: 1,
      value: 'Test',
      selected: false,
    },
  ]);
  // const [furnitureType] = useState({} as IPropertyType);
  const [iDImovel, setIDImovel] = useState(0);

  const handleClickNext = useCallback(
    (stepData) => {
      setErrorData('');
      switch (stepData) {
        case 2:
          if (revenue.length === 0) {
            setErrorData('O valor da renda é obrigatório');
          } else if (formatToFloat(revenue) < 50000) {
            setErrorData('O valor da renda deve ser acima ou igual a R$500,00');
          } else {
            // handleChangeStep(stepData);
          }
          break;
        case 3:
          if (!birthdate) {
            setErrorData('A data de nascimento é obrigatória');
          } else if (!errorBirthdate) {
            // handleChangeStep(stepData);
          }
          break;
        default:
          if (Object.keys(propertyType).length === 0) {
            setErrorData('O tipo de imóvel é obrigatório');
          } else {
            // handleChangeStep(stepData);
          }
          break;
      }
    },
    [birthdate, errorBirthdate, propertyType, revenue]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadTypesImovel = useCallback(() => {
    api.get<IPropertyTypes>(`/furniture-types`).then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: IOption[] = (response.data as unknown as any[]).map(
        (propertyData) => ({
          id: propertyData.id,
          value: propertyData.furnitureType,
          selected: iDImovel === propertyData.id,
        })
      );
      setTypesimovel(data);
    });
  }, [iDImovel]);

  useEffect(() => {
    loadTypesImovel();
  }, [loadTypesImovel]);

  return (
    <StepsContext.Provider
      value={{
        errorData,
        setErrorData,
        revenue,
        setRevenue,
        step,
        setStep,
        handleClickNext,
        totalSteps,
        setTotalSteps,
        birthdate,
        setBirthdate,
        errorBirthdate,
        setErrorBirthdate,
        activeCalendar,
        setActiveCalendar,
        typesimovel,
        setPropertyType,
        propertyType,
        setPropertyPrice,
        propertyPrice,
        setIDImovel,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

export function useStep(): StepsContextData {
  const context = useContext(StepsContext);

  if (!context) {
    throw new Error('useStep must be used within a ProjectProvider');
  }

  return context;
}
