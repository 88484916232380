import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IOrderService {
  id: number;
  anticipate_quotas?: boolean;
  agency?: string;
  builder_id: number;
  builder?: {
    id?: number;
    name?: string;
  };
  credit_agency?: string;
  credit_bank?: string;
  credit_acount?: string;
  fgts?: boolean;
  fgts_amount?: number;
  married?: boolean;
  birth_date?: Date;
  sex?: number;
  marital_status_id?: number;
  date_stable_union?: Date;
  mother?: string;
  father?: string;
  pep?: string;
  email?: string;
  cell?: string;
  telephone?: string;
  zip_code?: string;
  address?: string;
  number?: string;
  complement?: string;
  district?: string;
  state_id?: number;
  state?: {
    id?: number;
    name?: string;
  };
  residence_time?: number;
  profession?: string;
  company_name?: string;
  company_document?: string;
  occupation_type_id?: number;
  shipping_date?: Date;
}

interface OrderServiceContextData {
  orderService: IOrderService;
  setOrderService(orderService: IOrderService): void;
}

export const OrderServiceContext = createContext<OrderServiceContextData>(
  {} as OrderServiceContextData
);

export const OrderServiceProvider: React.FC = ({ children }) => {
  const [orderService, setOrderService] = useState(() => {
    const orderServiceData = localStorage.getItem('@HarveyClient:OS');

    if (orderServiceData) {
      return JSON.parse(orderServiceData);
    }

    return {} as IOrderService;
  });

  const handleSetOrderService = useCallback(
    (orderServiceData: IOrderService) => {
      localStorage.setItem(
        '@HarveyClient:OS',
        JSON.stringify(orderServiceData)
      );
      setOrderService(orderServiceData);
    },
    []
  );

  return (
    <OrderServiceContext.Provider
      value={{ orderService, setOrderService: handleSetOrderService }}
    >
      {children}
    </OrderServiceContext.Provider>
  );
};

export function useOrderService(): OrderServiceContextData {
  const context = useContext(OrderServiceContext);

  if (!context) {
    throw new Error(
      'useOrderService must be used within an OrderServiceProvider'
    );
  }

  return context;
}
