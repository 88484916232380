import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: string;
  hasError?(hasError: boolean): void;
}

const Input: FC<InputProps> = ({
  name,
  hasError,
  className,
  error: errorData,
  type,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [typeInput, setTypeInput] = useState('password');
  const [cpf, setCpf] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  const handleCpfChange = useCallback(
    (e) => {
      const { value } = e.target;
      const { inputType } = e.nativeEvent;
      const lastValue = parseInt(value.charAt(value.length - 1), 10);

      if (inputType === 'deleteContentBackward') setCpf(cpf.slice(0, -1));
      else if (value.length < 15 && Number.isInteger(lastValue)) {
        setCpf(value);
        if (value.length % 4 === 0) {
          if (value.length === 12)
            setCpf(cpf.concat('-', value.charAt(value.length - 1)));
          else setCpf(cpf.concat('.', value.charAt(value.length - 1)));
        }
      }
    },
    [cpf]
  );

  const handleClick = useCallback(() => {
    setTypeInput((state) => (state === 'password' ? 'text' : 'password'));
  }, []);

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          {type !== 'password' && type !== 'cpf' && (
            <input
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              ref={inputRef}
              type={type || 'text'}
              {...rest}
            />
          )}
          {type === 'password' && (
            <>
              <input
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                type={typeInput}
                {...rest}
              />
              <button
                type="button"
                className="button-show"
                onClick={handleClick}
              >
                {typeInput === 'password' ? 'Mostrar' : 'Ocultar'}
              </button>
            </>
          )}
          {type === 'cpf' && (
            <input
              onChange={handleCpfChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              value={cpf}
              ref={inputRef}
              type={type || 'text'}
              {...rest}
            />
          )}
        </div>
      </Container>
      {(errorData || error) && (
        <span className="small text-danger error">{errorData || error}</span>
      )}
    </>
  );
};

export default Input;
