import React from 'react';

import Loading from '~/components/Loading';
import { useSimulations } from '~/hooks/Simulations';

const LoadingResults: React.FC = () => {
  const { loading } = useSimulations();

  return (
    <Loading
      active={loading}
      textMessage="Estamos preparando a melhor simulação para você"
    />
  );
};

export default LoadingResults;
