import React, { useCallback, useEffect, useState } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuthContext } from '~/hooks/contexts/Auth';
import randomColor from '~/utils/colorGenerator';
import initialsName from '~/utils/initialsName';

import Notifications from '~/components/Notifications copy';
import { useClient } from '~/hooks/Client';
import { useSpouse } from '~/hooks/Spouse';
import api from '~/services/api';
import { Buttons, Container } from './styles';

import { useParticipant } from '~/hooks/Participants';
import Avatars from './Avatars';
import ModalForm, { IDataForm } from './ModalForm';

interface IParticipantes {
  id: number;
  nome: string;
  vlrRenda: number;
  cpf: string;
  vlrFgts: number;
  profissao: string;
  numPis: string;
  nvEscolar_id: number;
}
interface IUserData {
  osId: any;
  osKey?: any;
}
const UserData: React.FC<IUserData> = ({ osId, osKey }) => {
  const history = useHistory();
  const [color, setColor] = useState<string>();
  const [showForm, setShowForm] = useState(0);
  const { client, setClient } = useClient();
  const { spouse, setSpouse } = useSpouse();
  const { participants, setParticipants } = useParticipant();
  const [incomeValue, setIncomeValue] = useState(0);

  const [dataForm, setDataForm] = useState<IDataForm>({} as IDataForm);
  const { user } = useAuthContext();

  useEffect(() => {
    const colorGenerator = randomColor();
    setColor(colorGenerator);
  }, []);

  const handleAvatarSelected = useCallback(
    (formNumber: number, data: IDataForm) => {
      setDataForm(data);
      setShowForm(formNumber);
    },
    [setShowForm, setDataForm]
  );

  const handleSubmitClient = useCallback(
    async (data: IDataForm) => {
      try {
        const vlrRenda = data.salary
          ? parseFloat(
              data.salary
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            )
          : 0;

        if (vlrRenda.toString().length > 8) {
          Swal.fire({
            icon: 'warning',
            title: 'O valor da sua renda é inválido',
          });
          return;
        }

        const formData = {
          nome: data.name,
          documento: data.document,
          profissao: data.profession,
          numPis: data.pis_number,
          nvEscolar_id: data.schooling_level_id,
          fgts: data.fgts,
          os_id: osId,
          vlrFgts: data.fgts_amount
            ? parseFloat(
                data.fgts_amount
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              )
            : 0,
          vlrRenda,
        };

        // for saving client incomeValue in Service Order
        setIncomeValue(vlrRenda);

        if (client) {
          const response = await api.put(`/customers/${client.id}`, formData);

          const ordemServico = await api.get(
            `/service-orders/${osId}/${client.id}`
          );
          const newOS = { ...ordemServico.data, vlrRenda };
          const responseOS = await api.put(
            `/service-orders/${osId}/${client.id}`,
            newOS
          );

          // setting client
          setClient({
            ...response.data,
            name: response.data.nome,
            document: response.data.documento,
            profession: response.data.profissao,
            pis_number: response.data.numPis,
            school_level_id: response.data.schooling_level_id,
            salary: responseOS.data.vlrRenda,
          });
        } else {
          const response = await api.post(`/customers`, formData);
          setClient({
            ...response.data,
            name: response.data.nome,
            document: response.data.documento,
            profession: response.data.profissao,
            pis_number: response.data.numPis,
            school_level_id: response.data.schooling_level_id,
          });
        }

        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [client, osId, setClient]
  );

  const handleSubmitSpouse = useCallback(
    async (data: IDataForm) => {
      try {
        let vlrRenda = 0;
        if (data.salary && !data.salary.includes('R$')) {
          vlrRenda = parseFloat(data.salary.replaceAll('.', ','));
        } else if (data.salary && data.salary.includes('R$')) {
          vlrRenda = parseFloat(
            data.salary.replace('R$', '').replaceAll('.', '').replace(',', '.')
          );
        }
        const formData = {
          os_id: osId,
          nome: data.name,
          document: data.document,
          cpf: data.document,
          profissao: data.profession,
          fgts: data.fgts,
          vlrFgts: data.fgts_amount
            ? parseFloat(
                data.fgts_amount
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              )
            : 0,
          vlrRenda: Number(vlrRenda),
          numPis: data.pis_number,
          nvEscolar_id: data.schooling_level_id,
        };

        if (Object.values(spouse).length > 0) {
          const response = await api.put(`/os_spouses/${spouse.id}`, formData);
          setSpouse({
            ...response.data,
            name: response.data.nome,
            salary: response.data.vlrRenda,
            document: response.data.cpf,
            profession: response.data.profissao,
            fgts_amount: response.data.vlrFgts,
            pis_number: response.data.numPis,
            school_level_id: response.data.nvEscolar_id,
          });
        } else {
          const response = await api.post(`/os_spouses/`, formData);
          setSpouse({
            ...response.data,
            name: response.data.nome,
            salary: response.data.vlrRenda,
            document: response.data.cpf,
            profession: response.data.profissao,
            fgts_amount: response.data.vlrFgts,
            pis_number: response.data.numPis,
            school_level_id: response.data.nvEscolar_id,
          });
        }

        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [osId, setSpouse, spouse]
  );

  const handleSubmitParticipant = useCallback(
    async (data: IDataForm) => {
      // api.get<IParticipantes>(`os_participants/oss/$osId`);

      try {
        const participantes = await api.get<IParticipantes[]>(
          `os_participants/oss/${osId}`
        );
        let esteParticipante: IParticipantes = {} as IParticipantes;
        participantes.data.forEach((part) => {
          if (part.cpf === data.document) {
            esteParticipante = part;
          }
        });
        let vlrRenda = 0;
        if (data.salary && !data.salary.includes('R$')) {
          vlrRenda = parseFloat(data.salary.replaceAll('.', ','));
        } else if (data.salary && data.salary.includes('R$')) {
          vlrRenda = parseFloat(
            data.salary.replace('R$', '').replaceAll('.', '').replace(',', '.')
          );
        }
        const formData = {
          os_id: osId,
          nome: data.name,
          documento: data.document,
          cpf: data.document,
          profissao: data.profession,
          numPis: data.pis_number,
          nvEscolar_id: data.schooling_level_id,
          schooling_level_id: data.schooling_level_id,
          fgts: data.fgts,
          vlrFgts: data.fgts_amount
            ? parseFloat(
                data.fgts_amount
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              )
            : 0,
          vlrRenda: Number(vlrRenda),
        };
        if (Object.values(esteParticipante).length > 0) {
          const response = await api.put(
            `/os_participants/${esteParticipante.id}`,
            formData
          );
          const newParticipants = participantes.data.map((participant) => {
            if (participant.id === esteParticipante.id) {
              return {
                ...response.data,
                schooling_level_id: response.data.nvEscolar_id,
              };
            }
            return participant;
          });
          setParticipants(newParticipants);
        } else {
          const response = await api.post(`/os_participants/`, formData);
          setParticipants([...participants, response.data]);
        }
        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [osId, participants, setParticipants]
  );

  const handleClickRezafer = useCallback(() => {
    history.push(`/simulador/edit/${osId}/${osKey}`);
  }, [history, osId, osKey]);

  return (
    <Container className="mt-5 container">
      <div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            <div
              className="profile-icon d-flex justify-content-center align-items-center"
              style={{ backgroundColor: color }}
            >
              <p className="mb-0 p-2">
                {client?.name ? initialsName(client?.name) : ''}
              </p>
            </div>
            <div className="ms-3">
              <p className="mb-0 h6">{client?.name}</p>
              <p className="mb-0 client-name">Cliente</p>
            </div>
          </div>
          <Buttons className="d-flex justify-content-end px-2">
            <Notifications />
          </Buttons>
        </div>
        <div className="col-lg-12 mb-4">
          <div className="d-flex justify-content-end px-0">
            {user == null ? (
              <Link
                to={`${process.env.PUBLIC_URL}/login`}
                className="text-black fw-medium py-1"
              >
                Entrar
              </Link>
            ) : (
              <Link
                to="/solicitacoes"
                className="back text-black fw-medium py-1 px-2"
              >
                <RiArrowLeftSLine size={28} color="#000000" />
                Minhas solicitações
              </Link>
            )}

            <div className="text-black fw-medium px-2 py-1">
              {' '}
              {user == null ? '|' : ''}{' '}
            </div>
            {user == null ? (
              <Link
                to={`${process.env.PUBLIC_URL}/cadastre-se`}
                className="text-black fw-medium py-1"
              >
                Registrar
              </Link>
            ) : (
              <Link
                to={`${process.env.PUBLIC_URL}/cadastre-se`}
                className="text-black fw-medium py-1"
              />
            )}
          </div>
        </div>
        <div>
          <h2 className="h3 mb-4">Olá, {client?.name}</h2>
          <p>
            Estamos aqui para tirar o seu sonho do papel e construir sua casa
            juntos. Aqui está sua solicitação de financiamento
          </p>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <Avatars
            onSelectAvatar={handleAvatarSelected}
            osId={osId}
            clientId={client.id}
          />
        </div>
        <div className="d-flex justify-content-start">
          <button
            type="button"
            className="btn d-flex align-items-center refazer-button px-4"
            onClick={handleClickRezafer}
          >
            <span className="fw-semibold text-gray">Refazer a Simulação</span>{' '}
            <BsArrowCounterclockwise size={20} color="#707070" />
          </button>
        </div>
      </div>
      <ModalForm
        show={showForm === 1}
        onSubmit={handleSubmitClient}
        data={dataForm}
        onHide={() => setShowForm(0)}
        osId={osId}
      />
      <ModalForm
        show={showForm === 2}
        onSubmit={handleSubmitSpouse}
        data={dataForm}
        onHide={() => setShowForm(0)}
      />
      <ModalForm
        show={showForm === 3}
        onSubmit={handleSubmitParticipant}
        data={dataForm}
        onHide={() => setShowForm(0)}
      />
    </Container>
  );
};

export default UserData;
