import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RouteComponent from './Route';

import BuildYourHouse from '~/pages/BuildYourHouse';
import AboutYourHouse from '~/pages/BuildYourHouse/AboutYourHouse';
import Finishing from '~/pages/BuildYourHouse/Finishing';
import Builders from '~/pages/Builders';
import Builder from '~/pages/Builders/Builder';
import Dashboard from '~/pages/Dashboard';
import Finalities from '~/pages/Finalities';
import ForgotPassword from '~/pages/ForgotPassword';
import Login from '~/pages/Login';
import Requests from '~/pages/Requests';
import ResetPassword from '~/pages/ResetPassword';
import ServiceOrderOpening from '~/pages/ServiceOrderOpening';
import SignUp from '~/pages/SignUp';
import Simulation from '~/pages/Simulation';
import SimulationResults from '~/pages/Simulation/Results';
import SimulationResultAmortization from '~/pages/Simulation/Results/Amortization';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Finalities} />
      <Route
        path={`${process.env.PUBLIC_URL}/nova-os/:builderId/:bdi`}
        exact
        component={Finalities}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/simulador`}
        exact
        component={Simulation}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/simulador/edit/:osId/:osKey`}
        exact
        isPrivate
        component={Simulation}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/simulador/nova-os/:builderId/:bdi`}
        exact
        component={Simulation}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/simulador/resultado`}
        exact
        component={SimulationResults}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/simulador/resultado/edit/:osId`}
        exact
        isPrivate
        component={SimulationResults}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/simulador/resultado/nova-os/:builderId/:bdi`}
        exact
        component={SimulationResults}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/simulador/resumo`}
        exact
        component={SimulationResultAmortization}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/simulador/resumo/edit/:osId`}
        exact
        isPrivate
        component={SimulationResultAmortization}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/simulador/resumo/nova-os/:builderId/:bdi`}
        exact
        component={SimulationResultAmortization}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento`}
        exact
        component={ServiceOrderOpening}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/edit/:osId`}
        exact
        isPrivate
        component={ServiceOrderOpening}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/nova-os/:builderId/:bdi`}
        exact
        component={ServiceOrderOpening}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId`}
        exact
        component={BuildYourHouse}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/edit/:osId`}
        exact
        isPrivate
        component={BuildYourHouse}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/acabamento`}
        exact
        component={Finishing}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/edit/:osId/acabamento`}
        exact
        component={Finishing}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/acabamento/:slug`}
        exact
        component={Finishing}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/edit/:osId/acabamento/:slug`}
        exact
        component={Finishing}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/sobre-a-casa/:slug/:option`}
        exact
        component={AboutYourHouse}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/dashboard/:os_id`}
        exact
        component={Dashboard}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/login`}
        exact
        component={Login}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/cadastre-se`}
        component={SignUp}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/resetar-senha/:token`}
        exact
        component={ResetPassword}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/solicitacoes`}
        exact
        isPrivate
        component={Requests}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/:os_id/selecionar-construtor/:os_key`}
        exact
        isPrivate
        component={Builders}
      />
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/:os_id/construtor/:builder_id`}
        exact
        isPrivate
        component={Builder}
      />
    </Switch>
  );
};

export default routes;
