import React, { createContext, useState, useContext, useCallback } from 'react';

export interface ISpouse {
  schoolinglevel_id?: number | undefined;
  id: number;
  so_id?: number;
  name: string;
  document: string;
  birth_date?: Date;
  shipping_date?: Date;
  identity_document?: string;
  salary?: number;
  fgts_amount?: number;
  pis_number?: string;
  profession?: string;
  school_level_id?: number;
}

interface SpouseContextData {
  spouse: ISpouse;
  setSpouse(spouse: ISpouse): void;
}

export const SpouseContext = createContext<SpouseContextData>(
  {} as SpouseContextData
);

export const SpouseProvider: React.FC = ({ children }) => {
  const [spouse, setSpouse] = useState({} as ISpouse);

  const handleSetSpouse = useCallback((spouseData: ISpouse) => {
    setSpouse(spouseData);
  }, []);

  return (
    <SpouseContext.Provider value={{ spouse, setSpouse: handleSetSpouse }}>
      {children}
    </SpouseContext.Provider>
  );
};

export function useSpouse(): SpouseContextData {
  const context = useContext(SpouseContext);

  if (!context) {
    throw new Error('useSpouse must be used within an SpouseProvider');
  }

  return context;
}
