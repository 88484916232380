import { IMAmortization } from '~/models/Amortization';
import { IMImmobileType } from '~/models/InmobileTypes';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { IMSimulation } from '~/models/Simulation';

interface ICalculationParams {
  amortization: IMAmortization;
  age: number;
  revenue: number;
  deadline: number;
  targetInstallment: number;
  limitValue: number;
  targetLimitValue: number;
}

export interface ICalculationResponse {
  amortizacao_id: number;
  amortizacao: IMAmortization;
  vlrParcInic: number;
  vlrParcFinal: number;
  vlrFinanciado: number;
  taxa: number;
  prazo: number;
  vlrImovPronto: number;
  parcelaMax: number;
}

const getRateByAge = (old: number): number => {
  if (old >= 18 && old <= 25) {
    return 0.0115;
  }
  if (old >= 26 && old <= 30) {
    return 0.0121;
  }
  if (old >= 31 && old <= 35) {
    return 0.0178;
  }
  if (old >= 36 && old <= 40) {
    return 0.0234;
  }
  if (old >= 41 && old <= 45) {
    return 0.0318;
  }
  if (old >= 46 && old <= 50) {
    return 0.0448;
  }
  if (old >= 51 && old <= 55) {
    return 0.0751;
  }
  if (old >= 56 && old <= 60) {
    return 0.1561;
  }
  if (old >= 61 && old <= 65) {
    return 0.2781;
  }
  if (old >= 66 && old <= 70) {
    return 0.3549;
  }
  if (old >= 71 && old <= 75) {
    return 0.4707;
  }

  return 0.4707;
};

const calcSAC = ({
  amortization,
  age,
  revenue,
  deadline,
  targetInstallment,
  limitValue,
  targetLimitValue,
}: ICalculationParams): ICalculationResponse => {
  let maxPeriod = 966 - age * 12;

  maxPeriod =
    maxPeriod >= (amortization?.prazo ?? 0)
      ? amortization?.prazo ?? 0
      : Math.abs(maxPeriod);

  let period = deadline;
  period *= 12;
  period = period && period <= maxPeriod ? period : maxPeriod;

  const { taxa: rate, percentual: percentage } = amortization;

  const maximunInstallment = revenue * (percentage ?? 1);
  const installment =
    targetInstallment > 0 && targetInstallment < maximunInstallment
      ? targetInstallment
      : maximunInstallment;

  const x = 1 / period;
  const y = (rate ?? 0) / 100 + x;
  const z = installment / y;

  const txmip = (getRateByAge(age) / 100) * z;
  // não varia
  const txdfi = (z / 0.8) * 0.000078;
  const initialInstallmentValue = installment - txmip - txdfi - 25;
  let financedValue = (initialInstallmentValue / y) * 0.99;

  if (limitValue && financedValue > limitValue) {
    financedValue = limitValue;
  }

  if (targetLimitValue && targetLimitValue < financedValue) {
    financedValue = targetLimitValue;
  }

  const finalInstallmentValue = financedValue / period + 25;

  return {
    amortizacao_id: amortization.id || -1,
    amortizacao: amortization,
    vlrParcInic: initialInstallmentValue,
    vlrParcFinal: finalInstallmentValue,
    vlrFinanciado: financedValue,
    taxa: parseFloat(((rate ?? 1) * 12).toFixed(2)),
    prazo: maxPeriod / 12,
    vlrImovPronto: financedValue / 0.8,
    parcelaMax: installment,
  };
};

const calcPrice = ({
  amortization,
  age,
  revenue,
  deadline,
  targetInstallment,
  limitValue,
  targetLimitValue,
}: ICalculationParams): ICalculationResponse => {
  let maxPeriod = 966 - age * 12;
  maxPeriod =
    maxPeriod >= (amortization?.prazo ?? 0)
      ? amortization.prazo ?? 0
      : Math.abs(maxPeriod);

  let period = deadline;
  period *= 12;
  period = period && period <= maxPeriod ? period : maxPeriod;

  const rate = (amortization?.taxa ?? 0) / 100;
  const { percentual: percentage } = amortization;

  const maximunInstallment = revenue * (percentage ?? 1);
  let installment =
    targetInstallment > 0 && targetInstallment < maximunInstallment
      ? targetInstallment
      : maximunInstallment;

  const fakeFinancedValue = installment * ((1 - (1 + rate) ** -period) / rate);

  const txmip = (getRateByAge(age) / 100) * fakeFinancedValue;
  const txdfi = (fakeFinancedValue / 0.8) * 0.000078;

  const initialInstallmentValue = installment - txmip - txdfi - 25;
  let financedValue =
    initialInstallmentValue * ((1 - (1 + rate) ** -period) / rate);

  if (limitValue && financedValue > limitValue) {
    const p = targetLimitValue / financedValue;
    installment *= p;
    financedValue = limitValue;
  }

  if (targetLimitValue && targetLimitValue < financedValue) {
    const p = targetLimitValue / financedValue;
    installment *= p;
    financedValue = targetLimitValue;
  }

  return {
    amortizacao_id: amortization.id || -1,
    amortizacao: amortization,
    vlrParcInic: installment,
    vlrParcFinal: installment,
    vlrFinanciado: financedValue,
    taxa: parseFloat((rate * 12 * 100).toFixed(2)),
    prazo: maxPeriod / 12,
    vlrImovPronto: financedValue / 0.8,
    parcelaMax: installment,
  };
};

const simulationCalculation = ({
  amortization,
  age,
  revenue,
  deadline,
  targetInstallment,
  limitValue,
  targetLimitValue,
}: ICalculationParams): ICalculationResponse => {
  const functions = {
    calcSAC: calcSAC({
      amortization,
      age,
      revenue,
      deadline,
      targetInstallment,
      limitValue,
      targetLimitValue,
    }),
    calcPrice: calcPrice({
      amortization,
      age,
      revenue,
      deadline,
      targetInstallment,
      limitValue,
      targetLimitValue,
    }),
  };

  if (!amortization.funcao) {
    throw new Error('Tipo de calculo para amortização, é invalida');
  }

  return functions[amortization.funcao] as unknown as ICalculationResponse;
};

export { calcPrice, calcSAC, getRateByAge, simulationCalculation };

export const maximumInstallment = (simulations: IMSimulation[]): number => {
  if (simulations.length === 0) return 0;
  const max = simulations.reduce((acc, cur) =>
    (acc.vlrParcInic ?? 0) > (cur.vlrParcInic ?? 0) ? acc : cur
  );
  return max.vlrParcInic ?? 0;
};

export const maximumValueProperty = (simulations: IMSimulation[]): number => {
  if (simulations.length === 0) return 0;
  const max = simulations.reduce((acc, cur) =>
    (acc.vlrImovPronto ?? 0) > (cur.vlrImovPronto ?? 0) ? acc : cur
  );
  return max.vlrImovPronto ?? 0;
};

export const maximumDeadline = (simulations: IMSimulation[]): number => {
  if (simulations.length === 0) return 0;
  const max = simulations.reduce((acc, cur) =>
    (acc.prazo ?? 1) > (cur.prazo ?? 1) ? acc : cur
  );

  // TODO - LOG PARA DEBUG
  /*
  console.log('maximumDeadline');
  console.table(max);
  */

  return max.prazo ?? 1;
};

export const maximumFinancedValue = (simulations: IMSimulation[]): number => {
  if (simulations.length === 0) return 0;
  const max = simulations.reduce((acc, cur) =>
    (acc.vlrFinanciado ?? 0) > (cur.vlrFinanciado ?? 0) ? acc : cur
  );
  return max.vlrFinanciado ?? 0;
};

export const calculateLimitValue = (
  immobileTypes: IMImmobileType[],
  serviceOrder: IMServiceOrder,
  amortization: IMAmortization
): number => {
  let percentage = 0;
  const percAttribute =
    serviceOrder.finalidade_id === 6 ?? serviceOrder.finalidade_id === 7
      ? 'percentage'
      : 'perc_in';
  const immobileTypeSelected = immobileTypes.find(
    (immobileType) => immobileType.id === serviceOrder.simulacao?.tipo_imovel_id
  );
  percentage =
    serviceOrder.finalidade_id === 3 ?? serviceOrder.finalidade_id === 4
      ? amortization?.funding_limit ?? 0
      : immobileTypeSelected?.[percAttribute] ?? 0;
  return ((serviceOrder.simulacao?.vlrImovel ?? 0) * percentage) / 100;
};
