import React, { useCallback, useState } from 'react';

// Hooks
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSimulations } from '~/hooks/Simulations';

// Utils
import { formatPrice } from '~/utils/format';
import { simulationCalculation } from '~/utils/simulationCalculations';

// Styles
import { Container, Range } from './styles';

const CardRangeValueAndYears: React.FC = () => {
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const {
    yearSelected,
    setYearSelected,
    targetLimitValue,
    setTargetLimitValue,
  } = useSimulations();
  // const [yearSelected, setYearSelected] = useState<number>(
  //   serviceOrder.simulacao?.prazo || 1
  // );
  const yearsTotal = serviceOrder.simulacao?.prazo || 0;
  const [installment, setInstallment] = useState<number>(
    serviceOrder.simulacao?.vlrParcInic || 0
  );
  const [maxInstallment] = useState<number>(
    serviceOrder.simulacao?.vlrParcInic || 0
  );
  const [financedValue] = useState<number>(
    serviceOrder?.simulacao?.vlrFinanciado || 0
  );

  const handleCalculationSimulation = useCallback(
    (deadline: number, targetInstallment: number, targetLimit: number) => {
      if (serviceOrder.simulacao?.amortizacao) {
        const simulationResponse = simulationCalculation({
          amortization: serviceOrder.simulacao?.amortizacao,
          age:
            serviceOrder.ageOlder ||
            new Date(serviceOrder.cliente?.dt_nascimento || '').getFullYear() -
              new Date().getFullYear(),
          revenue: serviceOrder.simulacao?.vlrRenda || 0,
          deadline,
          targetInstallment:
            Math.round(targetInstallment) === Math.round(maxInstallment)
              ? 0
              : targetInstallment,
          limitValue: 0,
          targetLimitValue: targetLimit,
        });

        const vlrEntrada =
          serviceOrder?.finalidade_id === 3 || serviceOrder?.finalidade_id === 4
            ? (serviceOrder.simulacao?.vlrImovel || 0) -
              simulationResponse.vlrFinanciado
            : serviceOrder.simulacao.vlrEntrada;

        setServiceOrder({
          ...serviceOrder,
          simulacao: {
            ...serviceOrder.simulacao,
            ...simulationResponse,
            vlrEntrada,
          },
        });
      }
    },
    [serviceOrder, setServiceOrder, maxInstallment]
  );

  const handleChangeDeadline = useCallback(
    (e) => {
      const year = parseInt(e.target.value, 10);
      setYearSelected(year);
      handleCalculationSimulation(
        year,
        installment,
        targetLimitValue || targetLimitValue
      );
    },
    [
      setYearSelected,
      handleCalculationSimulation,
      installment,
      targetLimitValue,
    ]
  );

  const handleInstallment = useCallback(
    (e) => {
      setInstallment(parseFloat(e.target.value));
      handleCalculationSimulation(
        yearSelected,
        parseFloat(e.target.value),
        targetLimitValue || targetLimitValue
      );
    },
    [yearSelected, handleCalculationSimulation, targetLimitValue]
  );

  const handleChangeTargetLimitValue = useCallback(
    (e) => {
      const value = parseFloat(e.target.value);
      setTargetLimitValue(value);
      handleCalculationSimulation(yearSelected, installment, value);
    },
    [
      setTargetLimitValue,
      handleCalculationSimulation,
      yearSelected,
      installment,
    ]
  );

  return (
    <>
      {serviceOrder.simulacao && (
        <Container className="px-4 py-5 p-lg-5 h-100 box-gray d-flex flex-column justify-content-around">
          <div>
            <div className="mb-2">
              <h3 className="h5 fw-semibold text-dark-gray">
                Em quanto tempo deseja pagar?
              </h3>
              <p>Quanto maior o prazo, menores são as parcelas</p>
            </div>
            <div>
              <p className="h3 fw-semibold text-dark-gray my-1">
                {yearSelected} anos
              </p>
              <Range
                percent={(yearSelected * 100) / yearsTotal}
                className="w-100"
              >
                <input
                  type="range"
                  min={1}
                  max={yearsTotal}
                  value={yearSelected}
                  className="w-100"
                  onChange={handleChangeDeadline}
                />
              </Range>
            </div>
          </div>
          <div className="mt-5">
            <div className="mb-2">
              <h3 className="h5 fw-semibold text-dark-gray">
                Qual o melhor valor para pagar mensalmente?
              </h3>
              <p>Selecione a parcela que cabe no seu bolso</p>
            </div>
            <div>
              <p className="h3 fw-semibold text-dark-gray my-1">
                {formatPrice(installment)}
              </p>
              <Range
                percent={(installment * 100) / maxInstallment - 0.5}
                className="w-100"
              >
                <input
                  type="range"
                  min={100}
                  max={maxInstallment}
                  step=".01"
                  className="w-100"
                  onChange={handleInstallment}
                  value={installment}
                />
              </Range>
            </div>
          </div>
          {(serviceOrder.finalidade_id === 3 ||
            serviceOrder.finalidade_id === 4) && (
            <div className="mt-5">
              <div className="mb-2">
                <h3 className="h5 fw-semibold text-dark-gray">
                  Quanto você precisa financiar?
                </h3>
                <p>Selecione o valor máximo desejado</p>
              </div>
              <div>
                <p className="h3 fw-semibold text-dark-gray my-1">
                  {formatPrice(targetLimitValue)}
                </p>
                <Range
                  percent={(targetLimitValue * 100) / financedValue}
                  className="w-100"
                >
                  <input
                    type="range"
                    min={1000}
                    max={financedValue}
                    value={targetLimitValue}
                    step="100"
                    className="w-100"
                    onChange={handleChangeTargetLimitValue}
                  />
                </Range>
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default CardRangeValueAndYears;
