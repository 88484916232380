import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}
interface IModal {
  size?: 'md';
}
interface IBoxButtons {
  show?: boolean;
}

export const Container3 = styled.div`
  .user-login {
    display: flex;
    justify-content: end;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
  }
`;
interface IAvatar {
  src: string;
}
interface IModal {
  size?: 'md';
}
interface IBoxButtons {
  show?: boolean;
}

interface IAvatar {
  src: string;
}
export const Container2 = styled.div`
  .notify {
    border-radius: 50%;
    background: #f5f5f5;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 46px;
    height: 46px;

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      border-radius: 24px;
      right: 12px;
      top: 12px;
    }
  }

  .text-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #000000;
  }

  .btn-os {
    border-radius: 26px;
    width: 16px;
    height: 42px;
    color: white;
    background-color: #3aa787;
    :hover {
      background-color: #0a9169;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .espaciado3 {
    width: 240px;
    @media (min-width: 2000px) {
      width: 160px;
    }
  }

  .chat {
    background-color: #f5f5f5;
    border-radius: 50%;
  }

  .agrandar {
    width: 460px;
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    transition: 0.3ms;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .calendar {
    background: #ffffff;
    border-radius: 10px;
  }

  .reminder-task {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 250px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 250px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 250px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 250px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 200px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;

export const Container = styled.div`
  height: 100%;
  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }
  @media (min-width: 700px) {
    width: 85%;
    height: 85%;
  }
  @media (min-width: 3000px) {
    position: absolute;
    left: 30%;
    height: 100%;
  }

  .user-login {
    display: flex;
    justify-content: end;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
  }

  .mb-3 {
    width: 90%;
    justify-content: space-between;

    .h4-lg {
      font-size: 100%;
      width: 50%;
      margin: auto;
      @media (min-width: 700px) {
        font-size: 120%;
      }
      @media (min-width: 1200px) {
        margin-left: -1%;
      }
      @media (min-width: 3000px) {
        font-size: 250%;
        margin-left: 2%;
      }
    }
    #notifycontent {
      margin: auto;
      width: 100%;
      @media (min-width: 500px) {
        width: 100%;
      }
      @media (min-width: 700px) {
        width: 50vh;
      }
      @media (min-width: 1000px) {
        width: 55vh;
      }
      @media (min-width: 1200px) {
        width: 103vh;
      }
      @media (min-width: 1400px) {
        width: 1100px;
      }
      @media (min-width: 3000px) {
        width: 60vh;
      }
    }
    .usercontainer {
      width: 50vw;
      margin-top: 2%;
      justify-content: center;
    }
    h1 {
      font-size: 100px;
      margin: auto;
    }
    #avatar {
      margin: auto;
      @media (min-width: 700px) {
        width: 50%;
        margin: auto;
      }

      div {
        margin-left: 25%;
      }
    }
    .col-lg-6 {
      width: 30%;
      @media (min-width: 700px) {
        width: 30%;
      }
    }
  }
  .col-1 {
    width: 10%;
    margin: auto -6%;
    @media (min-width: 1400px) {
      margin: auto 1%;
    }
  }
  .notify {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 10%;
    left: 120%;
    width: 46px;
    height: 46px;
    @media (min-width: 500px) {
      left: 100%;
    }
    @media (min-width: 700px) {
      top: 8%;
    }
    @media (min-width: 1000px) {
      top: 5%;
      left: 150%;
    }
    @media (min-width: 1200px) {
      top: 8%;
      left: 150%;
    }
    @media (min-width: 1440px) {
      left: 90%;
      top: 55px;
    }
    @media (min-width: 1700px) {
      left: 74%;
      top: 6%;
    }
    @media (min-width: 3000px) {
      left: 99%;
      top: 4%;
    }

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      right: -15px;
      top: 11px;
      @media (min-width: 1440px) {
        right: -2px;
        top: 10px;
      }
    }
  }
  .contenedorCentralBody {
    @media (max-width: 600px) {
      margin-top: 60%;
    }
  }
  .search {
    border: 1px solid #e4e4e4;
    border-radius: 35px;
    height: 56px;
    width: 238%;
    background-color: #ffffff;
    :hover {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    :hover input {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    @media (min-width: 500px) {
      width: 100%;
    }
    @media (min-width: 700px) {
      width: 115%;
    }
    @media (min-width: 1440px) {
      width: 125%;
    }
  }
  #ContainerAvatar {
    z-index: 2;
    margin: auto;
    top: 43%;
    @media (min-width: 500px) {
      top: 31%;
      left: 32%;
    }
    @media (min-width: 700px) {
      width: 30%;
      top: 18%;
      left: 70%;
    }
    @media (min-width: 1000px) {
      top: 13%;
    }
    @media (min-width: 1200px) {
      top: 19%;
    }
    @media (min-width: 1440px) {
      top: 21%;
      width: 22%;
      margin-left: 20%;
      border-radius: 10px;
    }
    @media (min-width: 1700px) {
      left: 55%;
      top: 20%;
    }
    @media (min-width: 3000px) {
      top: 9%;
      left: 75%;
    }
  }

  .contenedorOS-bdi {
    position: absolute;
    width: 30%;
    height: 80px;
    display: flex;
    margin-left: 48%;
    @media (min-width: 1440px) {
      margin-left: 55%;
    }
  }
  .btn-os {
    border-radius: 24px;
    height: 60px;
    margin: auto;
    @media (min-width: 500px) {
      width: 51%;
      margin: auto 1%;
    }
    @media (min-width: 1440px) {
      width: 64%;
      margin-left: 10%;
    }
  }

  .btn-bdi {
    border-radius: 24px;
    background-color: #414d49;
    color: white;
    height: 60px;
    margin: 10% auto;
    @media (min-width: 500px) {
      width: 50%;
      margin: auto;
    }
    @media (min-width: 1440px) {
      width: 64%;
    }
  }

  input {
    color: #414142;
    font-size: 130%;
    @media (min-width: 700px) {
      height: 100%;
    }
    ::placeholder {
      color: #b9b8bc;
    }
  }

  .carousel {
    width: 180%;
    height: 100%;
    margin-top: 30%;
    @media (min-width: 500px) {
      width: 100%;
      margin-top: 3%;
    }
    @media (min-width: 1440px) {
      width: 110%;
    }
    .mt-4 {
      width: 90%;
      height: 25%;
      @media (min-width: 1020px) {
        width: 85%;
        height: 25%;
      }
      .col-lg-5 {
        width: 80%;
        position: absolute;
        top: -4%;
      }
    }
    .col-lg-8 {
      position: absolute;
      top: 35%;
      width: 85%;
    }

    .dollar {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 70%;
      left: 70%;
    }

    background: linear-gradient(
        75.33deg,
        rgba(0, 0, 0, 0.2) -10.41%,
        rgba(0, 0, 0, 0) 62.93%
      ),
      #f4b000;
    height: 354px;
    border-radius: 12px;
  }

  .sun {
    color: #202020;
  }

  .btn-know {
    height: 46px;
    background: #ffffff;
    border-radius: 40px;
  }
  .h-100 {
    width: 180%;
    @media (min-width: 500px) {
      width: 100%;
    }
    @media (min-width: 1000px) {
      width: 100%;
      margin-left: 20%;
    }
  }
  .pres-box {
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    margin: 5% 5%;
    width: 100%;
    height: 200px;
    @media (min-width: 700px) {
      width: 40%;
    }
  }
  .in-box {
    color: #747475;
  }

  .value-box {
    position: relative;
    color: #27262e;

    .red-ball {
      background: #ff1a50;
      border-radius: 6px;
      width: 12px;
      height: 12px;
      position: absolute;
    }
  }
  .tableContent {
    width: 180%;
    @media (min-width: 500px) {
      width: 76%;
    }
    @media (min-width: 1440px) {
      width: 80%;
    }
    .buttonsTable {
      margin: auto;
      margin-left: 15%;
    }
  }
  .inter-box {
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    height: 46px;
  }

  .btn-build {
    background-color: #fcfcfc;
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .tasks {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }

  .tasks {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    transition: 0.3ms;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .calendar {
    background: #ffffff;
    border-radius: 10px;
  }

  .reminder-task {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 160px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 120px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 130px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 100px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 150px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar2 = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
export const Buttons2 = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;
export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  .d-none {
    display: none;
  }

  .modalContent {
    max-width: ${(props) => (props.size === 'md' ? '400px' : '500px')};
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .btn-submit {
    background-color: #39a887;
    color: #ffffff;
    font-weight: 600;
  }

  .link {
    color: #1762e1;
  }
`;
