// Services
import api from '~/services/api';

// Types
import { AuthState } from '../../types';

/**
 * Get and mount the default value
 * @returns A object that represent all data of authentication or a cache from authentication
 */
export function authGetCacheStorage(): AuthState {
  try {
    const authBase: AuthState = {
      api_token: null,
      token: null,
      user: null,
    };

    return authBase;
  } catch (error: any) {
    throw new Error(`Do not possible assing the cache`);
  }
}

export function authSetTokenCacheStorage(authState: AuthState): void {
  try {
    localStorage.setItem('@HarveyClients:token', authState.token || '');

    api.defaults.headers.authorization = `Bearer ${authState.token}`;

    localStorage.setItem(
      '@HarveyClients:auth',
      JSON.stringify(authState || '{}')
    );
  } catch (error: any) {
    throw new Error(`Do not possible assing the cache`);
  }
}
