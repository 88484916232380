import React, { useCallback, useMemo } from 'react';
import Chart from 'react-apexcharts';
import formatCurrency from '~/utils/formatCurrency';

import { Container } from './styles';

interface ISimulation {
  vlrParcInic: number;
}

interface IParams {
  simulation: ISimulation;
}

const PerformanceComparator: React.FC<IParams> = ({ simulation }) => {
  const convertedValue = useCallback((value: number) => {
    const newValue = formatCurrency(
      parseFloat(String(value) === null || undefined ? '0' : String(value))
    );

    return newValue;
  }, []);

  const labels = useMemo(() => {
    const labelsData = [
      'Prestação do financiamento da construção',
      'Quanto custaria a prestação desse imóvel pronto',
      'Quanto custaria o aluguel desse imóvel pronto',
    ];
    return labelsData;
  }, []);

  const options = useMemo(
    () => ({
      options: {
        grid: {
          show: false,
        },
        chart: {
          height: 250,
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        labels,
        colors: ['#8676FF', '#6DFCA6', '#168FFF'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enable: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: undefined,
            formatter: undefined,
          },
          y: {
            show: true,
            formatter(val: number) {
              return `${convertedValue(val)}`;
            },
            title: {
              formatter: () => `Valor:`,
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          data: [
            simulation.vlrParcInic,
            simulation.vlrParcInic * 1.7,
            simulation.vlrParcInic * 1.1,
          ],
        },
      ],
    }),
    [convertedValue, simulation.vlrParcInic]
  );

  return (
    <Container className="container col-md-5 col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h6 mb-0">Comparador de prestação</h2>
      </div>
      <Chart
        options={options.options}
        series={options.series}
        type="bar"
        className="m-5 mb-0"
      />
      <div id="performanceComparator" className="m-5 mb-0" />

      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot me-3" />
            <p className="mb-0 w-75">
              Prestação do financiamento da construção
            </p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrParcInic)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-2 me-3" />
            <p className="mb-0 w-75">
              Quanto custaria a prestação desse imóvel pronto
            </p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrParcInic * 1.7)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-3 me-3" />
            <p className="mb-0 w-75">
              Quanto custaria o aluguel desse imóvel pronto
            </p>
          </div>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrParcInic * 1.1)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PerformanceComparator;
