import styled from 'styled-components';

interface ITitle {
  color: string;
}

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 21px;

  span {
    font-size: 14px;
  }

  .chain {
    background: #b8b9ba;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .chain-top {
    :after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: #b8b9ba;
    }
  }

  .chain-bottom {
    :before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(50% + 5px);
      background: #b8b9ba;
      top: 0;
    }
  }

  .installment {
    width: calc(100% - 25px);
  }
`;

export const Title = styled.h1<ITitle>`
  color: ${(props) => props.color};
`;
