export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatToFloat = (value: string): number => {
  return parseFloat(
    value.replace('R$', '').replaceAll('.', '').replace(',', '.')
  );
};
