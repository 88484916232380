import { createContext } from 'react';

// Middleware
import { IMUser } from '~/models/User';
import { authGetCacheStorage } from '../middleware/cache';

// Types
import { AuthCredentials, AuthState, IAuthDispatch } from '../types';

export const AuthContext = createContext<AuthState>(authGetCacheStorage());

if (AuthContext) AuthContext.displayName = 'Auth@alpha';

export const AuthContextDispatch = createContext<IAuthDispatch>({
  signIn: async ({ email, password }: AuthCredentials) => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  autoSignIn: async (hash = '') => {},
  signOut: () => ({}),
  recovery: async () => false,
  updateUser: (data: Partial<IMUser>) => false,
});

if (AuthContext) AuthContextDispatch.displayName = 'AuthContextDispatch@alpha';
