/* eslint-disable no-case-declarations */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { BsDash, BsPlus } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory, useParams } from 'react-router-dom';
import { formatPrice } from '~/utils/format';

import bathroom from '~/assets/defaults/bathroom.png';
import bedroom from '~/assets/defaults/bedroom.png';
import closet from '~/assets/defaults/closets.png';
import garage from '~/assets/defaults/garage.png';
import gourmetArea from '~/assets/defaults/gourmet-area.png';
import homeOffice from '~/assets/defaults/home-office.png';
import kitchen from '~/assets/defaults/kitchen.png';
import laundryArea from '~/assets/defaults/laundry-area.png';
import livingRoom from '~/assets/defaults/living-room.png';
import pantry from '~/assets/defaults/pantry.png';
import swimmingPool from '~/assets/defaults/swimming-pool.png';
import washroom from '~/assets/defaults/washroom.png';
import Loading from '~/components/Loading';

import largeArea from '~/assets/icons/large-area.svg';
import mediumArea from '~/assets/icons/medium-area.svg';
import noneArea from '~/assets/icons/none-area.svg';
import smallArea from '~/assets/icons/small-area.svg';

import { useOrderServiceBuilder } from '~/hooks/OrderServiceBuilder';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import api from '~/services/api';
import budgetCalculation from '~/utils/budgetCalculation';
import Toast from '~/utils/toast';

import { Bar, ButtonStandard, Container, Img, Step } from './styles';

interface IParams {
  osId: string;
  slug: string;
  option: 'quantidade' | 'tamanho';
}

interface IOption {
  id: number;
  label: string;
  icon: string;
  price: number;
  stateValue: number;
  valueFinishing: number;
  valueProfit: number;
  selected?: boolean;
}

interface IStep {
  id: number;
  type: string;
  photo?: string;
  quantity?: {
    question: string;
    qtd: number;
  };
  size: {
    question: string;
    options: IOption[];
  };
}

interface IAboutYourHouse {
  percent: number;
  totalBudget: number;
  budget: number;
}

interface IEnvironmentResponse {
  id: number;
  ambiente: string;
  icon: string;
  imagem: string;
  margem: number;
  opcional: boolean;
  unico: boolean;
}

interface ISizeResponse {
  id: number;
  tamanho: string;
}

interface IEnvironmentSizeResponse {
  id: number;
  metragem: number;
  ambiente: IEnvironmentResponse;
  tamanho: ISizeResponse;
}

interface IInitialData {
  ambientes_tamanhos: IEnvironmentSizeResponse[];
}

interface IOs {
  simulacao: {
    vlrFinanciado: number;
    vlrTerreno: number;
    vlrEntrada: number;
    vlrFgts: number;
  };
  terreno: {
    estado: {
      valor: number;
    };
  };
}

interface IParameterResponse {
  acabamento: {
    margem: number;
  };
  eletrica: {
    margem: number;
  };
  estilo_construcao: {
    margem: number;
  };
  hidraulica: {
    margem: number;
  };
  terreno: {
    margem: number;
  };
  margem: number;
}

interface IEnvironmentResponseAxios {
  id: number;
  amb_tam_id: number;
  item: number;
  metragem: number;
  vlrEstado: number;
  vlrAcabamento: number;
  vlrLucro: number;
  vlrFinal: number;
  ambiente_tamanho: {
    ambiente_id: number;
    ambiente: {
      ambiente: string;
      margem: number;
    };
  };
}

interface IEnvironment {
  id?: number;
  type?: string;
  selected?: boolean;
  amb_tam_id?: number;
  ambiente_tamanho?: {
    ambiente_id: number;
  };
  ambiente_id?: number;
  item?: number;
  metragem?: number;
  vlrEstado?: number;
  vlrAcabamento?: number;
  vlrLucro?: number;
  vlrFinal?: number;
}

interface IParameter {
  margem: number;
}

const AboutYourHouse: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { serviceOrder, setServiceOrder, recoveryServiceOrderData } =
    useServiceOrder();
  const { orderServiceBuilder } = useOrderServiceBuilder();
  const [buttonStatus, setButtonStatus] = useState(true);
  const [completedStatus] = useState(true);
  const [steps, setSteps] = useState<IStep[]>([]);
  const [step, setStep] = useState({} as IStep);
  const [budget, setBudget] = useState(0);
  const [about, setAbout] = useState({} as IAboutYourHouse);
  const [optionSelected, setOptionSelected] = useState<'size' | 'quantity'>(
    'quantity'
  );
  const [parameter, setParameter] = useState({} as IParameterResponse);
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [convenient, setConvenient] = useState('Quartos');
  const [scrollPosition, setScrollPosition] = useState(0);

  const alignScrollMenu = useCallback((position: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('box-steps')!.scrollLeft = position;
  }, []);

  useEffect(() => {
    alignScrollMenu(scrollPosition);
  }, [alignScrollMenu, scrollPosition]);

  useEffect(() => {
    if (params.osId && !serviceOrder?.key) {
      recoveryServiceOrderData(params.osId).then((_resolver) =>
        setLoading(false)
      );
    } else if (!params.osId || !serviceOrder?.key) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.osId]);

  useEffect(() => {
    api
      .get<IInitialData>('queries/inicial_data', {
        baseURL: `${process.env.REACT_APP_API_URL}`,
      })
      .then(async (response) => {
        const responseOs = await api.get<IOs>(`service-orders/${params.osId}`);

        const responseParameters = await api.get<IParameterResponse>(
          `service-orders/${serviceOrder.id}/budget/parameters`
        );

        const responseEnvironments = await api.get<IEnvironmentResponseAxios[]>(
          `service-orders/${serviceOrder.id}/budget/environments`
        );

        let lastEnvironmentType = '';
        const environmentsData: IEnvironment[] = responseEnvironments.data.map(
          (environment) => {
            const type = environment.ambiente_tamanho.ambiente.ambiente
              .toLowerCase()
              .replace(/[àáâãäå]/g, 'a')
              .replace(/æ/g, 'ae')
              .replace(/ç/g, 'c')
              .replace(/[èéêë]/g, 'e')
              .replace(/[ìíîï]/g, 'i')
              .replace(/ñ/g, 'n')
              .replace(/[òóôõö]/g, 'o')
              .replace(/œ/g, 'oe')
              .replace(/[ùúûü]/g, 'u')
              .replace(/[ýÿ]/g, 'y')
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/ /g, '-');

            const data = {
              id: environment.id,
              type,
              amb_tam_id: environment.amb_tam_id,
              ambiente_tamanho: {
                ambiente_id: environment.ambiente_tamanho.ambiente_id,
              },
              ambiente_id: environment.ambiente_tamanho.ambiente_id,
              item: environment.item,
              metragem: environment.metragem,
              vlrEstado: environment.vlrEstado,
              vlrAcabamento: environment.vlrAcabamento,
              vlrLucro: environment.vlrLucro,
              vlrFinal: environment.vlrFinal,
              selected: lastEnvironmentType !== type,
            };

            lastEnvironmentType = type;

            return data;
          }
        );

        const data: IStep[] = [];

        response.data.ambientes_tamanhos.forEach((environmentSize, index) => {
          const type = environmentSize.ambiente.ambiente
            .toLowerCase()
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/ /g, '-');

          let questionQuantity = '';
          let questionSize = '';
          let photo = '';
          switch (type) {
            case 'quarto':
              questionQuantity = 'Quantos quartos você deseja?';
              questionSize = 'Selecione o tamanho do quarto';
              photo = bedroom;
              break;
            case 'banheiro':
              questionQuantity = 'Quantos banheiros você deseja?';
              questionSize = 'Selecione o tamanho do banheiro';
              photo = bathroom;
              break;
            case 'closet':
              questionQuantity = 'Quantos closet você deseja?';
              questionSize = 'Selecione o tamanho do closet';
              photo = closet;
              break;
            case 'cozinha':
              questionSize = 'Selecione o tamanho da cozinha';
              photo = kitchen;
              break;
            case 'area-gourmet':
              questionSize = 'Selecione o tamanho da área gourmet';
              photo = gourmetArea;
              break;
            case 'area-de-servico':
              questionSize = 'Selecione o tamanho da área de serviço';
              photo = laundryArea;
              break;
            case 'lavabo':
              questionSize = 'Selecione o tamanho do lavabo';
              photo = washroom;
              break;
            case 'despensa':
              questionSize = 'Selecione o tamanho da despensa';
              photo = pantry;
              break;
            case 'piscina':
              questionSize = 'Selecione o tamanho da piscina';
              photo = swimmingPool;
              break;
            case 'garagem':
              questionSize = 'Selecione o tamanho da garagem';
              photo = garage;
              break;
            case 'sala':
              questionSize = 'Selecione o tamanho da sala';
              photo = livingRoom;
              break;
            default:
              questionSize = 'Selecione o tamanho do escritório';
              photo = homeOffice;
          }

          let icon = noneArea;
          if (environmentSize.tamanho.tamanho === 'Grande') {
            icon = largeArea;
          } else if (environmentSize.tamanho.tamanho === 'Médio') {
            icon = mediumArea;
          } else if (environmentSize.tamanho.tamanho === 'Pequeno') {
            icon = smallArea;
          }

          const finishingMargin = responseParameters.data.acabamento.margem;

          const stateValue =
            responseOs.data.terreno.estado.valor *
            environmentSize.ambiente.margem;
          const valueFinishing = stateValue * finishingMargin;
          const valueProfit =
            valueFinishing * (1 + responseParameters.data.margem / 100);

          const stepIndex = data.findIndex(
            (stepData) => stepData.type === type
          );

          if (stepIndex >= 0) {
            const newOption = {
              id: environmentSize.id,
              icon,
              label: `${environmentSize.metragem}m²`,
              price: valueProfit * environmentSize.metragem,
              stateValue,
              valueFinishing,
              valueProfit,
              selected: !!environmentsData.find(
                (environment) =>
                  environment.type === type &&
                  environment.metragem === environmentSize.metragem &&
                  environment.selected
              ),
            };
            data[stepIndex].size.options.push(newOption);

            if (response.data.ambientes_tamanhos.length === index + 1) {
              if (response.data.ambientes_tamanhos[index]) {
                if (
                  response.data.ambientes_tamanhos[index].ambiente.opcional &&
                  response.data.ambientes_tamanhos[index].ambiente.unico
                ) {
                  const typeCheck = response.data.ambientes_tamanhos[
                    index
                  ].ambiente.ambiente
                    .toLowerCase()
                    .replace(/[àáâãäå]/g, 'a')
                    .replace(/æ/g, 'ae')
                    .replace(/ç/g, 'c')
                    .replace(/[èéêë]/g, 'e')
                    .replace(/[ìíîï]/g, 'i')
                    .replace(/ñ/g, 'n')
                    .replace(/[òóôõö]/g, 'o')
                    .replace(/œ/g, 'oe')
                    .replace(/[ùúûü]/g, 'u')
                    .replace(/[ýÿ]/g, 'y')
                    .replace(/[^a-zA-Z0-9 -]/g, '')
                    .replace(/ /g, '-');

                  const checkOption = environmentsData.find(
                    (environment) =>
                      environment.type === typeCheck && environment.selected
                  );

                  const newOptionData = {
                    id: 0,
                    icon: noneArea,
                    label: `Nenhum`,
                    price: 0,
                    stateValue,
                    valueFinishing,
                    valueProfit,
                    selected: environmentsData.length > 0 && !checkOption,
                  };

                  const stepIdx = data.findIndex(
                    (stepData) =>
                      stepData.id ===
                      response.data.ambientes_tamanhos[index].ambiente.id
                  );
                  if (stepIdx >= 0) {
                    data[stepIdx].size.options.push(newOptionData);
                  }
                }
              }
            }
          } else {
            const newStep: IStep = {
              id: environmentSize.ambiente.id,
              type,
              photo,
              quantity: environmentSize.ambiente.unico
                ? undefined
                : {
                    question: questionQuantity,
                    qtd: environmentsData.filter(
                      (environment) => environment.type === type
                    ).length,
                  },
              size: {
                question: questionSize,
                options: [
                  {
                    id: environmentSize.id,
                    icon,
                    label: `${environmentSize.metragem}m²`,
                    price: valueProfit * environmentSize.metragem,
                    stateValue,
                    valueFinishing,
                    valueProfit,
                    selected: !!environmentsData.find(
                      (environment) =>
                        environment.type === type &&
                        environment.metragem === environmentSize.metragem &&
                        environment.selected
                    ),
                  },
                ],
              },
            };

            data.push(newStep);

            if (response.data.ambientes_tamanhos[index - 1]) {
              if (
                response.data.ambientes_tamanhos[index - 1].ambiente.opcional &&
                response.data.ambientes_tamanhos[index - 1].ambiente.unico
              ) {
                const typeCheck = response.data.ambientes_tamanhos[
                  index - 1
                ].ambiente.ambiente
                  .toLowerCase()
                  .replace(/[àáâãäå]/g, 'a')
                  .replace(/æ/g, 'ae')
                  .replace(/ç/g, 'c')
                  .replace(/[èéêë]/g, 'e')
                  .replace(/[ìíîï]/g, 'i')
                  .replace(/ñ/g, 'n')
                  .replace(/[òóôõö]/g, 'o')
                  .replace(/œ/g, 'oe')
                  .replace(/[ùúûü]/g, 'u')
                  .replace(/[ýÿ]/g, 'y')
                  .replace(/[^a-zA-Z0-9 -]/g, '')
                  .replace(/ /g, '-');

                const checkOption = environmentsData.find(
                  (environment) =>
                    environment.type === typeCheck && environment.selected
                );

                const newOption = {
                  id: 0,
                  icon: noneArea,
                  label: `Nenhum`,
                  price: 0,
                  stateValue,
                  valueFinishing,
                  valueProfit,
                  selected: environmentsData.length > 0 && !checkOption,
                };

                const stepIdx = data.findIndex(
                  (stepData) =>
                    stepData.id ===
                    response.data.ambientes_tamanhos[index - 1].ambiente.id
                );
                if (stepIdx >= 0) {
                  data[stepIdx].size.options.push(newOption);
                }
              }
            }
          }
        });

        setParameter(responseParameters.data);
        setEnvironments(environmentsData);
        setBudget(
          responseOs.data.simulacao.vlrFinanciado +
            responseOs.data.simulacao.vlrFgts +
            responseOs.data.simulacao.vlrEntrada -
            responseOs.data.simulacao.vlrTerreno
        );
        setSteps(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.osId]);

  useEffect(() => {
    if (environments.length === 0 && params.slug === 'quarto') {
      history.push(
        `/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/quantidade`
      );
    }
  }, [environments.length, history, params.osId, params.slug]);

  useEffect(() => {
    let option = 'quantity';
    if (params.option === 'tamanho') {
      option = 'size';
    }
    setButtonStatus(true);
    setOptionSelected(option as 'size' | 'quantity');
  }, [params.option]);

  useEffect(() => {
    let stepSelected = steps.find(
      (stepData) => params.slug && stepData.type === params.slug
    );

    if (!stepSelected) {
      // eslint-disable-next-line prefer-destructuring
      stepSelected = steps[0];
    }

    setStep(stepSelected);
  }, [params.slug, steps]);

  useEffect(() => {
    const parameters: IParameter[] = [];

    Object.values(parameter).forEach((parameterData) => {
      if (parameterData && typeof parameterData === 'object') {
        parameters.push(parameterData);
      }
    });

    const totalBudget = budgetCalculation({
      environments,
      parameters,
    });

    setAbout({
      percent: parseFloat(((totalBudget * 100) / budget).toFixed(2)),
      totalBudget,
      budget,
    });
  }, [budget, environments, parameter]);

  useEffect(() => {
    if (step && Object.keys(step).length > 0) {
      const checkEnvironment = environments.find(
        (environmentData) => environmentData.type === step.type
      );
      if (!checkEnvironment) {
        if (!step.quantity) {
          const newEnvironment: IEnvironment = {
            type: step.type,
          };

          setEnvironments((state) => [...state, newEnvironment]);
        }
      }
    }
  }, [environments, params.slug, step]);

  const handleChangeEnviroment = useCallback(async () => {
    if (step && Object.keys(step).length > 0) {
      const newEnvironments = environments.slice();

      const environmentsQtd = newEnvironments.filter(
        (environmentData) => environmentData.type === step.type
      ).length;

      if (step.quantity) {
        if (step.quantity.qtd > environmentsQtd) {
          const diff = step.quantity.qtd - environmentsQtd;
          for (let i = 0; i < diff; i += 1) {
            const data: IEnvironment = {
              type: step.type,
            };
            newEnvironments.push(data);
          }
        } else if (step.quantity.qtd < environmentsQtd) {
          const diff = environmentsQtd - step.quantity.qtd;

          for (let i = 0; i < diff; i += 1) {
            const environmentIndex = newEnvironments.findIndex(
              (environmentData) =>
                environmentData.type === step.type && !environmentData.metragem
            );
            if (environmentIndex >= 0) {
              newEnvironments.splice(environmentIndex, 1);
            } else {
              newEnvironments.splice(newEnvironments.length - 1, 1);
            }
          }
        }

        const newEnvironmentsCurrentStep = newEnvironments.filter(
          (environmentData) => environmentData.type === step.type
        );

        const newEnvironmentsOthersSteps = newEnvironments.filter(
          (environmentData) => environmentData.type !== step.type
        );

        newEnvironmentsCurrentStep[0].selected = true;
        setEnvironments([
          ...newEnvironmentsCurrentStep,
          ...newEnvironmentsOthersSteps,
        ]);
      }
    }
  }, [environments, step]);

  const handleClickOpiton = useCallback(
    (option: IOption) => {
      setButtonStatus(false);
      const newOptions = step.size.options.map((optionData) => {
        if (optionData.id === option.id) {
          return {
            ...optionData,
            selected: true,
          };
        }
        return {
          ...optionData,
          selected: false,
        };
      });

      const newStep = { ...step };
      newStep.size.options = newOptions;

      const newEnvironments = environments.slice();

      if (
        step.type === 'quarto' ||
        step.type === 'banheiro' ||
        step.type === 'closet'
      ) {
        const environmentIndex = newEnvironments.findIndex(
          (environmentData) =>
            environmentData.type === step.type && environmentData.selected
        );

        if (environmentIndex >= 0) {
          newEnvironments[environmentIndex].amb_tam_id = option.id;
          newEnvironments[environmentIndex].ambiente_id = step.id;
          newEnvironments[environmentIndex].metragem =
            option.label === 'Nenhum'
              ? 0
              : parseInt(option.label.replace('m²', ''), 10);
          newEnvironments[environmentIndex].ambiente_tamanho = {
            ambiente_id: step.id,
          };
          newEnvironments[environmentIndex].vlrEstado = option.stateValue;
          newEnvironments[environmentIndex].vlrAcabamento =
            option.valueFinishing;
          newEnvironments[environmentIndex].vlrFinal = option.price;
          newEnvironments[environmentIndex].vlrLucro = option.valueProfit;
        }
      } else {
        const environmentIndex = newEnvironments.findIndex(
          (environmentData) => environmentData.type === step.type
        );
        if (environmentIndex >= 0) {
          newEnvironments[environmentIndex].amb_tam_id = option.id;
          newEnvironments[environmentIndex].ambiente_id = step.id;
          newEnvironments[environmentIndex].metragem =
            option.label === 'Nenhum'
              ? 0
              : parseInt(option.label.replace('m²', ''), 10);
          newEnvironments[environmentIndex].ambiente_tamanho = {
            ambiente_id: step.id,
          };
          newEnvironments[environmentIndex].vlrEstado = option.stateValue;
          newEnvironments[environmentIndex].vlrAcabamento =
            option.valueFinishing;
          newEnvironments[environmentIndex].vlrFinal = option.price;
          newEnvironments[environmentIndex].vlrLucro = option.valueProfit;
        }
      }

      const newSteps = steps.slice();
      const stepIndex = newSteps.findIndex(
        (stepData) => stepData.id === newStep.id
      );

      if (stepIndex >= 0) {
        newSteps[stepIndex] = newStep;
      }
      setEnvironments(newEnvironments);
      setSteps(newSteps);
      setStep(newStep);
    },
    [environments, step, steps]
  );

  const handleSetAddScroll = useCallback((value: number) => {
    setScrollPosition(value + 35);
  }, []);

  const handleSetSubScroll = useCallback((value: number) => {
    setScrollPosition(value - 35);
  }, []);

  const handleClickNext = useCallback(() => {
    setError('');
    if (params.option === 'quantidade') {
      switch (params.slug) {
        case 'quarto':
          if (step.quantity && step.quantity.qtd > 0) {
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/tamanho`
            );
            setScrollPosition(0);
          } else {
            setError('É preciso ter ao menos 1 quarto');
          }
          break;
        case 'banheiro':
          if (step.quantity && step.quantity.qtd > 0) {
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/banheiro/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('É preciso ter ao menos 1 banheiro');
          }
          break;
        default:
          if (step.quantity && step.quantity.qtd > 0) {
            setConvenient('Closets');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setConvenient('Cozinha');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/cozinha/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          }
      }
    } else {
      const optionCheck = step.size.options.find((option) => option.selected);
      switch (params.slug) {
        case 'quarto':
          const checkRooms = environments.filter(
            (environmentData) =>
              environmentData.type === step.type && !environmentData.metragem
          );
          if (checkRooms.length === 0) {
            setConvenient('Banheiros');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/banheiro/quantidade`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('As metragens de todos os quartos deve ser preenchida');
          }
          break;
        case 'banheiro':
          const checkBathrooms = environments.filter(
            (environmentData) =>
              environmentData.type === step.type && !environmentData.metragem
          );
          if (checkBathrooms.length === 0) {
            setConvenient('Closets');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/quantidade`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('As metragens de todos os banheiros deve ser preenchida');
          }
          break;
        case 'closet':
          const checkClosets = environments.filter(
            (environmentData) =>
              environmentData.type === step.type && !environmentData.metragem
          );
          if (checkClosets.length === 0) {
            setConvenient('Cozinha');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/cozinha/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('As metragens de todos os closets deve ser preenchida');
          }
          break;
        case 'cozinha':
          if (optionCheck) {
            setConvenient('A.gourmet');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/area-gourmet/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        case 'area-gourmet':
          if (optionCheck) {
            setConvenient('A.serviço');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/area-de-servico/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        case 'area-de-servico':
          if (optionCheck) {
            setConvenient('Lavabo');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/lavabo/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        case 'lavabo':
          if (optionCheck) {
            setConvenient('Despensa');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/despensa/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        case 'despensa':
          if (optionCheck) {
            setConvenient('Piscina');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/piscina/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }

          break;
        case 'piscina':
          if (optionCheck) {
            setConvenient('Garagem');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/garagem/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        case 'garagem':
          if (optionCheck) {
            setConvenient('Sala');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/sala/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
          break;
        default:
          if (optionCheck) {
            setConvenient('Escritório');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/escritorio/tamanho`
            );
            handleSetAddScroll(scrollPosition);
          } else {
            setError('Uma das metragens deve ser selecionada');
          }
      }
    }
  }, [
    environments,
    history,
    params.option,
    params.osId,
    params.slug,
    step,
    scrollPosition,
    handleSetAddScroll,
  ]);

  const handleClickSubtract = useCallback(() => {
    if (step.quantity && step.quantity.qtd > 1) {
      const newSteps = steps.slice();
      const stepIndex = newSteps.findIndex(
        (stepData) => stepData.id === step.id
      );
      if (stepIndex >= 0) {
        const { quantity } = newSteps[stepIndex];
        if (quantity) {
          quantity.qtd -= 1;
        }
        setStep(newSteps[stepIndex]);
        setSteps(newSteps);
        handleChangeEnviroment();
      }
    }
  }, [handleChangeEnviroment, step, steps]);

  const handleClickPlus = useCallback(() => {
    setButtonStatus(false);
    const newSteps = steps.slice();
    const stepIndex = newSteps.findIndex((stepData) => stepData.id === step.id);
    if (stepIndex >= 0) {
      const { quantity } = newSteps[stepIndex];
      if (quantity) {
        quantity.qtd += 1;
      }
      setStep(newSteps[stepIndex]);
      setSteps(newSteps);
      handleChangeEnviroment();
    }
  }, [handleChangeEnviroment, step, steps]);

  const handleClickPrevious = useCallback(() => {
    setButtonStatus(true);
    setError('');
    if (params.option === 'tamanho' && step.quantity) {
      switch (params.slug) {
        case 'closet':
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/quantidade`
          );

          break;
        case 'banheiro':
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/banheiro/quantidade`
          );

          break;
        default:
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/quantidade`
          );
      }
    } else {
      switch (params.slug) {
        case 'escritorio':
          setConvenient('Sala');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/sala/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'sala':
          setConvenient('Garagem');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/garagem/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'garagem':
          setConvenient('Piscina');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/piscina/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'piscina':
          setConvenient('Despensa');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/despensa/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'despensa':
          setConvenient('Lavabo');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/lavabo/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'lavabo':
          setConvenient('A.serviço');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/area-de-servico/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'area-de-servico':
          setConvenient('A.gourmet');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/area-gourmet/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'area-gourmet':
          setConvenient('Cozinha');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/cozinha/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        case 'cozinha':
          const checkClosets = environments.find(
            (environment) => environment.type === 'closet'
          );
          if (checkClosets) {
            setConvenient('Closets');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/tamanho`
            );
            handleSetSubScroll(scrollPosition);
          } else {
            setConvenient('Closets');
            history.push(
              `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/quantidade`
            );
            handleSetSubScroll(scrollPosition);
          }
          break;
        case 'closet':
          setConvenient('Banheiros');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/banheiro/tamanho`
          );
          handleSetSubScroll(scrollPosition);
          break;
        default:
          setConvenient('Quartos');
          history.push(
            `/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/tamanho`
          );
          setScrollPosition(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.option,
    params.slug,
    step,
    history,
    environments,
    handleSetSubScroll,
  ]);

  const handleClickQuartos = useCallback(() => {
    setConvenient('Quartos');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/quarto/tamanho`);
    setScrollPosition(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickBanheiro = useCallback(() => {
    setConvenient('Banheiros');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/banheiro/quantidade`
    );
    setScrollPosition(20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickCloset = useCallback(() => {
    setConvenient('Closets');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/closet/quantidade`
    );
    setScrollPosition(66);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickCozinha = useCallback(() => {
    setConvenient('Cozinha');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/cozinha/tamanho`);
    setScrollPosition(120);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickGourmet = useCallback(() => {
    setConvenient('A.gourmet');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/area-gourmet/tamanho`
    );
    setScrollPosition(140);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickServicioArea = useCallback(() => {
    setConvenient('A.serviço');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/area-de-servico/tamanho`
    );
    setScrollPosition(165);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickLavabo = useCallback(() => {
    setConvenient('Lavabo');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/lavabo/tamanho`);
    setScrollPosition(198);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickDespensa = useCallback(() => {
    setConvenient('Despensa');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/despensa/tamanho`
    );
    setScrollPosition(264);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickPiscina = useCallback(() => {
    setConvenient('Piscina');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/piscina/tamanho`);
    setScrollPosition(280);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickGaragem = useCallback(() => {
    setConvenient('Garagem');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/garagem/tamanho`);
    setScrollPosition(320);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickSala = useCallback(() => {
    setConvenient('Sala');
    history.push(`/monte-sua-casa/${params.osId}/sobre-a-casa/sala/tamanho`);
    setScrollPosition(380);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickEscritorio = useCallback(() => {
    setConvenient('Escritório');
    history.push(
      `/monte-sua-casa/${params.osId}/sobre-a-casa/escritorio/tamanho`
    );
    setScrollPosition(400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleChangeQuantity = useCallback(
    (e) => {
      let quantityValue = parseInt(e.target.value, 10);
      if (quantityValue < 0) {
        quantityValue *= -1;
      }
      const newSteps = steps.slice();
      const stepIndex = newSteps.findIndex(
        (stepData) => stepData.id === step.id
      );
      if (e.target.value === '') {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
      if (stepIndex >= 0) {
        const { quantity } = newSteps[stepIndex];
        if (quantity) {
          quantity.qtd = quantityValue;
        }
        setStep(newSteps[stepIndex]);
        setSteps(newSteps);
        handleChangeEnviroment();
      }
    },
    [handleChangeEnviroment, step, steps]
  );
  const handleClickRoom = useCallback(
    (environmentsCurrentStepIndex) => {
      const newEnvironments = environments.slice();
      const environmentsCurrentStep = newEnvironments.filter(
        (environmentData) => environmentData.type === step.type
      );
      const environmentsOthersSteps = newEnvironments.filter(
        (environmentData) => environmentData.type !== step.type
      );
      const newEnvironmentsCurrentStep = environmentsCurrentStep.map(
        (environmentData, index) => {
          if (index === environmentsCurrentStepIndex) {
            return { ...environmentData, selected: true };
          }

          return { ...environmentData, selected: false };
        }
      );

      const newStep = { ...step };
      const newOptions = newStep.size.options.map((option) => {
        if (newEnvironmentsCurrentStep[environmentsCurrentStepIndex].metragem) {
          const label = `${newEnvironmentsCurrentStep[environmentsCurrentStepIndex].metragem}m²`;
          if (option.label === label) {
            return { ...option, selected: true };
          }
          return { ...option, selected: false };
        }
        return { ...option, selected: false };
      });

      newStep.size.options = newOptions;

      setStep(newStep);
      setEnvironments([
        ...newEnvironmentsCurrentStep,
        ...environmentsOthersSteps,
      ]);
    },
    [environments, step]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const ambientes = environments
        .filter((environment) => !!environment.amb_tam_id)
        .map((environment, index) => ({
          ...environment,
          item: index + 1,
        }));

      const budgetRoom = await api.post<IMBudgetRoom[]>(
        `/service-orders/${serviceOrder.id}/budget/environments`,
        {
          ambientes,
        }
      );

      setServiceOrder({
        ...serviceOrder,
        orc_ambientes: budgetRoom.data,
      });
      if (orderServiceBuilder?.builder_id) {
        const formData = {
          builder_id: orderServiceBuilder?.builder_id,
          os_id: params.osId,
          status_id: 1,
          answered: 0,
        };
        api.post(`/requests`, formData).then(() => {
          localStorage.removeItem('@HarveyClient:OSBuilder');
        });
      }
      Toast.fire({
        icon: 'success',
        title: 'Ambientes salvos!',
      });

      history.push(`/monte-sua-casa/${params.osId}`);
    } finally {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environments, history, params.osId]);

  return (
    <Container buttonStatus>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex align-items-center">
              <Link to={`/monte-sua-casa/${params.osId}`}>
                <RiArrowLeftSLine size={28} color="#B8B9BA" />
              </Link>
              <h1 className="h4 mb-0 ms-3 fw-semibold text-dark-gray">
                Monte sua casa
              </h1>
            </div>
          </div>
          <div className="col-lg-2 part">
            <span>
              Parte
              {step && Object.keys(step).length > 0
                ? step.id.toString().padStart(2, '0')
                : '01'}
              /11
            </span>

            <div className="dropdown-div">
              <p className="roon-green fw-medium">{convenient}</p>
              <div className="finishing-green fw-semibold">
                <NavDropdown
                  title={<MdKeyboardArrowDown size={40} />}
                  id="nav-dropdown"
                >
                  <br />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        className="w-100 text-center"
                        active={!!params.slug.match('quarto')}
                        onClick={handleClickQuartos}
                      >
                        Quartos
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="4.3">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('banheiro')}
                        onClick={handleClickBanheiro}
                      >
                        Banheiros
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.3">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3 fondo"
                        active={!!params.slug.match('closet')}
                        onClick={handleClickCloset}
                      >
                        Closets
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.3">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('cozinha')}
                        onClick={handleClickCozinha}
                      >
                        Cozinha
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('area-gourmet')}
                        onClick={handleClickGourmet}
                      >
                        A. gourmet
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('area-de-servico')}
                        onClick={handleClickServicioArea}
                      >
                        A. serviço
                      </Step>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('lavabo')}
                        onClick={handleClickLavabo}
                      >
                        Lavabo
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('despensa')}
                        onClick={handleClickDespensa}
                      >
                        Despensa
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('piscina')}
                        onClick={handleClickPiscina}
                      >
                        Piscina
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('garagem')}
                        onClick={handleClickGaragem}
                      >
                        Garagem
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center px-3"
                        active={!!params.slug.match('sala')}
                        onClick={handleClickSala}
                      >
                        Sala
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        style={{ cursor: 'pointer' }}
                        className="w-100 text-center ps-3"
                        active={!!params.slug.match('escritorio')}
                        onClick={handleClickEscritorio}
                      >
                        Escritório
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>

        <hr className="mb-0 px-0" />
        <div className="row mb-3 px-3 p-lg-5">
          <div id="box-steps" className="col-12 box-steps">
            <div className="d-flex steps">
              <div className="border-steps pe-3">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center "
                  active={!!params.slug.match('quarto')}
                  onClick={handleClickQuartos}
                >
                  Quartos
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('banheiro')}
                  onClick={handleClickBanheiro}
                >
                  Banheiros
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('closet')}
                  onClick={handleClickCloset}
                >
                  Closets
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('cozinha')}
                  onClick={handleClickCozinha}
                >
                  Cozinha
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('area-gourmet')}
                  onClick={handleClickGourmet}
                >
                  A. gourmet
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('area-de-servico')}
                  onClick={handleClickServicioArea}
                >
                  A. serviço
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('lavabo')}
                  onClick={handleClickLavabo}
                >
                  Lavabo
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('despensa')}
                  onClick={handleClickDespensa}
                >
                  Despensa
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('piscina')}
                  onClick={handleClickPiscina}
                >
                  Piscina
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('garagem')}
                  onClick={handleClickGaragem}
                >
                  Garagem
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center px-3"
                  active={!!params.slug.match('sala')}
                  onClick={handleClickSala}
                >
                  Sala
                </Step>
              </div>
              <div className="border-steps">
                <Step
                  style={{ cursor: 'pointer' }}
                  className="w-100 text-center ps-3"
                  active={!!params.slug.match('escritorio')}
                  onClick={handleClickEscritorio}
                >
                  Escritório
                </Step>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className="row d-flex align-items-center"
              style={{
                marginTop: '40px',
              }}
            >
              {step && Object.keys(step).length > 0 && (
                <>
                  <div className="col-lg-6 me-0">
                    <Img src={step.photo as string} className="mx-auto" />
                  </div>
                  <div className="col-lg-6 mb-3 ps-lg-0 position-relative">
                    {!!step.quantity && optionSelected === 'size' && (
                      <div className="d-flex flex-wrap mb-3 rooms">
                        {environments
                          .filter(
                            (environmentData) =>
                              environmentData.type === step.type
                          )
                          .map((environmentData, index) => (
                            <Fragment key={index.toString()}>
                              <div>
                                <button
                                  type="button"
                                  className={`btn-sites ${
                                    environmentData.selected ? 'active' : ''
                                  }`}
                                  onClick={() => handleClickRoom(index)}
                                >
                                  {environmentData.type &&
                                    environmentData.type
                                      .charAt(0)
                                      .toUpperCase() +
                                      environmentData.type.slice(1)}{' '}
                                  {(index + 1).toString().padStart(2, '0')}
                                </button>
                              </div>
                              <span className="d-block mx-2">/</span>
                            </Fragment>
                          ))}
                      </div>
                    )}
                    <h2 className="h6 fw-semibold text-align-center mt-5 mt-lg-0">
                      {step[optionSelected]?.question}
                    </h2>
                    {optionSelected === 'quantity' && (
                      <div className="row mt-5 justify-content-between border-bottom pb-2">
                        <div className="col-3 col-lg-1">
                          <button
                            type="button"
                            className="border-0 bg-transparent mx-auto mx-lg-0 d-block"
                            onClick={handleClickSubtract}
                          >
                            <BsDash size={24} color="#39A887" />
                          </button>
                        </div>
                        <div className="col-3 col-lg-8 counter">
                          <input
                            type="number"
                            placeholder="Insira"
                            min={0}
                            className="insert w-100 text-center border-0 fondo"
                            value={step.quantity?.qtd || ''}
                            onChange={handleChangeQuantity}
                          />
                        </div>
                        <div className="col-3 col-lg-1">
                          <button
                            type="button"
                            className="border-0 bg-transparent mx-auto d-block"
                            onClick={handleClickPlus}
                          >
                            <BsPlus size={24} color="#39A887" />
                          </button>
                        </div>
                      </div>
                    )}
                    {optionSelected === 'size' && (
                      <div className="row align-items-center justify-content-center justify-content-lg-start mt-4">
                        {step.size.options.map((option) => (
                          <div key={option.id} className="col-3 col-sm-3">
                            <ButtonStandard
                              type="button"
                              className="d-flex flex-column justify-content-center align-items-center mx-auto mb-3 mb-sm-0"
                              selected={option.selected}
                              onClick={() => handleClickOpiton(option)}
                            >
                              <img src={option.icon} alt={option.label} />
                              <span>{option.label}</span>
                            </ButtonStandard>
                          </div>
                        ))}
                      </div>
                    )}
                    {error && (
                      <span className="error small text-end position-absolute">
                        {error}
                      </span>
                    )}
                  </div>
                </>
              )}
              {Object.keys(about).length > 0 && (
                <>
                  <div className="col-12 mt-5">
                    <Bar
                      percent={Number.isNaN(about.percent) ? 0 : about.percent}
                    >
                      <div>
                        <p className="mb-0">
                          {Number.isNaN(about.percent) ? 0 : about.percent}%
                        </p>
                      </div>
                    </Bar>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap mt-2">
                    <p className="small normal-lg w-100 w-lg-auto mb-0">
                      Total atingido no orçamento
                    </p>
                    <p className="text-light-gray small mb-0">
                      {formatPrice(about.totalBudget)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-lg-end">
                    <p className="text-primary fw-semibold small normal-lg">
                      Total disponível para construção:{' '}
                      {formatPrice(about.budget)}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="espacio col-sm-8 col-lg-4 d-flex justify-content-center">
            {(params.option !== 'quantidade' || params.slug !== 'quarto') && (
              <button
                type="button"
                className="btn py-2 me-3 me-lg-0"
                style={{
                  backgroundColor:
                    buttonStatus && completedStatus ? '#39A887' : '#39A887',
                  borderRadius: '20px',
                  width: '62px',
                  height: '54px',
                  marginLeft: '-40px',
                }}
                onClick={handleClickPrevious}
              >
                <RiArrowLeftSLine
                  size={24}
                  color={buttonStatus && completedStatus ? 'white' : 'white'}
                />
              </button>
            )}
            {params.slug === 'escritorio' ? (
              <button
                type="button"
                className="btn btn-primary  justify-content-end"
                id="btn-next-step"
                onClick={handleSubmit}
                style={{
                  backgroundColor: '#39A887',
                  borderRadius: '20px',
                  width: '331px',
                  height: '54px',
                  marginLeft: '10px',
                }}
              >
                <span className="tooltiptext">Preencha {params.option}</span>
                <b
                  style={{
                    color: buttonStatus && completedStatus ? 'white' : 'white',
                  }}
                >
                  Próximo
                </b>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary w-75 py-2 ms-lg-3"
                onClick={handleClickNext}
                style={{
                  backgroundColor: '#39A887',
                  borderRadius: '20px',
                  width: '331px',
                  height: '54px',
                  marginLeft: '10px',
                }}
              >
                Próximo
              </button>
            )}
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default AboutYourHouse;
