// Types
import { AuthAction, AuthState, IProxyAuthDefaultData } from '../types';

export function reducerAuth(state: AuthState, action: AuthAction): AuthState {
  if (!state) throw new Error('The state is required');
  if (!action) throw new Error('The action rollback is required');

  const draft: AuthState = { ...state };

  switch (action.type) {
    case 'auth_update': {
      draft.api_token = action.payload?.api_token || null;
      draft.token = action.payload?.token || null;
      draft.user = action.payload?.user || null;
      break;
    }
    case 'auth_token_update': {
      draft.token = action.payload?.token || null;
      break;
    }
    case 'auth_user_update': {
      draft.user = action.payload || null;
      break;
    }
    case 'auth_sign_out': {
      draft.user = null;
      draft.token = null;
      draft.api_token = null;
      break;
    }
    default:
      throw new Error('The action type is not available');
  }

  return new Proxy(draft, {
    get: (target: IProxyAuthDefaultData, key) => {
      if (!(key.toString() in target)) {
        console.warn(`The key ${key.toString()} is invalid`);
        return undefined;
      }

      // eslint-disable-next-line
      // @ts-ignore
      return target[key];
    },
    set: (_target: IProxyAuthDefaultData, key, value) => {
      console.warn(`The value ${value} to key ${key.toString()} is not valid`);

      return value;
    },
  }) as AuthState;
}
