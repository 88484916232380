import styled from 'styled-components';

export const Container = styled.div`
  .btn-next {
    position: absolute;
    left: 800px;
    color: white;
    width: 331px;
    height: 54px;
    background: #39a887;
    border-radius: 20px;
  }
`;
