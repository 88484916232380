import React from 'react';

import { addYears, format } from 'date-fns';
import { RiInformationFill } from 'react-icons/ri';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { formatPrice } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';
import { Box, Title } from './styles';

const CardTotalValueFInanced: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  return (
    <>
      {serviceOrder.simulacao && (
        <Box className="px-4 py-5 p-lg-5 h-100">
          <div className="d-flex justify-content-between">
            <Title
              color={
                serviceOrder.simulacao.amortizacao?.color
                  ? serviceOrder.simulacao.amortizacao.color
                  : '#000'
              }
              className=""
            >
              {serviceOrder?.simulacao?.amortizacao?.amortizacao}
            </Title>
            <button type="button" className="border-0 bg-transparent">
              <RiInformationFill size={22} color="#fff" />
            </button>
          </div>
          <div className="mt-4 mb-5 row">
            <div className="col-6">
              <span>Valor total financiado</span>
              <div className="d-flex align-items-center mt-2">
                <p className="h3 text-dark-gray mb-0 fw-semibold">
                  {serviceOrder.simulacao.vlrFinanciado &&
                    formatCurrency(serviceOrder.simulacao.vlrFinanciado)}
                </p>
              </div>
            </div>
            {(serviceOrder.finalidade_id === 3 ||
              serviceOrder.finalidade_id === 4) && (
              <div className="col-6">
                <span>Valor de entrada</span>
                <div className="d-flex align-items-center mt-2">
                  <p className="h3 text-dark-gray mb-0 fw-semibold">
                    {serviceOrder.simulacao.vlrEntrada &&
                      formatCurrency(serviceOrder.simulacao.vlrEntrada)}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="d-flex pb-3 position-relative">
              <div className="d-flex justify-content-center chain chain-top my-2" />
              <div className="installment d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <p className="mb-0">Primeira Parcela</p>
                  <small>{format(new Date(), 'dd/MM/yyyy')}</small>
                </div>
                <div>
                  <p className="h5">
                    {serviceOrder.simulacao?.vlrParcInic &&
                      formatPrice(serviceOrder.simulacao?.vlrParcInic)}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex pt-3 position-relative">
              <div className="d-flex justify-content-center chain chain-bottom my-auto" />
              <div className="installment d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <p className="mb-0">Última Parcela</p>
                  <small>
                    {format(
                      addYears(new Date(), serviceOrder.simulacao.prazo || 0),
                      'dd/MM/yyyy'
                    )}
                  </small>
                </div>
                <div>
                  <p className="h5">
                    {serviceOrder.simulacao?.vlrParcFinal &&
                      formatPrice(serviceOrder.simulacao?.vlrParcFinal)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default CardTotalValueFInanced;
