import styled from 'styled-components';

interface ITitle {
  color: string;
}

export const Container = styled.div`
  .btn-simulate-again {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .box-gray {
    background-color: #f7f7fd;
    border: none;
    border-radius: 10px;
  }

  .box-user {
    @media (max-width: 740px) {
      margin-left: 26%;
    }
  }

  .box-rezise {
    @media (max-width: 740px) {
      display: flex;
      margin: auto;
    }
  }

  .box-details {
    padding: 0px 1rem;
    margin-left: 0.25rem;
    @media (max-width: 469px) {
      padding: 0px 4rem;
      margin-left: 2rem;
    }
    @media (max-width: 425px) {
      padding: 0px 3rem;
      margin-left: 2rem;
    }
    @media (max-width: 394px) {
      padding: 0px 2rem;
      margin-left: 2rem;
    }
    @media (max-width: 320px) {
      padding: 0px 1rem;
      margin-left: 2rem;
    }
  }

  @media (max-width: 740px) {
    .container_button {
      order: 3;
    }
    .container_tempo {
      order: 2;
    }
  }
`;

export const Title = styled.h1<ITitle>`
  color: ${(props) => props.color};
`;
