interface IEnvironment {
  vlrFinal?: number;
}

interface IParameter {
  margem: number;
}

interface IBudgetCalculation {
  environments: IEnvironment[];
  parameters: IParameter[];
}

const budgetCalculation = ({
  environments,
  parameters,
}: IBudgetCalculation): number => {
  const totalEnvironmentValue = environments.reduce(
    (previousValue, currentValue) => {
      return previousValue + (currentValue.vlrFinal ?? 0);
    },
    0
  );

  const totalParameter = parameters.reduce((previousValue, currentValue) => {
    return previousValue + (totalEnvironmentValue * currentValue.margem) / 100;
  }, 0);

  const total = totalEnvironmentValue + totalParameter;

  return total;
};

export default budgetCalculation;
