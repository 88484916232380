/* eslint-disable */
import { formatDistanceStrict, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import React, { useCallback, useEffect, useState } from 'react';
import ReactChatView from 'react-chatview';
import { CgClose } from 'react-icons/cg';
import { RiNotification4Fill } from 'react-icons/ri';

import api from '~/services/api';
import {
  initializeNotificationSocketEvents,
  initializeSocketConnection,
} from '~/services/socket';

import { Avatar, Button, Container, Notification } from './styles';

import logo from '~/assets/logos/logo.svg';
import { useAuthContext } from '~/hooks/contexts/Auth';

interface INotificationResponse {
  id: number;
  content: string;
  url?: string;
  read: boolean;
  created_at: string;
}

interface INotificationData {
  data: INotificationResponse[];
}

interface INotification {
  id: number;
  content: string;
  url?: string;
  read: boolean;
  date: string;
}

const Notifications: React.FC = () => {
  const { user } = useAuthContext();
  const [opened, setOpened] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [newNotifications, setNewNotification] = useState(false);
  const [page, setPage] = useState(1);
  const [socketInitialized, setSocketInitialized] = useState(false);

  useEffect(() => {
    if (user?.id) {
      initializeSocketConnection('notification', setSocketInitialized);
    }
  }, [user?.id]);

  useEffect(() => {
    if (socketInitialized) {
      initializeNotificationSocketEvents({
        user_id: user?.id || -1,
        opened,
        setNewNotification,
        setNotifications,
      });
    }
  }, [opened, socketInitialized, user?.id]);

  useEffect(() => {
    api
      .get<INotificationData>(
        `notifications/${user?.id}`
      )
      .then((response) => {
        const data = response.data.data.map((notification) => {
          console.log(notification);
          const date = formatDistanceStrict(
            parseISO(notification.created_at),
            new Date(),
            {
              addSuffix: true,
              locale: ptBr,
            }
          );
          return {
            id: notification.id,
            content: notification.content,
            url: notification.url,
            read: notification.read,
            date,
          };
        });

        const checkNotification = data.find(
          (notification) => !notification.read
        );

        if (checkNotification) {
          setNewNotification(true);
        }

        setNotifications(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user?.id]);

  const loadOldNotifications = useCallback(async () => {
    try {
      const newPage = page + 1;
      const response = await api.get<INotificationData>(
        `notifications/${user?.id || -1}`,
        {
          params: {
            page: newPage,
          },
        }
      );

      const data: INotification[] = response.data.data.map((notification) => {
        const date = formatDistanceStrict(
          parseISO(notification.created_at),
          new Date(),
          {
            addSuffix: true,
            locale: ptBr,
          }
        );
        return {
          id: notification.id,
          content: notification.content,
          url: notification.url,
          read: notification.read,
          date,
        };
      });
      setNotifications((state) => [...state, ...data]);
      setPage(newPage);
    } catch (error) {
      console.log(error);
    }
  }, [page, user?.id]);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <>
      <Button
        type="button"
        className="border-0 bg-transparent"
        onClick={() => setOpened(true)}
        new={newNotifications}
      >
        <span>
          <RiNotification4Fill size={27} color="#707070" />
        </span>
      </Button>
      <Container
        opened={opened}
        className="position-fixed d-flex justify-content-end"
      >
        <button type="button" onClick={handleClose} />
        <ReactChatView
          className="bg-white p-4"
          scrollLoadThreshold={100}
          onInfiniteLoad={loadOldNotifications}
        >
          <button
            type="button"
            className="d-block border-0 bg-transparent ms-auto mb-4"
            onClick={handleClose}
          >
            <CgClose size={20} color="#949494" />
          </button>
          <h4>
            Notificações
            <span className="d-inline-block ms-2">
              <RiNotification4Fill size={23} color="#414142" />
            </span>
          </h4>
          <div className="mt-5">
            {notifications.map((notification) => (
              <Notification key={notification?.id} className="p-5">
                <small className="text-gray small mb-1 text-start block">
                  {notification?.date}
                </small>
                <div className="d-flex">
                  <Avatar>
                    <img src={logo} alt="logo" />
                  </Avatar>
                  <div>
                    {/* <h6 className="my-1">Novo curso lançado!</h6> */}
                    <p className="text-gray small py-2 mt-0 mb-3">
                      {notification?.content}
                    </p>
                    {!!notification?.url && (
                      <a
                        href={notification?.url}
                        target="_blank"
                        className="btn btn-primary px-6 py-1 mr-auto"
                        rel="noreferrer"
                      >
                        Ir
                      </a>
                    )}
                  </div>
                </div>
              </Notification>
            ))}
          </div>
        </ReactChatView>
      </Container>
    </>
  );
};

export default Notifications;
