import React from 'react';

import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AvisoNaranja: React.FC = () => {
  return (
    <Container className="container col-md-11 col-11 border border-secondary border-3 card-dashboard justify-content-center p-4 mb-5">
      <div className="d-flex  align-items-center">
        <div className="col-11 d-flex text-dark text-opacity-50 align-items-center ">
          <h3 className="mb-0 ">
            Faça seu login ou cadastro para processeguir com a solicitação do
            seu financiamento
          </h3>
        </div>
      </div>
    </Container>
  );
};

export default AvisoNaranja;
