import styled from 'styled-components';

interface IContainer {
  active: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: #00000055;
  pointer-events: none;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  z-index: 999;
  cursor: default;

  .message-loading {
    display: flex;
    margin-top: 8%;
    text-align: center;
    b {
      font-size: 25px;
      font-weight: 700;
      color: #fff;
    }
  }
`;
