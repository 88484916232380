import styled from 'styled-components';

export const Container = styled.div`
  .container_dashboard_info_res {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  .imovel {
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .container_dashboard_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
  }
  h1 {
    color: #414141;
    font-weight: 400 !important;
    text-align: center;

    b {
      font-weight: 700 !important;
    }
  }
  .card-dashboard {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
  }
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    border-radius: 50%;
  }
`;

export const ChatOptions = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;

  button.btn-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #079269;
    border: 0;
  }
`;
