import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IClient {
  id: number;
  user_id?: number;
  name: string;
  document: string;
  identity_document?: string;
  dispatching_agency?: string;
  school_level_id?: number;
  salary?: number;
  fgts_amount?: number;
  pis_number?: string;
  married?: boolean;
  birth_date?: Date;
  sex?: number;
  marital_status_id?: number;
  date_stable_union?: Date;
  mother?: string;
  father?: string;
  pep?: string;
  email: string;
  cell?: string;
  telephone?: string;
  zip_code?: string;
  address?: string;
  number?: string;
  complement?: string;
  district?: string;
  state_id?: number;
  state?: {
    id?: number;
    name?: string;
  };
  residence_time?: number;
  profession?: string;
  company_name?: string;
  company_document?: string;
  occupation_type_id?: number;
  shipping_date?: Date;
}

interface ClientContextData {
  client: IClient;
  setClient(client: IClient): void;
}

export const ClientContext = createContext<ClientContextData>(
  {} as ClientContextData
);

export const ClientProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState({} as IClient);

  const handleSetClient = useCallback((clientData: IClient) => {
    setClient(clientData);
  }, []);

  return (
    <ClientContext.Provider value={{ client, setClient: handleSetClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export function useClient(): ClientContextData {
  const context = useContext(ClientContext);

  if (!context) {
    throw new Error('useClient must be used within an ClientProvider');
  }

  return context;
}
