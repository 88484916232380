import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IParticipant {
  id: number;
  so_id?: number;
  name: string;
  document: string;
  birth_date?: Date;
  shipping_date?: Date;
  identity_document?: string;
  salary?: number;
  fgts_amount?: number;
  pis_number?: string;
  profession?: string;
  school_level_id?: number;
}

interface ParticipantContextData {
  participants: IParticipant[];
  setParticipants(participants: IParticipant[]): void;
}

export const ParticipantContext = createContext<ParticipantContextData>(
  {} as ParticipantContextData
);

export const ParticipantProvider: React.FC = ({ children }) => {
  const [participants, setParticipants] = useState([{} as IParticipant]);

  const handleSetParticipants = useCallback(
    (participantData: IParticipant[]) => {
      setParticipants(participantData);
    },
    []
  );

  return (
    <ParticipantContext.Provider
      value={{ participants, setParticipants: handleSetParticipants }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};

export function useParticipant(): ParticipantContextData {
  const context = useContext(ParticipantContext);

  if (!context) {
    throw new Error(
      'useParticipant must be used within an ParticipantProvider'
    );
  }

  return context;
}
