import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 21%;
  width: 50%;
  .p-relative {
    position: relative;
  }
  h2 {
    color: #5b4dbe;
    font-weight: 600 !important;
    font-size: 20px;
  }
  h3 {
    font-weight: 600;
    color: #414142;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
  .w-75 {
    p {
      font-size: 12px;
    }
  }
  .font-weight-700 {
    font-weight: 700;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #cbcbcb;
    position: relative;
    z-index: 1;
  }
  hr {
    height: 150px;
    width: 2px;
    margin-top: -44px;
    margin-bottom: -21px;
    margin-left: 4px;
    position: relative;
    z-index: 0;
    color: #e7e7e7;
  }

  @media (max-width: 988px) {
    display: unset;
    height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }
`;
