import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';

import { Container } from './styles';

interface IPagination {
  totalData?: number;
  selectedPage?: number;
  fromData?: number;
  toData?: number;
  onChangePage?(e: number): void;
  perPage?: number;
}

const Pagination: React.FC<IPagination> = ({
  totalData,
  selectedPage,
  fromData,
  toData,
  onChangePage,
  perPage = 10,
}) => {
  const [firstButtonDisabled, setFirstButtonDisabled] = useState(true);
  const [lastButtonDisabled, setLastButtonDisabled] = useState(true);
  const [pages, setPages] = useState<number[]>([]);

  const totalPages = useMemo(() => {
    if (totalData) {
      const pagesData = Math.ceil(totalData / perPage);
      return pagesData;
    }

    return 1;
  }, [totalData, perPage]);

  useEffect(() => {
    const pagesArr: number[] = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pagesArr.push(i);
    }
    setPages(pagesArr);
    setLastButtonDisabled(totalPages === 1);
  }, [totalPages]);

  const handleChangePage = useCallback(
    (page) => {
      if (onChangePage) {
        onChangePage(page);
      }
      if (page > 1 && page + 1 <= totalPages) {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(false);
      } else if (page === 1) {
        setFirstButtonDisabled(true);
        setLastButtonDisabled(false);
      } else if (page === totalPages) {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(true);
      } else {
        setFirstButtonDisabled(true);
        setLastButtonDisabled(true);
      }
    },
    [onChangePage, totalPages]
  );

  return (
    <Container className="justify-content-center justify-content-md-end pt-2 mt-4">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="w-100 w-sm-auto text-center">
          <small className="me-3 me-md-2">Procurar por página:</small>
          <select
            onChange={(e) => handleChangePage(parseInt(e.target.value, 10))}
          >
            {pages.map((page) => (
              <option key={page} value={page} selected={selectedPage === page}>
                {page}
              </option>
            ))}
          </select>
        </div>
        <div className="w-100 w-sm-auto d-flex justify-content-center align-items-center mt-3 mt-sm-0">
          <small className="me-3 me-md-2 mb-n1">
            {fromData}-{toData} de {totalData}
          </small>
          <div className="d-flex">
            <button
              type="button"
              className="border-0 me-3"
              disabled={firstButtonDisabled}
              onClick={() =>
                handleChangePage(selectedPage ? selectedPage - 1 : 0)
              }
            >
              <MdOutlineArrowBackIos
                size={15}
                color={firstButtonDisabled ? '#aaaaaa' : '#676565'}
              />
            </button>
            <button
              type="button"
              className="border-0 ms-3"
              disabled={lastButtonDisabled}
              onClick={() =>
                handleChangePage(selectedPage ? selectedPage + 1 : 0)
              }
            >
              <MdOutlineArrowForwardIos
                size={15}
                color={lastButtonDisabled ? '#aaaaaa' : '#676565'}
              />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Pagination;
