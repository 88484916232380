import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IOrderServiceBuilder {
  builder_id: string;
  bdi: string;
}

interface OrderServiceBuilderContextData {
  orderServiceBuilder: IOrderServiceBuilder;
  setOrderServiceBuilder(orderServiceBuilder: IOrderServiceBuilder): void;
}

export const OrderServiceBuilderContext =
  createContext<OrderServiceBuilderContextData>(
    {} as OrderServiceBuilderContextData
  );

export const OrderServiceBuilderProvider: React.FC = ({ children }) => {
  const [orderServiceBuilder, setOrderServiceBuilder] = useState(() => {
    const orderServiceBuilderLocal = localStorage.getItem('@HarveyClient:OS');

    if (orderServiceBuilderLocal) {
      return JSON.parse(orderServiceBuilderLocal);
    }

    return {} as IOrderServiceBuilder;
  });

  const handleSetOrderService = useCallback(
    (orderServiceBuilderData: IOrderServiceBuilder) => {
      localStorage.setItem(
        '@HarveyClient:OSBuilder',
        JSON.stringify(orderServiceBuilderData)
      );
      setOrderServiceBuilder(orderServiceBuilderData);
    },
    []
  );

  return (
    <OrderServiceBuilderContext.Provider
      value={{
        orderServiceBuilder,
        setOrderServiceBuilder: handleSetOrderService,
      }}
    >
      {children}
    </OrderServiceBuilderContext.Provider>
  );
};

export function useOrderServiceBuilder(): OrderServiceBuilderContextData {
  const context = useContext(OrderServiceBuilderContext);

  if (!context) {
    throw new Error(
      'useOrderServiceBuilder must be used within an OrderServiceBuilderProvider'
    );
  }

  return context;
}
