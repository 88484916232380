import React from 'react';

import { Container } from './styles';

interface ILoadingProps {
  active: boolean;
  textMessage?: string;
}

const Loading: React.FC<ILoadingProps> = ({ active, textMessage }) => {
  return (
    <Container active={active} className="loading-box">
      {/* <Lottie
        options={{
          animationData: active,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={125}
        width={125}
      /> */}

      <div
        className="inline-block h-[7.812rem] w-[7.812rem] animate-spin rounded-full border-[1rem] border-solid border-emerald-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          {textMessage && textMessage?.length > 0 && textMessage}
        </span>
      </div>
    </Container>
  );
};

export default Loading;
