import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Assets
import { RiArrowLeftSLine } from 'react-icons/ri';
import acquisitionConstruction from '~/assets/defaults/acquisition-construction.jpg';
import buildingInLand from '~/assets/defaults/building-in-land.jpg';
import homeEquity from '~/assets/defaults/home-equity.jpg';
import land from '~/assets/defaults/land.jpg';
import newProperty from '~/assets/defaults/new-property.jpg';
import reformExpansion from '~/assets/defaults/reform-expansion.jpg';
import usedProperty from '~/assets/defaults/used-property.jpg';

// Components
import Loading from '~/components/Loading';

// Hooks
import { useDefaultData } from '~/hooks/DefaultData';
import { useServiceOrder } from '~/hooks/ServiceOrder';

// Models
import { IMFinality } from '~/models/Finality';
import { IMServiceOrder } from '~/models/ServiceOrder';

// Pages
import Slider from './Slider';

// Services
import api from '~/services/api';

// Styles
import { useAuthContext } from '~/hooks/contexts/Auth';
import { Box, Container, Content, Img, Title } from './styles';

const Finalities: React.FC = () => {
  const history = useHistory();
  const [finalities, setFinalities] = useState<IMFinality[]>([]);
  const { serviceOrder, setServiceOrder, clearServiceOrder } =
    useServiceOrder();
  const { setDefaultData, finalidades } = useDefaultData();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearServiceOrder();
  }, [clearServiceOrder]);

  useEffect(() => {
    setDefaultData();
  }, [setDefaultData]);

  useEffect(() => {
    setLoading(true);
    const finalitiesData: IMFinality[] = [];
    if (finalidades?.length) {
      const data: IMFinality[] = finalidades?.map((finality) => {
        let imagem = '';
        switch (finality.id) {
          case 1: // AC - Aquisição de terreno e construção
            imagem = acquisitionConstruction;
            break;
          case 2: // CT - Construção em terreno próprio
            imagem = buildingInLand;
            break;
          case 3: // IN - Imóvel novo
            imagem = newProperty;
            break;
          case 4: // IU - Imóvel usado
            imagem = usedProperty;
            break;
          case 5: // AT - Aquisição de terreno
            imagem = land;
            break;
          case 6: // CGI - Crédito com garantia de imóvel
            imagem = homeEquity;
            break;
          default:
            // RA - Reforma e ampliação
            imagem = reformExpansion;
            break;
        }
        return {
          id: finality.id,
          finalidade: finality.finalidade,
          descricao: finality.descricao,
          imagem,
        };
      });
      finalitiesData.push(...data);
      const eightFinality = {
        id: 99,
        finalidade: 'CC - Crédito para condomínio',
        descricao: 'Tire do papel os planos de melhoria para o seu condomínio',
        imagem: acquisitionConstruction,
      };
      finalitiesData.push(eightFinality);
      setLoading(false);
    }
    setFinalities(finalitiesData);
  }, [finalidades]);

  const handleClickModality = useCallback(
    (finality: IMFinality) => {
      if (finality.id === 99) {
        window.open(
          'https://institucional.cashme.com.br/condo-lp/minhacasafinanciada',
          '_self'
        );
      } else {
        setServiceOrder({
          ...serviceOrder,
          finalidade_id: finality.id,
          finalidade: finality,
        });
        history.push(`${process.env.PUBLIC_URL}/simulador`);
      }
    },

    [history, serviceOrder, setServiceOrder]
  );

  useEffect(() => {
    const servidorOrderIDCached: string = (localStorage.getItem(
      'CLIENT@SERVICER_ID'
    ) || '') as unknown as string;

    if (servidorOrderIDCached !== '') {
      Swal.fire({
        icon: 'warning',
        title: 'OS encontrada',
        text: `Deseja continuar com a Ordem de Serviço`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#4DC68C',
        cancelButtonColor: '#d8d8d8',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          api
            .get<IMServiceOrder>(`service-orders/${servidorOrderIDCached}`)
            .then((resolver) => {
              if (!resolver.data) return;

              setServiceOrder(resolver.data);
              if (resolver.data.status?.id === 1) {
                history.push(`monte-sua-casa/${servidorOrderIDCached}`);
              } else {
                history.push(`dashboard/${servidorOrderIDCached}`);
              }
            })
            .catch((_rejects) => {
              // do anything
            });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="p-md-5 p-4">
      <div className="container">
        <Title>
          <div style={{ width: '40px' }} />
          <br />
        </Title>
        <div className="d-flex flex-row flex-wrap justify-content-between">
          <div className="flex-1">
            <div className="d-flex align-items-center">
              <h2 className="h2 lg-h1 fw-semibold text-dark-gray mb-0">
                Escolha uma das opções
              </h2>
            </div>
            <p className="mt-5 mb-5">
              Possuímos diversas modalidades, escolha a que mais se encaixa com
              sua necessidade.
            </p>
          </div>
          <div className="flex-1 mb-5">
            <div className="d-flex justify-content-end px-0">
              {user == null ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/login`}
                  className="text-black fw-medium py-1"
                >
                  Entrar
                </Link>
              ) : (
                <Link
                  to="/solicitacoes"
                  className="back text-black fw-medium py-1 px-2"
                >
                  <RiArrowLeftSLine size={28} color="#000000" />
                  Minhas solicitações
                </Link>
              )}

              <div className="text-black fw-medium px-2 py-1">
                {' '}
                {user == null ? '|' : ''}{' '}
              </div>
              {user == null ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/cadastre-se`}
                  className="text-black fw-medium py-1"
                >
                  Registrar
                </Link>
              ) : (
                <Link
                  to={`${process.env.PUBLIC_URL}/cadastre-se`}
                  className="text-black fw-medium py-1"
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 sm:px-5 md:px-5">
            <Slider>
              {finalities.map((finality) => (
                <Box
                  key={finality.id}
                  className="w-100 d-flex mb-4 d-flex flex-column flex-md-row"
                >
                  <Img src={finality.imagem} />
                  <Content className="d-flex justift-content-center align-items-center">
                    <div className="content-info w-75 px-sm-4 mx-auto d-flex flex-column">
                      <h2 className="h5 text-dark-gray fw-semibold text-center">
                        {finality.finalidade}
                      </h2>
                      <p className="text-center my-4">{finality.descricao}</p>
                      <div className="content-info_button mx-auto px-5 d-flex">
                        <button
                          type="button"
                          className="btn  w-100 py-3 px-4 "
                          style={{
                            width: ' 239px',
                            height: '54px',
                            backgroundColor: '#39A887',
                            color: 'white',
                          }}
                          onClick={() => handleClickModality(finality)}
                        >
                          Selecionar Modalidade
                        </button>
                      </div>
                    </div>
                  </Content>
                </Box>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default Finalities;
