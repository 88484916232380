import React, { useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '~/models/Simulation/Amortization';
import { Container } from './styles';

const ButtonNext: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();

  const handleClickRequestQuote = useCallback(() => {
    if (params.builderId) {
      history.push(
        `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/nova-os/${params.builderId}/${params.bdi}`
      );
    } else if (params.osId !== undefined) {
      history.push(
        `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/${params.osId}`
      );
    } else {
      history.push(`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento`);
    }
  }, [history, params.bdi, params.builderId, params.osId]);

  return (
    <Container>
      <button
        type="button"
        className="btn btn-primary w-100 fw-bold py-3 btn-submit"
        onClick={handleClickRequestQuote}
      >
        Próximo
      </button>
    </Container>
  );
};

export default ButtonNext;
