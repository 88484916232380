import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-dialog {
    height: 92%;
    width: auto;
    display: flex;
    align-items: center;

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .modal-content {
      height: auto;
      border-radius: 15px;
      padding-left: 5%;
      padding-right: 5%;
      box-shadow: -17px 17px 34px rgba(102, 102, 102, 0.2),
        17px 17px 43px rgba(102, 102, 102, 0.9),
        inset 1px 1px 2px rgba(255, 255, 255, 0.3),
        inset -1px -1px 2px rgba(102, 102, 102, 0.5);

      form {
        height: 100%;

        .input {
          border-radius: 0;
          border-left: none;
          border-top: none;
          border-right: none;
        }

        .modal-header {
          border-bottom: unset;
        }

        .modal-footer {
          border-top: unset;

          .buttons_group {
            display: flex;
            gap: 2rem;
            width: 100%;

            .button_submit {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 54px;
              font-weight: 600;
              font-size: 18px;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .container_switchs {
            gap: 6rem !important;
          }
        }
      }
    }

    .modal-footer {
      .buttons_group {
        flex-direction: column;
        gap: 0.5rem !important;
      }
    }
  }

  @media (max-width: 557px) {
    .modal-dialog {
      width: auto;
      height: auto;

      .modal-content {
        height: auto;

        .modal-body {
          height: 72% !important;
          overflow: auto;

          .container_switchs {
            gap: 3rem !important;
          }

          .container_pills {
            .sc-crzoAE {
              width: 30% !important;
            }
          }
        }

        .modal-footer {
          .buttons_group {
            flex-direction: column;
            gap: 0.5rem !important;
          }
        }
      }
    }
  }
`;
