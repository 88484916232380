import React, { useCallback, useState } from 'react';
import { FaRegImages } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import Slider from './Slider';

import Modal from './ModalVisualizer';
import { CardContainer } from './styles';

interface IMedias {
  source: string;
  title: string;
  description: string;
}

interface ICardProps {
  projectID: number;
  bathroomsNumber: number;
  suitesNumber: number;
  bedroomsNumber: number;
  garagesNumber: number;
  linkRedirect: string;
  medias: IMedias[];
}

const Card: React.FC<ICardProps> = ({
  projectID,
  bathroomsNumber,
  suitesNumber,
  bedroomsNumber,
  garagesNumber,
  medias,
  linkRedirect,
}) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  // Se crea un link embedido de youtube para mostrar en el slider con los links de los videos que llegan suponiendo que todos son de youtube
  const LINK_BASE_YOUTUBE = 'https://www.youtube.com/embed/';
  const handleClickProject = useCallback(() => {
    setShow(true);
  }, []);

  const handleClickRedirect = () => {
    window.open(linkRedirect, '_blank');
  };
  return (
    <>
      <CardContainer>
        <div className="card border-0">
          <Slider>
            {/* Esta solución esta escrita considerando que la API de imagenes siempre devolvera imagenes y sumado a esto a veces agregara un video de YOUTUBE */}
            {medias.length > 0 ? (
              medias.map((media) => {
                return media.source.includes('jpg') ? (
                  <img src={media.source} alt={String(projectID)} />
                ) : (
                  <iframe
                    style={{
                      height: '244',
                    }}
                    src={
                      LINK_BASE_YOUTUBE +
                      media.source.split('=')[1].split('&')[0]
                    }
                    title={String(projectID)}
                    allowFullScreen
                  />
                );
              })
            ) : (
              <img
                src="https://www.cdc-carpi.com/wp-content/uploads/2020/12/image-placeholder-400x266.jpg"
                alt={String(projectID)}
              />
            )}
            {/* Solucion escrita anteriormente */}
            {/* {medias[0].source.split('.')[
              medias[0].source.split('.').length - 1
            ] === 'jpg' ? (
              medias.map((media) =>
                media.source === '' ? (
                  <img
                    src="https://www.cdc-carpi.com/wp-content/uploads/2020/12/image-placeholder-400x266.jpg"
                    alt={String(projectID)}
                  />
                ) : (
                  <img src={media.source} alt={String(projectID)} />
                )
              )
            ) : (
              
            )} */}
          </Slider>
          <div className="card_body ">
            <span className="title_body">
              <button
                type="button"
                className="border-0 button_redirect"
                onClick={() => handleClickRedirect()}
              >
                <span>Projeto: {projectID}</span>
              </button>
              <button
                type="button"
                className="border-0 view"
                onClick={() => handleClickProject()}
              >
                <FaRegImages className="icon" />
              </button>
            </span>

            <div className="d-flex gap-3 card_body_items">
              <p>
                Dormitórios <span>{bedroomsNumber}</span>
              </p>
              <p>
                Banheiros <span>{bathroomsNumber}</span>
              </p>
              <p>
                Suítes <span>{suitesNumber}</span>
              </p>
              <p>
                Garagem <span>{garagesNumber}</span>
              </p>
            </div>
          </div>
        </div>
      </CardContainer>
      <Modal
        show={show}
        medias={medias}
        linkBase={LINK_BASE_YOUTUBE}
        project={String(projectID)}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default Card;
