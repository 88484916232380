import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 21%;
  .p-relative {
    position: relative;
  }
  .w-75 {
    font-size: 12px;
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8675fe;
  }
  .dot-2 {
    background-color: #6dfca6 !important;
  }
  .dot-3 {
    background-color: #168fff !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }

  @media (max-width: 988px) {
    display: unset;
    height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    height: auto;
  }
`;
