import styled from 'styled-components';

interface IBar {
  percent: number;
  color: string;
}

interface ICircle {
  color: string;
}

export const Container = styled.div`
  display: flex;
`;

export const Bar = styled.div<IBar>`
  background: rgba(215, 215, 215, 0.3);
  border-radius: 10px;
  height: 115px;
  width: 16px;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    transition-duration: 0.3s;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
    height: ${(props) => props.percent}%;
    background: ${(props) => props.color};
  }

  + div {
    margin-left: 24px;
  }
`;

export const Circle = styled.div<ICircle>`
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;
