import React, { useCallback, useEffect, useState } from 'react';

// Hooks
import { useServiceOrder } from '~/hooks/ServiceOrder';

// Components
import InputMask from '~/components/InputMask';
import NextButton from '../NextButton';

// Pages
import { useStep } from '~/pages/Simulation/Hooks/useStep';

// Utils
import { formatToFloat } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';

// Styles
import { Container } from './styles';

const InputRevenue: React.FC = () => {
  const { setStep } = useStep();
  const [revenue, setRevenue] = useState('');
  const [errorData, setErrorData] = useState('');
  const { serviceOrder, setServiceOrder } = useServiceOrder();

  const handleNextStep = useCallback(() => {
    const revenueNumber = parseFloat(
      revenue.replace('R$', '').replaceAll('.', '').replace(',', '.')
    );
    if (revenueNumber === 0) {
      setErrorData('O valor da renda é obrigatório');
    } else {
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...serviceOrder.simulacao,
          vlrRenda: formatToFloat(revenue),
        },
      });
      setStep(2);
    }
  }, [revenue, setErrorData, serviceOrder, setServiceOrder, setStep]);

  useEffect(() => {
    setRevenue(formatCurrency(serviceOrder.simulacao?.vlrRenda || 0));
  }, [serviceOrder, serviceOrder.simulacao?.vlrRenda]);

  return (
    <Container>
      <p className="font-qual mt-4 px-4 px-lg-0">
        Qual seria o valor total da renda de todos os envolvidos no
        financiamento?
      </p>
      <label className="w-100 mt-5 input-simulator font-renda px-4 px-lg-0">
        Valor da renda:
        <InputMask
          kind="money"
          name="revenue"
          placeholder="R$ 0,00"
          value={revenue}
          onChange={(e) => setRevenue(e.target.value)}
          className="input mt-2 w-75 w-lg-50 fondo-gris"
        />
        {errorData && (
          <span className="small text-danger error">{errorData}</span>
        )}
      </label>
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-12 d-flex justify-content-end ps-4">
          <NextButton onClick={handleNextStep} />
        </div>
      </div>
    </Container>
  );
};

export default InputRevenue;
